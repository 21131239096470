import { Title, TitleProps } from '@mantine/core';

export const StepTitle = ({
  isInverted,
  order,
  size,
  ...rest
}: TitleProps & { isInverted?: boolean }) => {
  return (
    <Title
      order={order ?? 5}
      size={size ?? '32px'}
      align="center"
      c={isInverted ? 'minor.1' : 'primary.0'}
      style={{
        whiteSpace: 'break-spaces',
        lineHeight: '120%',
      }}
      {...rest}
    />
  );
};
