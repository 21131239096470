import { router } from '@/router/router';
import { isNextPathGuard, urlScheme } from '@/router/router.types';
import { CaseState } from '@/state/state.types';
import { mapStepIdToRoute } from './mapStepIdToRoute';
import { mapStepIdToUrl } from './mapStepIdToUrl';

export const navigateToNextStepOrUrl = (
  caseState: CaseState,
  replace?: boolean,
  timeOut?: number,
  avoidUrlNavigation?: boolean,
) => {
  const nextStep = mapStepIdToRoute(caseState);
  if (isNextPathGuard(nextStep)) {
    router.navigate({
      to: nextStep,
      params: { stepId: caseState.step?.id ?? '' },
      replace,
    });

    return;
  }

  if (avoidUrlNavigation || nextStep === undefined) {
    return;
  }

  const nextUrl = mapStepIdToUrl(caseState);
  if (nextUrl && urlScheme.safeParse(nextUrl).success) {
    const redirect = (replace?: boolean) => {
      replace ? window.location.replace(nextUrl) : window.location.assign(nextUrl);
    };

    if (timeOut) {
      setTimeout(() => {
        redirect(replace);
      }, timeOut);
    } else {
      redirect(replace);
    }

    return;
  }

  throw new Error('Can not detect next navigation path');
};
