import { fetchCaseState } from '@/fetchers/backend-fetchers';
import { useQuery } from '@tanstack/react-query';

export const useCase = () => {
  const { data: case_ } = useQuery({
    queryKey: ['caseState'],
    queryFn: fetchCaseState,
    select: (data) => data?.case,
  });
  return case_;
};
