import { ComponentPropsWithoutRef } from 'react';

export const Star = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.72 1.43 1.76 3.52c.24.5.88.96 1.42 1.05l3.2.53c2.03.34 2.51 1.82 1.04 3.28l-2.48 2.48a2.17 2.17 0 0 0-.52 1.81l.71 3.07c.56 2.43-.73 3.37-2.88 2.1l-2.99-1.77a2.16 2.16 0 0 0-1.98 0l-2.99 1.77c-2.14 1.27-3.44.32-2.88-2.1l.71-3.07c.13-.58-.1-1.39-.52-1.8L1.84 9.8C.38 8.35.85 6.87 2.9 6.53L6.1 6a2.18 2.18 0 0 0 1.4-1.05l1.76-3.52c.96-1.9 2.52-1.9 3.47 0Z"
    />
  </svg>
);
