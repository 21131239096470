import { createAccount } from '@/fetchers/bff-fetchers';
import { emitHeapEvents } from '@/metrics/emitHeapEvents';
import { AboveFpl } from '@/routes/AboveFpl';
import { CanNotHelpLegalEncumbrance } from '@/routes/CannotHelpLegalEncumbrance';
import { ChoiceStep } from '@/routes/ChoiceStep';
import { Info } from '@/routes/Info';
import { PainPointAddress } from '@/routes/PainPointAddress';
import { ProductStep } from '@/routes/ProductStep';
import { Terms } from '@/routes/Terms';
import { clearCaseStateAndLocalStorage } from '@/utils/clearCaseStateAndLocalStorage';
import { DASHBOARD_ROUTES, getDashboardURL } from '@/utils/dashboardUrl';
import { isQueryFnPresented } from '@/utils/isQueryFnPresented';
import { mapStepIdToRoute } from '@/utils/mapStepIdToRoute';
import { mapStepIdToUrl } from '@/utils/mapStepIdToUrl';
import { navigateToNextStepOrUrl } from '@/utils/navigateToNextStepOrUrl';
import { hasValidPartnership } from '@/utils/partnership';
import {
  getStepName,
  isCheckoutStep,
  isCompleteAccountStep,
  isEmailStep,
  isMilestoneStep,
  isProductCannotHelpLegalEncumbranceStep,
  isProductStep,
  isValidationStep,
} from '@/utils/stepUtils';
import { Center, Loader, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { persistQueryClientRestore } from '@tanstack/react-query-persist-client';
import { Navigate, RootRoute, Route, Router, useParams } from '@tanstack/router';
import { lazy, useEffect } from 'react';
import { z } from 'zod';
import { stytch } from '../auth/stytch';
import { StepTitle } from '../components/StepTitle';
import {
  authenticateOauth,
  createCase,
  fetchCaseState,
  fetchSequence,
} from '../fetchers/backend-fetchers';
import { validateHeapSafely } from '../metrics/heap.types';
import { submitTrackingMetrics } from '../metrics/submitTrackingMetrics';
import { CanNotHelp } from '../routes/CanNotHelp';
import { Checkout } from '../routes/Checkout';
import { CompleteAccount } from '../routes/CompleteAccount';
import { ConsultFocus } from '../routes/ConsultFocus';
import { Email } from '../routes/Email';
import { FirstStepSubmitter } from '../routes/FirstStepSubmitter';
import { GeneralError } from '../routes/GeneralError';
import { LoaderForProduct } from '../routes/LoaderForProduct';
import { Milestone } from '../routes/Milestone';
import { MultiChoiceStep } from '../routes/MultiChoiceStep';
import { OpenStep } from '../routes/OpenStep';
import { SsoAuthenticate } from '../routes/SsoAuthenticate';
import { Validation } from '../routes/Validation';
import { localStoragePersister, queryClient, sevenDays } from '../state/queryClient';
import {
  CaseState,
  Sequence,
  Step,
  StepName,
  StepType,
  isStepGuard,
  isStepNameGuard,
} from '../state/state.types';
import { updateCase } from '../state/updateCase';
import { updateStytchSession } from '../utils/updateStytchSession';
import { Layout } from './Layout';
import { useRouteSafeGuard } from './hooks/useRouteSafeGuard';
import { navigateToErrorRouteComponent } from './navigateToErrorRouteComponent';
import { urlScheme } from './router.types';

// REVIEW: we have to keep all routes in one file until we have a solution
// for circular dependencies in TanRouter returning error
// `Cannot access `{route name}` before initialization

export const rootRoute = new RootRoute({
  loader: async ({ search }) => {
    const { partnership: partnershipParam } = (search as { partnership?: string | number }) ?? {};
    const partnership = hasValidPartnership(`${partnershipParam}`) ? `${partnershipParam}` : '';
    // this way we avoid race condition between root route and a specific route
    // on that route we start the app differently
    // on error we want avoid fetching and just show the error
    if (
      [
        fromDashboardRoute.id,
        resetFromDashboard.id,
        fromJotformRoute.id,
        errorRoute.id,
        infoRoute.id,
      ].some((el) => router.state.currentLocation.pathname === el)
    )
      return;

    // Wait for re-hydration is done before we start accessing the cache or fetching data
    await persistQueryClientRestore({
      queryClient,
      persister: localStoragePersister,
      maxAge: sevenDays,
    });

    const session = stytch.session.getSync();
    // A session only exists if the user has passed a certain point in the sequence where we create a Stytch user
    if (session) {
      await stytch.session.authenticate({
        // Extend the session for another 60 minutes
        session_duration_minutes: 60,
      });
    }

    // We loose QueryFn after hydration,
    // the only way to restore it is to remove it and re-fetch
    if (!isQueryFnPresented(['sequence'])) {
      queryClient.removeQueries({
        queryKey: ['sequence'],
      });
    }

    // NOTE: we intentionally do not await on this, we delegate the fetching chain to the TQ
    // and get data from the cache when we need it
    // TQ prevents second fetch for the same query if it is already in progress
    queryClient.fetchQuery({
      queryKey: ['sequence'],
      queryFn: fetchSequence,
    });

    if (!isQueryFnPresented(['caseState'])) {
      queryClient.removeQueries({
        queryKey: ['caseState'],
      });
    }

    let caseState = await queryClient.fetchQuery({
      queryKey: ['caseState'],
      queryFn: fetchCaseState,
      // we want to enforce refetching on the app start
      // we navigate to the next step after
      // this way caseState will be provided from cache after the navigation
      staleTime: 1000,
    });
    const sequence = await queryClient.ensureQueryData<Sequence>(['sequence']);
    if (!caseState) {
      caseState = await createCase(sequence?.id, partnership);
      queryClient.setQueryData(['caseState'], caseState);
    }

    const nextStepId = caseState.step?.id ?? '';

    if (nextStepId && !!getStepName(nextStepId) && isStepGuard(sequence.steps[0])) {
      queryClient.setQueryData(['step', nextStepId], {
        ...caseState.step,
        counter: Object.values(caseState.case.fields).length - 3,
        remaining: caseState.remaining,
      });
    }

    // we await on this function to make sure we have response from the server
    // to avoid race condition with posting first step on the firsStepSubmitRoute
    await submitTrackingMetrics();
  },
  component: function RootRouteComponent() {
    const [routeIsSafe] = useRouteSafeGuard(false);

    if (!routeIsSafe) return;

    return <Layout />;
  },
});

export const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: function IndexRouteComponent() {
    const { data: caseState } = useQuery<CaseState>(['caseState']);

    useEffect(() => {
      if (!caseState) return;

      navigateToNextStepOrUrl(caseState);
    }, [caseState]);

    return <Layout />;
  },
});

export const firsStepSubmitRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `submit/$stepId`,
  validateSearch: (search) => {
    return z
      .object({
        choice: z.string(),
      })
      .parse(search);
  },
  component: () => {
    return <FirstStepSubmitter />;
  },
});

const mapChoiceStepIdToComponent = (stepId: string) => {
  switch (true) {
    case getStepName(stepId) === StepName.above_fpl:
      return <AboveFpl />;
    default:
      return <ChoiceStep />;
  }
};

export const choiceStepRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `${StepType.choice}/$stepId`,
  component: function ChoiceStepRoute() {
    const { stepId } = useParams({
      from: staticStepRoute.id,
    }) as { stepId: string };
    return mapChoiceStepIdToComponent(stepId);
  },
});

export const magicLinkSentRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `magic-link-sent`,
  component: lazy(() => import('../routes/MagicLinkSent')),
});

export const ssoAuthenticateRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `sso-authenticate`,
  validateSearch: (search) => {
    return z
      .object({
        stytch_token_type: z.string(),
        token: z.string(),
      })
      .parse(search);
  },
  loader: async ({ search }) => {
    if (search.stytch_token_type != 'oauth') {
      throw new Error('expected oauth token type');
    }
    const response = await authenticateOauth(search.token);
    localStorage.setItem('first-name-sso', response.first_name);
    localStorage.setItem('last-name-sso', response.last_name);

    const formDataEmail = new FormData();
    formDataEmail.append('stepId', StepName.email);
    formDataEmail.append('answer', response.email.email);
    try {
      await createAccount(formDataEmail);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.['error_type'] == 'legacy_user') {
        window.location.replace(
          `${getDashboardURL()}/${DASHBOARD_ROUTES.sessionAuthenticate}?sessionToken=${
            response.session_token ?? ''
          }`,
        );
      } else {
        if (e?.['error_type'] != 'exists_in_stytch_not_in_db') {
          console.error('createAccount');
          console.error(e);
        }
      }
    }
    const updatedCase = await updateCase(formDataEmail);
    await updateStytchSession(response.session_token, response.jwt_token);
    navigateToNextStepOrUrl(updatedCase, true, 2000);
  },
  component: () => {
    return <SsoAuthenticate />;
  },
});

export const multiChoiceStepRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `${StepType.multi_choice}/$stepId`,
  component: () => {
    return <MultiChoiceStep />;
  },
});

const mapOpenStepIdToComponent = (stepId: string) => {
  switch (true) {
    case isEmailStep(stepId):
      return <Email />;
    default:
      return <OpenStep />;
  }
};

export const openStepRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `${StepType.open}/$stepId`,
  component: function OpenStepRoute() {
    // TODO: improve type
    const { stepId } = useParams({
      from: staticStepRoute.id,
    }) as { stepId: string };

    return mapOpenStepIdToComponent(stepId);
  },
});

const mapPartialStepIdToComponent = (stepId: string) => {
  switch (true) {
    case isCheckoutStep(stepId):
      return <Checkout />;
    default:
      throw new Error(`Unknown partial step: ${stepId}`);
  }
};

export const partialStepRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `${StepType.questionpartial}/$stepId`,
  component: function PartialStepRoute() {
    // TODO: improve type
    const { stepId } = useParams({
      from: staticStepRoute.id,
    }) as { stepId: string };

    return mapPartialStepIdToComponent(stepId);
  },
});

const mapStaticStepIdToComponent = (stepId: string) => {
  switch (true) {
    case getStepName(stepId) === StepName.consult_focus:
      return <ConsultFocus />;
    case getStepName(stepId) === StepName.product_cannot_help_email:
      return <CanNotHelp />;
    case getStepName(stepId) === StepName.pain_point_address:
      return <PainPointAddress />;
    case getStepName(stepId) === StepName.loader_product:
      return <LoaderForProduct />;
    case getStepName(stepId) === StepName.terms:
      return <Terms />;
    case isValidationStep(stepId):
      return <Validation />;
    case isMilestoneStep(stepId):
      return <Milestone />;
    case isCompleteAccountStep(stepId):
      return <CompleteAccount />;
    case isProductStep(stepId):
      return <ProductStep />;
    case isProductCannotHelpLegalEncumbranceStep(stepId):
      return <CanNotHelpLegalEncumbrance />;
    default:
      return (
        <Navigate
          to={errorRoute.id}
          search={{
            message: 'Can not detect static step',
            stack: 'mapStaticStepIdToComponent',
          }}
        />
      );
  }
};

export const staticStepRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `${StepType.static}/$stepId`,
  component: function StaticStepRoute() {
    // TODO: improve type
    const { stepId } = useParams({
      from: staticStepRoute.id,
    }) as { stepId: string };

    return mapStaticStepIdToComponent(stepId);
  },
});

export const bookAppointmentRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `book-appointment`,
  component: lazy(() => import('../routes/BookAppointment')),
});

export const scheduledCallInfoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `scheduled-call-info`,
  component: lazy(() => import('../routes/ScheduledCallInfo')),
});

export const redirectToDashboardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `redirect-to-dashboard`,
  loader: () => {
    const sessionToken = stytch.session.getTokens()?.session_token;
    if (!sessionToken)
      throw new Error('Session token should be present at dashboard redirect route');

    setTimeout(() => {
      const tokens = stytch.session.getTokens();

      window.location.replace(
        `${getDashboardURL()}/${DASHBOARD_ROUTES.sessionAuthenticate}?sessionToken=${
          tokens?.session_token ?? ''
        }&roadmap=true`,
      );
    }, 5000);
  },

  component: () => (
    <Center mt="xl">
      <Stack
        spacing={24}
        align="center"
      >
        <StepTitle>Logging into to your dashboard</StepTitle>
        <Loader
          size="xl"
          color="primary.0"
        />
      </Stack>
    </Center>
  ),
});

export const stepSubmitRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `submit-step/$stepId`,
  validateSearch: (search) => {
    return z
      .object({
        answer: z.string().optional(),
        replace: z.boolean().optional(),
      })
      .parse(search);
  },
  loader: async ({ search, params }) => {
    const { stepId } = params;
    if (!getStepName(stepId))
      throw new Error('Known step id should be provided for posing to backend');

    const { answer, replace } = search;

    const formData = new FormData();
    formData.append('stepId', stepId);
    formData.append('answer', answer ? answer : 'viewed');

    const updatedCase = await updateCase(formData);

    navigateToNextStepOrUrl(updatedCase, replace, 5000);
  },
  component: () => {
    return (
      <Center mt="xl">
        <Stack
          spacing={24}
          align="center"
        >
          <StepTitle>Please wait, you will be redirected to the site shortly</StepTitle>
          <Loader
            size="xl"
            color="primary.0"
          />
        </Stack>
      </Center>
    );
  },
});

export const fromJotformRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `integration/jotform`,
  validateSearch: (search) => {
    return z
      .object({
        deal_id: z.number(),
        submission_id: z.number(),
      })
      .parse(search);
  },
  loader: async ({ search }) => {
    // Wait for re-hydration is done before we start accessing the cache or fetching data
    await persistQueryClientRestore({
      queryClient,
      persister: localStoragePersister,
      maxAge: sevenDays,
    });

    await stytch.session.authenticate();

    const caseState = await queryClient.fetchQuery({
      queryKey: ['caseState'],
      queryFn: fetchCaseState,
      staleTime: 0,
    });

    if (!caseState) throw new Error('Case state should be defined in fromDashboardRoute');

    const stepId = caseState?.step?.id;

    if (!stepId) throw new Error('Fails to find stepId');

    const formData = new FormData();
    formData.append('stepId', stepId);
    formData.append('dealId', search.deal_id.toString());
    formData.append('submissionId', search.submission_id.toString());

    const updatedCase = await updateCase(formData);

    emitHeapEvents(caseState.case.fields, updatedCase.case.fields);

    navigateToNextStepOrUrl(updatedCase, false, 0);
  },
  component: () => {
    return (
      <Center mt="xl">
        <Stack
          spacing={24}
          align="center"
        >
          <StepTitle>Please wait, you will be redirected to the site shortly</StepTitle>
          <Loader
            size="xl"
            color="primary.0"
          />
        </Stack>
      </Center>
    );
  },
});

export const fromDashboardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: `integration/dashboard`,
  validateSearch: (search) => {
    return z
      .object({
        token: z.string(),
      })
      .parse(search);
  },
  loader: async ({ search }) => {
    const { token } = search;

    await updateStytchSession(token);

    const caseState = await queryClient.fetchQuery({
      queryKey: ['caseState'],
      queryFn: fetchCaseState,
      staleTime: 0,
    });

    if (!caseState) throw new Error('Case state should be defined in fromDashboardRoute');

    const nextStepId = caseState.step?.id;
    if (nextStepId && !!getStepName(nextStepId) && isStepGuard(caseState.step)) {
      queryClient.setQueryData(['step', nextStepId], {
        ...caseState.step,
        counter: Object.values(caseState.case.fields).length - 3,
      });
    }

    const nextUrl = mapStepIdToUrl(caseState);
    if (nextUrl && urlScheme.safeParse(nextUrl).success) {
      window.location.assign(nextUrl);

      return;
    }

    const nextStep = mapStepIdToRoute(caseState);

    router.navigate({
      to: nextStep,
      params: { stepId: nextStepId },
    });
  },
  component: () => {
    return (
      <Center mt="xl">
        <Loader
          size="xl"
          color="primary.0"
        />
      </Center>
    );
  },
});

export const resetFromDashboard = new Route({
  getParentRoute: () => rootRoute,
  path: 'integration/reset',
  validateSearch: (search) => {
    return z
      .object({
        token: z.string(),
      })
      .parse(search);
  },
  loader: async ({ search }) => {
    const partnershipParam = (search as { partnership?: string | number })?.partnership;
    const partnership = hasValidPartnership(`${partnershipParam}`) ? `${partnershipParam}` : '';
    const { token } = search;

    await updateStytchSession(token);

    // Compatibility with sequence 423543b6-9a59-4f65-8de3-f5a759c5b6a7 and earlier
    // we need to remove it for the next sequence where we
    // don't have `product-cannot-help-legal-encumbrance` anymore
    // For now we need to post the answer to close the case.
    // Currently user can have not more than one active case
    // BE doesn't allow to create a new case if the user has an active one

    const ongoingCaseState = await queryClient.fetchQuery<CaseState>({
      queryKey: ['caseState'],
      queryFn: fetchCaseState,
      staleTime: 0,
    });
    const stepId = ongoingCaseState.step?.id;
    if (stepId && getStepName(stepId) === StepName.legal_encumbrance) {
      const formData = new FormData();
      formData.append('stepId', stepId);
      formData.append('answer', 'viewed product-cannot-help-legal-encumbrance');

      await updateCase(formData);
    }

    await clearCaseStateAndLocalStorage();

    // the user is authorized, so we provide token for that request
    // and backend session is created
    const sequence = await queryClient.fetchQuery({
      queryKey: ['sequence'],
      queryFn: fetchSequence,
      staleTime: 0,
    });

    const caseState = await createCase(sequence?.id, partnership);

    queryClient.setQueryData(['caseState'], caseState);

    const firstStepId =
      caseState.step?.id ?? isStepGuard(sequence?.steps[0]) ? (sequence?.steps[0] as Step).id : '';
    if (getStepName(firstStepId)) {
      queryClient.setQueryData(['step', firstStepId], {
        ...caseState.step,
        counter: 1,
      });
    } else {
      throw new Error('Could not find first step');
    }
  },

  component: () => {
    const firstStepId = queryClient.getQueryData<CaseState>(['caseState'])?.step?.id;

    return (
      <>
        {isStepNameGuard(firstStepId) && (
          <Navigate
            to={choiceStepRoute.id}
            params={{ stepId: firstStepId }}
            replace
          />
        )}
      </>
    );
  },
});

const errorSearchSchema = z.object({
  message: z.string().or(
    z.array(
      z.object({
        code: z.string(),
        message: z.string(),
      }),
    ),
  ),
  status: z.number().optional(),
  type: z.string().optional(),
  stack: z.string().optional(),
  networkPayload: z.instanceof(Object).optional(),
});

export type ErrorSearchParams = z.infer<typeof errorSearchSchema>;

export const errorRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'error',
  validateSearch: (search) => {
    return errorSearchSchema.parse(search);
  },
  loader: ({ search }) => {
    if (validateHeapSafely()) {
      search.networkPayload = JSON.stringify(search.networkPayload);

      window.heap.track('error-route-event', search);
    }
  },
  component: function ErrorRoute() {
    return <GeneralError />;
  },
});

export const indexErrorRoute = new Route({
  getParentRoute: () => errorRoute,
  path: '/',
  component: () => {
    return <Layout />;
  },
});

export const infoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'info',
  component: () => {
    return <Info />;
  },
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  firsStepSubmitRoute,
  choiceStepRoute,
  openStepRoute,
  multiChoiceStepRoute,
  partialStepRoute,
  staticStepRoute,
  fromJotformRoute,
  fromDashboardRoute,
  resetFromDashboard,
  bookAppointmentRoute,
  scheduledCallInfoRoute,
  magicLinkSentRoute,
  stepSubmitRoute,
  redirectToDashboardRoute,
  ssoAuthenticateRoute,
  infoRoute,
  errorRoute.addChildren([indexErrorRoute]),
]);

export const router = new Router({
  defaultErrorComponent: navigateToErrorRouteComponent,
  defaultPendingComponent: () => {
    <Center mt="xl">
      <Loader
        size="xl"
        color="primary.0"
      />
    </Center>;
  },
  routeTree,
  defaultPreload: 'intent',
  onRouteChange: () => {
    window.scrollTo(0, 0);
  },
});

declare module '@tanstack/router' {
  interface Register {
    router: typeof router;
  }
}
