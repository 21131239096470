import { PainPoint } from '@/state/state.types';
import { StepRecordContentByDependency } from './stepContent.types';

export const painPointContent: StepRecordContentByDependency<PainPoint> = {
  affordability: {
    header: `I owe more than I can afford`,
    title: `Medical bills can seem impossible, but there is hope.`,
    subtitle: `Our experts can take over and maximize your savings.`,
  },
  'fair-price': {
    header: `Being charged a fair price`,
    title: `Healthcare costs can feel like they're out of control.`,
    subtitle: `We can take over and make sure you pay a reasonable amount.`,
  },
  'credit-collections': {
    header: `Credit Score / Collections`,
    title: `Collections and credit don't have to be scary.`,
    subtitle: `We'll help remove medical bills from your credit and prevent existing bills from affecting your credit score.`,
  },
  effort: {
    header: `Time/Effort spent correcting`,
    title: `We recognize medical billing is complex.`,
    subtitle: `Our experts can take over and make sense of everything so you no longer have to deal with it.`,
  },
  'billing-errors': {
    header: `Billing issues or errors`,
    title: `This is unfortunately all too common.`,
    subtitle: `However - we can find and correct these issues for you.`,
  },
  overwhelmed: {
    header: `I'm completely overwhelmed`,
    title: `We understand that this process can be overwhelming.`,
    subtitle: `We're ready to take over so you can focus on the more important things in life.`,
  },
  other: {
    header: `Other`,
    title: `Resolving medical billing issues is what we do.`,
    subtitle: `No matter what you're facing, our team is experienced and equipped to step in to help.`,
  },
};
