import { ComponentPropsWithoutRef } from 'react';

export const ArrowRightCircledFilled = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_895_10161)">
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={props.fill}
      />
      <path
        d="M13.7133 7.95332L17.6667 12M17.6667 12L13.7133 16.0467M17.6667 12H6.44666"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
