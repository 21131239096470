import {
  choiceStepRoute,
  multiChoiceStepRoute,
  openStepRoute,
  partialStepRoute,
  staticStepRoute,
} from '@/router/router';
import { NextPath } from '@/router/router.types';
import { CaseState, StepType } from '@/state/state.types';

export const mapStepIdToRoute = (caseState: CaseState): NextPath | undefined => {
  if (caseState.step?.type === StepType.choice) {
    return choiceStepRoute.id;
  }
  if (caseState.step?.type === StepType.open) {
    return openStepRoute.id;
  }
  if (caseState.step?.type === StepType.static) {
    return staticStepRoute.id;
  }
  if (caseState.step?.type === StepType.multi_choice) {
    return multiChoiceStepRoute.id;
  }
  if (caseState.step?.type === StepType.questionpartial) {
    return partialStepRoute.id;
  }
};
