import { Stack, Text } from '@mantine/core';

export const Footnotes = ({
  footnotes,
  textProps = {},
}: {
  footnotes: string[];
  textProps?: any;
}) => {
  return footnotes?.length ? (
    <Stack
      mt={40}
      spacing={8}
      ta="center"
    >
      {footnotes.map((footnote) => (
        <Text
          key={footnote}
          c="primary.1"
          {...textProps}
        >
          {footnote}
        </Text>
      ))}
    </Stack>
  ) : null;
};
