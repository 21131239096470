import { DealLost, FieldName, Fields, Product } from '@/state/state.types';
import { validateHeapSafely } from './heap.types';

export const emitHeapEvents = (prevFields: Fields | undefined, nextFields: Fields | undefined) => {
  if (!prevFields || !nextFields) return;
  if (!validateHeapSafely()) return;

  addUserProperties(prevFields, nextFields);

  if (detectOfferConsult(prevFields, nextFields)) {
    window.heap.track('offer-consult-event', {
      answer: nextFields['offer-consult'],
    });
  }

  if (detectTCAccepted(prevFields, nextFields)) {
    window.heap.track('tc-accepted-event');
  }

  if (detectHipaaSigned(prevFields, nextFields)) {
    window.heap.track('hipaa-signed-event');
  }

  if (detectPaymentHappened(prevFields, nextFields)) {
    window.heap.track('payment-happened-event');
  }

  if (detectActivationCompleted(prevFields, nextFields)) {
    window.heap.track('activation-complete-event');
  }

  if (detectSmallBillSize(prevFields, nextFields)) {
    window.heap.track('decline-bill-size-event');
  }

  if (detectLegalEncumbrance(prevFields, nextFields)) {
    window.heap.track('decline-legal-encumbrance-event');
  }

  if (detectEZFile(prevFields, nextFields)) {
    window.heap.track('ezfile-event');
  }

  if (detectPaymentError(prevFields, nextFields)) {
    window.heap.track('payment-error');
  }
};

const detectOfferConsult = (prevFields: Fields, nextFields: Fields) => {
  const isOfferConsultSelected = !prevFields['offer-consult'] && !!nextFields['offer-consult'];
  return isOfferConsultSelected;
};

const detectTCAccepted = (prevFields: Fields, nextFields: Fields) => {
  const isTCAccepted = !prevFields.agreement && !!nextFields.agreement;
  return isTCAccepted;
};

const detectHipaaSigned = (prevFields: Fields, nextFields: Fields) => {
  const isHipaaSigned = !prevFields['hipaa-signed'] && !!nextFields['hipaa-signed'];
  return isHipaaSigned;
};

const detectPaymentHappened = (prevFields: Fields, nextFields: Fields) => {
  const isDepositPaid = !prevFields['deposit-payment-id'] && !!nextFields['deposit-payment-id'];
  const isSubscriptionPaid =
    !prevFields['subscription-payment-id'] && !!nextFields['subscription-payment-id'];
  return isDepositPaid || isSubscriptionPaid;
};

const detectPaymentError = (prevFields: Fields, nextFields: Fields) => {
  const isPaymentError = !prevFields['payment-error'] && !!nextFields['payment-error'];
  return isPaymentError;
};

const detectActivationCompleted = (prevFields: Fields, nextFields: Fields) => {
  const allFieldsFulfilled =
    !!nextFields['agreement'] &&
    !!nextFields['hipaa-signed'] &&
    (!!nextFields['deposit-payment-id'] || !!nextFields['subscription-payment-id']);

  const isActivationCompleted =
    (detectTCAccepted(prevFields, nextFields) ||
      detectHipaaSigned(prevFields, nextFields) ||
      detectPaymentHappened(prevFields, nextFields)) &&
    allFieldsFulfilled;

  return isActivationCompleted;
};

const checkFieldWasSetToValue = (prev: Fields, next: Fields, fld: FieldName, val: string) => {
  return prev[fld] !== val && next[fld] === val;
};

const detectSmallBillSize = (prevFields: Fields, nextFields: Fields) => {
  return checkFieldWasSetToValue(
    prevFields,
    nextFields,
    FieldName.deal_lost,
    DealLost.Enum['bill-size-too-small'],
  );
};

const detectLegalEncumbrance = (prevFields: Fields, nextFields: Fields) => {
  return (
    checkFieldWasSetToValue(
      prevFields,
      nextFields,
      FieldName.deal_lost,
      DealLost.Enum['in-collections'],
    ) ||
    checkFieldWasSetToValue(prevFields, nextFields, FieldName.deal_lost, DealLost.Enum['ezfile'])
  );
};

const detectEZFile = (prevFields: Fields, nextFields: Fields) => {
  return checkFieldWasSetToValue(prevFields, nextFields, FieldName.product, Product.Enum.ezfile);
};

const addUserProperties = (prevFields: Fields, nextFields: Fields) => {
  const userProperties: Fields = {};

  const userPropertyList = [
    FieldName.bill_size,
    FieldName.bill_frequency,
    FieldName.feeling,
    FieldName.product,
  ];

  userPropertyList.forEach((el) => {
    if (nextFields[el] && prevFields[el] !== nextFields[el]) {
      userProperties[el] = nextFields[el];
    }
  });

  if (Object.keys(userProperties).length > 0) {
    // https://developers.heap.io/reference/adduserproperties
    // That's a network call(not cheap). So we add properties only if they are changed.
    window.heap.addUserProperties(userProperties);
  }
};
