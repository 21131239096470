import { StepContent } from './stepContent.types';

export const loaderForProductContent: StepContent = {
  title: 'Analyzing your situation',
  testimonials: [
    {
      quote: 'The peace of mind we had… was life changing. And couldn’t be more grateful',
      name: 'Sonja',
      saving: '$500,000',
    },
    {
      quote: 'This company was amazing to work with… Resolve gets all the stars.',
      name: 'Kelsey',
      saving: '$10,000',
    },
  ],
};
