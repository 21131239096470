import { Avatar } from '@mantine/core';

import alyanna from '../assets/img/sales/alyanna-tomas.webp';
import camille from '../assets/img/sales/camille-derecho.webp';
import denise from '../assets/img/sales/denise-merriman.webp';
import erika from '../assets/img/sales/erika-zeigler.webp';
import katrina from '../assets/img/sales/katrina-smith.webp';
import melissa from '../assets/img/sales/melissa-johnson.webp';

export const SalesAvatars = () => {
  return (
    <>
      <Avatar
        src={melissa}
        size={36}
      />
      <Avatar
        src={erika}
        size={36}
      />
      <Avatar
        src={camille}
        size={36}
      />
      <Avatar
        src={alyanna}
        size={36}
      />
      <Avatar
        src={denise}
        size={36}
      />
      <Avatar
        src={katrina}
        size={36}
      />
    </>
  );
};
