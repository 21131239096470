import { useFields } from '@/routes/hooks/useFields';
import { BillSize, StepName } from '@/state/state.types';
import { shouldShowSalesBannerByStepId } from '@/utils/showLayoutElements';
import { SalesBanner } from './SalesBanner';
import { useBillSize } from '@/routes/hooks/useBillSize';

export const CheckAndDisplaySalesBanner = (stepId: string) => {
  const fields = useFields();
  const { billSizeUnder15k } = useBillSize();

  const isValidPath = [StepName.medical_provider_name, StepName.in_collections].includes(stepId);

  if (billSizeUnder15k || !isValidPath || !BillSize.safeParse(fields?.['bill-size']).success)
    return null;

  return shouldShowSalesBannerByStepId(stepId) && <SalesBanner stepId={stepId} />;
};
