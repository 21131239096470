import { AllowedPatchField } from '../state/state.types';

enum TrackingMetric {
  gclid = 'gclid',
  msclkid = 'msclkid',
  fbclid = 'fbclid',
  utm_campaign = 'utm_campaign',
  utm_network = 'utm_network',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
  utm_medium = 'utm_medium',
  utm_source = 'utm_source',
}

type TrackingMetrics = Partial<{
  [value in TrackingMetric]: string;
}>;

export const collectTrackingMetrics: () => TrackingMetrics = () => {
  // REVIEW: Bring it back when we referrals from Sagewell
  // const isSagewell = window.location.href.indexOf('sagewell') > -1;

  const searchParams = new URL(window.location.href).searchParams;
  const metrics: TrackingMetrics = {};

  for (const metric of Object.values(TrackingMetric)) {
    const value = searchParams.get(metric);
    if (value) {
      metrics[metric] = value;
    }
  }

  return metrics;
};

const metricToFieldMap: Record<TrackingMetric, AllowedPatchField> = {
  gclid: AllowedPatchField.gclid,
  msclkid: AllowedPatchField.msclkid,
  fbclid: AllowedPatchField.fbclid,
  utm_campaign: AllowedPatchField['utm-campaign'],
  utm_network: AllowedPatchField['utm-network'],
  utm_term: AllowedPatchField['utm-term'],
  utm_content: AllowedPatchField['utm-content'],
  utm_medium: AllowedPatchField['utm-medium'],
  utm_source: AllowedPatchField['utm-source'],
};

export const mapMetricsToAllowedPatchFields = (metrics: TrackingMetrics) => {
  const fields: Partial<Record<AllowedPatchField, string>> = {};

  for (const [metric, value] of Object.entries(metrics)) {
    const field = metricToFieldMap[metric as TrackingMetric];
    if (field) {
      fields[field] = value;
    }
  }

  return fields;
};
