import { ComponentPropsWithoutRef } from 'react';

export const ChevronDown = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M6.06841 6.99566L10.3809 11.3047L14.6934 6.99219C15.1274 6.55816 15.8288 6.55816 16.2594 6.99219C16.6934 7.42622 16.6934 8.12761 16.2594 8.55816L11.1587 13.6623C10.7281 14.0964 10.0267 14.0964 9.59272 13.6623L4.49202 8.56164C4.058 8.13108 4.058 7.42969 4.49202 6.99566C4.92605 6.57205 5.63786 6.56164 6.06841 6.99566Z"
      fill="currentColor"
    />
  </svg>
);
