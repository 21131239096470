import { Center, Loader } from '@mantine/core';
import { useParams, useSearch } from '@tanstack/router';
import { useEffect } from 'react';
import { firsStepSubmitRoute } from '../router/router';
import { isStepNameGuard } from '../state/state.types';
import { useFirstStepSubmitter } from './hooks/useFirstStepSubmitter';

// NOTE: we keep posting in the component instead of putting it in onLoad
// because we have to wait for caseState is setup in cache before posting.
// It's easier with useQuery than creating a custom query cache subscription.
// NOTE: We post from the component which guarantees that we router is in idle state
// and all onLoads are finished
export const FirstStepSubmitter = () => {
  const { stepId } = useParams({ from: firsStepSubmitRoute.id });
  const searchParams = useSearch({ from: firsStepSubmitRoute.id });

  const { submit } = useFirstStepSubmitter();

  useEffect(() => {
    const choice = searchParams.choice;
    if (!choice) return;

    if (!stepId || !isStepNameGuard(stepId))
      throw new Error('Current stepId should be questionnaire step id');

    const form = new FormData();
    form.append('stepId', stepId);
    form.append('answer', choice);

    submit(form);
  }, [searchParams.choice, stepId, submit]);

  return (
    <Center mt="xl">
      <Loader
        size="xl"
        color="primary.0"
      />
    </Center>
  );
};
