import { ComponentPropsWithoutRef } from 'react';

export const ArrowDownward = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <path
      d="M11.9668 0.601356C11.3985 0.601356 10.9336 1.0663 10.9336 1.63458L10.9336 9.47672L1.75856 0.301721C1.66291 0.206064 1.54934 0.130184 1.42436 0.0784146C1.29938 0.0266451 1.16542 -5.14119e-07 1.03014 -5.23219e-07C0.894862 -5.3232e-07 0.760906 0.0266451 0.635923 0.0784146C0.510941 0.130184 0.397378 0.206064 0.301721 0.301721C0.206062 0.397379 0.130184 0.510941 0.0784149 0.635923C0.0266456 0.760906 -3.91157e-08 0.894861 -4.50289e-08 1.03014C-5.09422e-08 1.16542 0.0266456 1.29938 0.0784149 1.42436C0.130184 1.54934 0.206062 1.6629 0.301721 1.75856L9.47672 10.9336L1.63458 10.9336C1.0663 10.9336 0.601354 11.3985 0.601354 11.9668C0.601354 12.5351 1.0663 13 1.63458 13L11.9668 13C12.5351 13 13 12.5351 13 11.9668L13 1.63458C13 1.0663 12.5351 0.601356 11.9668 0.601356Z"
      fill="currentColor"
    />
  </svg>
);
