import { ComponentPropsWithoutRef } from 'react';

export const SquareLogo = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 80 20"
    {...props}
  >
    <g
      fill="currentColor"
      clipPath="url(#a)"
    >
      <path
        fillRule="evenodd"
        d="M3.9 0H17a3.3 3.3 0 0 1 3.3 3.4v13.2c0 1.8-1.5 3.3-3.3 3.3H3.9a3.3 3.3 0 0 1-3.3-3.3V3.4C.6 1.6 2 .1 3.9.1Zm11.8 16.3c.6 0 1-.5 1-1V4.7c0-.5-.4-1-1-1H5.2a1 1 0 0 0-1 1v10.6c0 .5.4 1 1 1h10.5Z"
        clipRule="evenodd"
      />
      <path d="M8.4 12.7a.6.6 0 0 1-.6-.6V7.9a.6.6 0 0 1 .6-.6h4.2a.6.6 0 0 1 .6.6V12a.6.6 0 0 1-.6.6H8.4Zm22-3.6-1.6-.5c-.8-.4-1.2-.9-1.2-1.6 0-1.4 1.4-2 2.7-2 1.3 0 2.4.5 3.1 1.4h.1l1-.8a5 5 0 0 0-4.1-2 5 5 0 0 0-3 .9A3 3 0 0 0 26.3 7c0 2.5 2.3 3 4.2 3.6 1.9.5 3 .8 3 2.3 0 1.4-1.1 2.3-2.9 2.3a4 4 0 0 1-3.5-1.8l-1.2.8c1 1.5 2.7 2.3 4.6 2.3 2.7 0 4.4-1.5 4.4-3.7 0-2.5-2.4-3.2-4.3-3.7Z" />
      <path
        fillRule="evenodd"
        d="M43.4 8.6V7.3h1.3v12.6h-1.3v-5c-.7 1-1.8 1.6-3 1.6-2.5 0-4.2-2-4.2-4.7 0-2.8 1.7-4.7 4.1-4.7 1.3 0 2.4.5 3.1 1.5Zm-5.8 3.2c0 2.6 1.5 3.5 2.8 3.5 1.9 0 3-1.4 3-3.5s-1.1-3.5-3-3.5c-2 0-2.8 1.8-2.8 3.5Z"
        clipRule="evenodd"
      />
      <path d="M53 7.3v5c0 1.7-1.1 3-2.8 3-1.3 0-2-.8-2-2.5V7.3h-1.3V13c0 2.1 1.2 3.4 3.2 3.4 1.2 0 2.3-.5 3-1.5v1.3h1.2v-9h-1.2Z" />
      <path
        fillRule="evenodd"
        d="M56.7 8.2a6 6 0 0 1 3.5-1.1c2 0 3.2 1 3.2 2.6v6.6h-1.2v-1c-.7.8-1.6 1.2-2.8 1.2-2 0-3.2-1-3.2-2.8 0-2.2 2.1-2.5 3-2.7h.5c1.2-.2 2.5-.4 2.5-1.5s-1.6-1.2-2-1.2a4 4 0 0 0-2.8 1l-.7-1Zm.9 5.5c0 1.4 1.3 1.6 2 1.6 1.2 0 2.6-.7 2.6-2.6v-1.3c-.7.4-1.5.5-2.3.7h-.4c-1.3.3-2 .6-2 1.6Z"
        clipRule="evenodd"
      />
      <path d="M70.8 7.4c-.3-.2-.8-.3-1.3-.3a3.2 3.2 0 0 0-2.6 1.3V7.3h-1.2v9h1.2v-4.8c0-2.2 1.2-3.1 2.4-3.1.4 0 .7 0 1 .2h.1l.4-1.1Z" />
      <path
        fillRule="evenodd"
        d="M71.1 11.8c0-2.8 1.8-4.7 4.3-4.7 2.4 0 4 1.7 4 4.2v.7h-7c.1 2 1.3 3.3 3.1 3.3 1 0 2-.4 2.6-1.2V14l1 .8c-.7.8-1.8 1.7-3.6 1.7-2.6 0-4.4-1.9-4.4-4.7Zm4.2-3.5c-1.5 0-2.6 1-2.8 2.6h5.6c-.1-1.3-.9-2.6-2.8-2.6Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M.5 0h79v20H.5z"
        />
      </clipPath>
    </defs>
  </svg>
);
