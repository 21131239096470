import { stytch } from '../auth/stytch';

/**
 * Allow stytch SDK to access the token
 *
 * @param token - Stytch opaque session token
 */
export const updateStytchSession = async (token: string, jwt_token?: string) => {
  stytch.session.updateSession({
    session_token: token,
    // We need to provide not empty value for the session_jwt
    // https://stytch.slack.com/archives/C015UDB4X33/p1681417260954519?thread_ts=1681414336.994369&cid=C015UDB4X33
    session_jwt: jwt_token || 'fake-token',
  });
  await stytch.session.authenticate();
};
