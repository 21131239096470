import { ComponentPropsWithoutRef } from 'react';

export const ArrowLeft = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M9.57 18.0699L3.5 11.9999L9.57 5.92995M20.5 11.9999H3.67"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
