import { TextInput, TextInputProps } from '@mantine/core';

import { HTMLInputTypeAttribute, ReactNode } from 'react';

export type InputProps = {
  type: HTMLInputTypeAttribute;
  name: string;
  label?: string;
  placeholder: string;
  isLoading: boolean;
  error: ReactNode;
  inputProps: TextInputProps;
};

export const Input = ({
  type,
  name,
  label,
  placeholder,
  isLoading,
  error,
  inputProps,
}: InputProps) => {
  return (
    <TextInput
      type={type}
      name={name}
      placeholder={placeholder}
      disabled={isLoading}
      label={label}
      labelProps={{
        fz: 'sm',
        fw: '600',
        c: 'primary.0',
        mb: 4,
      }}
      mt={{ base: 16, md: 24 }}
      radius={8}
      styles={(theme) => ({
        root: {
          flex: 1,
        },
        input: {
          borderColor: error ? `${theme.colors.error[5]} !important` : theme.colors.primary[1],
          borderWidth: 2,
          fontSize: theme.fontSizes.md,
          height: 64,
          color: `${theme.colors.primary[0]} !important`,
          backgroundColor: theme.colors.minor[1],
          padding: 16,

          '&:focus': {
            borderColor: error ? 'inherit' : theme.colors.primary[0],
          },

          '&::placeholder': {
            color: '#2e193e4d !important',
          },
        },
        error: {
          fontSize: theme.fontSizes.sm,
          color: theme.colors.error[5],
          fontWeight: 600,
        },
      })}
      {...inputProps}
    />
  );
};
