import { ComponentPropsWithoutRef } from 'react';

export const Warning = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.025 7.584a1.431 1.431 0 0 1 2.486 0l17.732 29.43a.626.626 0 0 0 1.086-.621L28.996 6.163c-1.648-2.884-5.808-2.884-7.456 0L3.572 37.608c-1.636 2.863.431 6.424 3.728 6.424l34.428-.7a.73.73 0 0 0 0-1.461L7.3 41.169a1.431 1.431 0 0 1-1.243-2.142L24.025 7.584Zm-1.462 10.824a1.431 1.431 0 0 1 1.424-1.573h2.562c.847 0 1.509.73 1.424 1.573l-1.145 11.45a1.431 1.431 0 0 1-1.424 1.29h-.272a1.431 1.431 0 0 1-1.424-1.29l-1.145-11.45Zm5.568 17.034a2.863 2.863 0 1 1-5.726 0 2.863 2.863 0 0 1 5.726 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
