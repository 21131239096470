import { BillSize } from '@/state/state.types';
import { useFields } from './useFields';

export const useBillSize = () => {
  const fields = useFields();
  const billSize = fields?.['bill-size'] as string;

  const hasBillSize = !!billSize;
  const billSizeUnder15k = [BillSize.Enum['less-than-5k'], BillSize.Enum['5k-15k']].includes(
    billSize,
  );

  return { billSizeUnder15k, hasBillSize };
};
