export const Info = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: '#2e193e',
          padding: '40px',
        }}
      >
        <h1
          style={{
            fontSize: '64px',
            fontWeight: 'bold',
            color: '#fff1e3',
            margin: '10px 0 20px 0',
          }}
        >
          A Notice To Our Patients
        </h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '16px',
            color: '#fff1e3',
            gap: '10px',
            width: '100%',
          }}
        >
          <p>
            As of July 16th, 2024 we will no longer be taking new patients from non-partner
            organizations. If you came here from a partner organization, please use the link
            supplied to sign up.
          </p>
          <p>
            If you have already signed up for Resolve, you can still access your account by clicking
            Login in the upper right-hand corner.
          </p>
          <p>We will continue to find savings for all patients who have signed up.</p>
          <p>See below for some additional resources to help you lower your medical bills.</p>
          <p>
            We apologize for any inconvenience and are truly thankful for the thousands of people
            who we&apos;ve worked with to find savings.
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: '#e1d4eb',
          padding: '20px 40px 40px 40px',
          borderRadius: '36px',
        }}
      >
        <h2 style={{ fontSize: '48px', color: '#2e193e', margin: '10px 0 20px 0' }}>
          Additional Key Resources
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            fontSize: '14px',
            color: '#2e193e',
            gap: '10px',
          }}
        >
          <span>
            <a
              style={{ fontWeight: '600', color: '#2e193e' }}
              href="https://app.umbrahealthadvocacy.com/search"
              target="_blank"
              rel="noreferrer"
            >
              Umbra Health Advocacy
            </a>{' '}
            (directory of patient advocates)
          </span>
          <span>
            <a
              style={{ fontWeight: '600', color: '#2e193e' }}
              href="https://dollarfor.org/"
              target="_blank"
              rel="noreferrer"
            >
              Get Relief from Hospital Bills – Dollar For
            </a>{' '}
            (see if qualify for income-based financial aid)
          </span>
          <span>
            <a
              style={{ fontWeight: '600', color: '#2e193e' }}
              href="https://www.resolvemedicalbills.com/guides/medical-bill-guide"
              target="_blank"
              rel="noreferrer"
            >
              Resolve Guide to Lowering Medical Bills
            </a>{' '}
            (our completely free DIY guide to lowering your medical bills (warning - it&apos;s long)
          </span>
        </div>
      </div>
    </div>
  );
};
