import { stytch } from '@/auth/stytch';
import { Url } from '@/router/router.types';
import { CaseState } from '@/state/state.types';
import { getDashboardURL, DASHBOARD_ROUTES } from './dashboardUrl';

export const mapStepIdToUrl = (caseState: CaseState): Url | undefined => {
  if (!caseState.step?.id && caseState?.remaining === 0) {
    const tokens = stytch.session.getTokens();

    return `${getDashboardURL()}/${DASHBOARD_ROUTES.sessionAuthenticate}?sessionToken=${
      tokens?.session_token ?? ''
    }`;
  }
};
