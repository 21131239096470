import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';

export const sevenDays = 7 * 24 * 60 * 60 * 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: sevenDays,
      // Garbage collection time.
      // We don't use cache directly. We fetch data and store it in router URL or state.
      // We want to keep the data in cache even we don't have a subscription to it.
      cacheTime: Infinity,
      retry: 1,
    },
  },
});

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 0,
});
