import { ComponentPropsWithoutRef } from 'react';

export const Checkmark = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      d="M10.4768 15.8489L16.9128 6.40946C17.2045 5.98167 17.7878 5.87133 18.2155 6.163C18.6433 6.45468 18.7537 7.03792 18.462 7.46571L11.4308 17.7781C11.1142 18.2425 10.463 18.3258 10.0397 17.9562L5.82096 14.2732C5.43092 13.9327 5.39077 13.3405 5.73128 12.9504C6.07179 12.5604 6.66402 12.5203 7.05406 12.8608L10.4768 15.8489Z"
      fill="currentColor"
    />
  </svg>
);
