import { StepTitle } from '@/components/StepTitle';
import { Button, Flex, Group, List, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { NavigateOptions, useNavigate, useParams } from '@tanstack/router';
import { consultFocusContent } from '../content/productContent';
import { bookAppointmentRoute, staticStepRoute } from '../router/router';
import { useCaseUpdater } from './hooks/useCaseUpdater';

import { PrimaryButton } from '@/components/PrimaryButton';
import { StepForm } from '@/components/StepForm';
import { ArrowUpward } from '@/components/icons/ArrowUpward';
import { SalesAvatars } from '@/components/SalesAvatars';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

const ConsultFocus = () => {
  const navigate = useNavigate();
  const { stepId } = useParams({ from: staticStepRoute.id });
  const theme = useMantineTheme();
  const { onFormSubmit, isLoading } = useCaseUpdater({});
  const consultationLink = useConsultationLink(true);

  const { header, bulletList, subtitle } = consultFocusContent;

  if (!stepId) throw new Error('StepId should be defined on consult focus step');

  const search: NavigateOptions['search'] = {
    appointmentUrl: consultationLink,
    formData: [
      ['stepId', stepId],
      ['answer', 'true'],
    ],
  };

  return (
    <StepForm
      onSubmit={(e) => {
        window.heap?.track('redirect-to-checkout-bill-reduction', {
          stepId,
        });
        onFormSubmit(e);
      }}
      style={{
        marginTop: 0,
        alignItems: 'center',
        backgroundColor: theme.colors.primary[0],
        boxShadow: `0 0 0 100vmax ${theme.colors.primary[0]}`,
      }}
    >
      <input
        name="stepId"
        type="hidden"
        value={stepId}
      />
      <input
        name="answer"
        type="hidden"
        value="true"
      />

      <Flex
        direction={{ base: 'column' }}
        w="100%"
      >
        <Stack
          spacing={0}
          align="center"
          justify="center"
          w="100%"
          mt={50}
        >
          {!!header && (
            <StepTitle
              weight="600"
              c="neutral.6"
              align="center"
            >
              {header}
            </StepTitle>
          )}
          <Group
            spacing={4}
            mt={30}
          >
            <SalesAvatars />
          </Group>
        </Stack>
        <Stack
          spacing={0}
          align="center"
          justify="center"
          w="100%"
          mt={30}
        >
          {!!bulletList?.length && (
            <>
              {!!subtitle && (
                <Title
                  c="neutral.6"
                  size={20}
                  weight={700}
                  align="left"
                >
                  {subtitle}
                </Title>
              )}
              <List
                center
                spacing={16}
                mt={30}
                styles={{
                  item: {
                    lineHeight: 1.4,
                  },
                }}
                style={{
                  paddingLeft: 0,
                }}
              >
                {bulletList.map((item) => (
                  <List.Item
                    key={item.id}
                    fz="sm"
                    c="minor.1"
                  >
                    {item.text}
                  </List.Item>
                ))}
              </List>
            </>
          )}
        </Stack>
        <Flex
          align="center"
          justify="center"
          w="100%"
          mt={10}
          wrap="wrap"
        >
          <PrimaryButton
            align="start"
            type="button"
            isLoading={isLoading}
            isDisabled={isLoading}
            isOutlineHover
            mt={20}
            ml={10}
            mr={10}
            onClick={(e) => {
              e.preventDefault();
              window.heap?.track('redirect-to-calendly-click-event', {
                stepId,
              });
              navigate({
                search,
                to: bookAppointmentRoute.id,
              });
            }}
          >
            Schedule Your Free Consult
          </PrimaryButton>
          <Button
            type="submit"
            mt={20}
            ml={10}
            mr={10}
            loading={isLoading}
            sx={{
              root: {
                backgroundColor: 'transparent',
                border: 'none',
              },
            }}
            p={0}
          >
            <Group spacing={2}>
              <>
                <Text
                  mr={4}
                  fw={700}
                  c="neutral.6"
                >
                  No, Finish Without Consult
                </Text>
                <ArrowUpward color={theme.colors.tertiary[1]} />
              </>
            </Group>
          </Button>
        </Flex>
      </Flex>
    </StepForm>
  );
};

export { ConsultFocus };
