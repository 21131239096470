import { StepTitle } from '@/components/StepTitle';
import { FieldName } from '@/state/state.types';
import { getStepName } from '@/utils/stepUtils';
import { Flex, Text, useMantineTheme } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useParams } from '@tanstack/router';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { z } from 'zod';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { staticStepRoute } from '../router/router';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useOpenStepResponse } from './hooks/useOpenStepResponse';
import { ContentContainer } from '@/components/ContentContainer';
import { WhyDoWeAsk } from '@/components/WhyDoWeAsk';
import { whyActivateContent } from '@/content/whyActivateContent';
import { Input } from '@/components/ui/Input';

const completeAccountFields = z.object({
  [FieldName.first_name]: z
    .string()
    .min(1, { message: 'First name is required' })
    .max(128, { message: 'First name must be less than 128 characters' })
    .refine(
      (value) => {
        if (!value) return true;

        return !/[!@#$%^&*()_+\-=\\[\]{};:"\\|,.<>\\\d/?]/.test(value);
      },
      { message: 'Letters only, please.' },
    ),
  [FieldName.last_name]: z
    .string()
    .min(1, { message: 'Last name is required' })
    .max(128, { message: 'Last name must be less than 128 characters' })
    .refine(
      (value) => {
        if (!value) return true;

        return !/[!@#$%^&*()_+\-=\\[\]{};:"\\|,.<>\\\d/?]/.test(value);
      },
      { message: 'Letters only, please.' },
    ),
  [FieldName.phone]: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;

        return isValidPhoneNumber(value);
      },
      { message: 'Please enter a 10 digit phone number.' },
    ),
});

type CompleteAccountFields = z.infer<typeof completeAccountFields>;

const completeAccountFieldList = [
  { name: completeAccountFields.keyof().options[0] },
  { name: completeAccountFields.keyof().options[1] },
  {
    name: completeAccountFields.keyof().options[2],
    transform: (value: string) => {
      // TODO: find a better way to parse phone number
      // PhoneInput allows to set input value with preceding `+` sign
      // It's assumed that we have a US and CA phone number
      const number = value.replace('+1', '').replace(/\D/g, '');
      return `+1${number}`;
    },
  },
];

const CompleteAccount = () => {
  const theme = useMantineTheme();
  const { stepId } = useParams({ from: staticStepRoute.id });

  const stepName = getStepName(stepId);

  const { onFormSubmit, isLoading } = useCaseUpdater({});

  const form = useForm<CompleteAccountFields>({
    validate: zodResolver(completeAccountFields),
    validateInputOnBlur: true,
    initialValues: {
      'first-name': localStorage.getItem('first-name-sso') || '',
      'last-name': localStorage.getItem('last-name-sso') || '',
      phone: '',
    },
  });

  useOpenStepResponse<CompleteAccountFields>(completeAccountFieldList, form.setFieldValue);

  const checkIfStringIsEmail = (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  if (checkIfStringIsEmail(form.getInputProps('first-name').value)) {
    form.setFieldValue('first-name', '');
  }

  if (!stepId || !stepName) throw new Error('StepId and stepName should be defined');

  const { title: whyActivateTitle, description: whyActivateDescription } =
    whyActivateContent?.[stepId] ?? {};

  return (
    <Flex
      direction="column"
      h="100%"
      maw={516}
      style={{
        margin: '0 auto',
      }}
    >
      <StepTitle fz={{ base: 28, md: 32 }}>
        Welcome to Resolve! Tell us a little more about yourself.
      </StepTitle>
      <ContentContainer>
        <StepForm
          onSubmit={(e) => {
            localStorage.removeItem('first-name-sso');
            localStorage.removeItem('last-name-sso');
            onFormSubmit(e);
          }}
        >
          <input
            name="stepId"
            type="hidden"
            value={stepId}
          />

          <Flex
            justify="center"
            w="100%"
            gap={0}
            direction="column"
          >
            <Input
              type="text"
              label="First Name"
              name="first-name"
              inputProps={form.getInputProps('first-name')}
              placeholder="First Name"
              isLoading={isLoading}
              error={form.errors['first-name']}
            />

            <Input
              type="text"
              label="Last Name"
              name="last-name"
              inputProps={form.getInputProps('last-name')}
              placeholder="Last Name"
              isLoading={isLoading}
              error={form.errors['last-name']}
            />
          </Flex>

          <Flex
            direction="column"
            mt={24}
          >
            <Text
              color="primary.0"
              size="sm"
              weight="600"
            >
              Phone (Optional)
            </Text>
            <PhoneInput
              defaultCountry="US"
              name="phone"
              disabled={isLoading}
              style={{
                marginTop: 4,
                height: 64,
                color: theme.colors.primary[0],
                backgroundColor: theme.colors.minor[1],
                borderStyle: 'solid',
                borderColor: !form.values['phone']
                  ? theme.colors.primary[1]
                  : form.isValid('phone')
                  ? theme.colors.primary[1]
                  : form.errors.phone
                  ? theme.colors.error[5]
                  : theme.colors.neutral[1],
                borderWidth: 2,
                borderRadius: 8,
                paddingLeft: 10,
                fontFamily:
                  'Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
                // prevent default browser outline on focus
                outline: 'none',
                padding: 16,
              }}
              {...form.getInputProps('phone')}
            />
            {form.errors.phone && (
              <Text
                size="sm"
                weight="600"
                c="error.5"
                mt={4}
              >
                {form.errors.phone}
              </Text>
            )}
            <Text
              color="primary.0"
              size="sm"
              mt={8}
              w={{ base: '100%', md: 'auto' }}
            >
              Billing issues are resolved faster when we can communicate by phone.
            </Text>
          </Flex>
          <PrimaryButton
            isLoading={isLoading}
            isDisabled={
              !form.isValid('first-name') ||
              !form.isValid('last-name') ||
              !form.isValid('phone') ||
              isLoading
            }
            mt={24}
            mx="auto"
            maw={343}
            w="100%"
          >
            Continue
          </PrimaryButton>
        </StepForm>
      </ContentContainer>

      {whyActivateTitle && whyActivateDescription && (
        <Flex
          align="center"
          justify="center"
          mt="auto"
        >
          <WhyDoWeAsk
            title={whyActivateTitle}
            description={whyActivateDescription}
            stepId={stepId}
          />
        </Flex>
      )}
    </Flex>
  );
};

export { CompleteAccount };
