import { z } from 'zod';

export interface Heap {
  track: (event: string, properties?: object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: object) => void;
  addEventProperties: (properties: object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: unknown;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

const heap = z.object({
  appid: z.string(),
});

export const validateHeapSafely = () => heap.safeParse(window.heap).success;
