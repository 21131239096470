import { Button, Flex, Text, useMantineTheme } from '@mantine/core';
import { Checkmark } from '../icons/Checkmark';
import { ChevronDown } from '../icons/ChevronDown';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { ReactNode } from 'react';

export type SingleSelectButtonProps = {
  uniqueKey: string;
  isLoading: boolean;
  value: string | number | undefined;
  onClick: (value: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isRow: boolean;
  answer: string;
  label: ReactNode;
  isLast?: boolean;
  icon?: ReactNode;
  variant?: 'withIcons';
};

export const SingleSelectButton = ({
  uniqueKey,
  isLoading,
  value,
  onClick,
  isRow,
  answer,
  label,
  isLast = false,
  icon,
  variant,
}: SingleSelectButtonProps) => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();

  const withIcons = variant === 'withIcons';

  const isLastItemWithIcons = withIcons && isLast;

  return (
    <Button
      key={uniqueKey}
      type="submit"
      disabled={isLoading}
      name="answer"
      value={value}
      onClick={(e) => {
        onClick(`${value}`, e);
      }}
      py={16}
      px={16}
      display="flex"
      h="auto"
      mih={isMdBreakpoint ? 64 : 56}
      fullWidth
      radius={8}
      styles={(theme) => ({
        root: {
          borderColor: theme.colors.primary[1],
          borderWidth: 2,
          backgroundColor: answer === value ? theme.colors.primary[1] : 'white',
          transition: 'background 250ms linear',

          justifySelf: isMdBreakpoint && isLastItemWithIcons ? 'center' : 'auto',
          gridColumnStart: isMdBreakpoint && isLastItemWithIcons ? 'span 4' : 'auto',
          width: isMdBreakpoint && isLastItemWithIcons ? 'calc(50% - 0.5rem)' : 'auto',
          maxWidth: !withIcons && !icon ? 359 : '100%',
          minWidth:
            isMdBreakpoint && !withIcons && !icon
              ? 359
              : isMdBreakpoint && isLastItemWithIcons
              ? 'auto'
              : '100%',
          margin: !withIcons && !icon ? '0 auto' : 'none',

          '&:hover': {
            backgroundColor: isMdBreakpoint
              ? answer === value
                ? theme.colors.primary[1]
                : '#EFEAFC'
              : answer === value
              ? theme.colors.primary[1]
              : '#EFEAFC',
          },

          '&:disabled': {
            backgroundColor: answer === value ? theme.colors.primary[1] : 'white',
            borderColor: theme.colors.primary[1],
            borderWidth: 2,
          },
        },
        label: { whiteSpace: 'normal', width: '100%' },
        inner: {
          width: '100%',
          justifyContent: isRow ? 'center' : 'start',
        },
      })}
    >
      <Flex
        w="100%"
        h="100%"
        justify={withIcons && !isLast ? 'flex-start' : 'center'}
        align="center"
        direction={isMdBreakpoint ? 'column' : 'row'}
        gap={20}
      >
        <Flex
          w="100%"
          gap={10}
          style={{
            order: isMdBreakpoint ? 1 : 2,
            flex: 1,
            alignItems: icon && isMdBreakpoint ? 'self-start' : 'center',
          }}
        >
          <Text
            c={answer !== value ? 'primary.1' : 'white'}
            sx={{ flexGrow: 1 }}
            fw={700}
            ta={isRow || (icon && isMdBreakpoint) ? 'center' : 'start'}
          >
            {label}
          </Text>
          {((!icon && isMdBreakpoint) || !isMdBreakpoint) && (
            <>
              {!isRow ? (
                answer === value ? (
                  <Checkmark
                    color="white"
                    style={{ flexShrink: 0, alignSelf: 'center', rotate: '5deg' }}
                  />
                ) : (
                  <ChevronDown
                    color={theme.colors.primary[1]}
                    style={{ flexShrink: 0, alignSelf: 'center', rotate: '-90deg' }}
                  />
                )
              ) : null}
            </>
          )}
        </Flex>
        {icon && (
          <Flex
            style={{
              order: isMdBreakpoint ? 2 : 1,
            }}
          >
            {icon}
          </Flex>
        )}
      </Flex>
    </Button>
  );
};
