import { Flex, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { StepSubtitle } from './StepSubtitle';
import { motion } from 'framer-motion';

const ProductAnimatedSubtitle = () => {
  const theme = useMantineTheme();

  const [animationStep, setAnimationStep] = useState(0);

  useEffect(() => {
    const subtitleAnimationStep = setInterval(() => {
      setAnimationStep((value) => {
        if (value === 3) return 0;
        return value + 1;
      });
    }, 2500);
    return () => clearInterval(subtitleAnimationStep);
  }, []);

  return (
    <StepSubtitle
      c="minor.1"
      ta="center"
      sx={{ lineHeight: '22px' }}
    >
      <Flex
        gap={6}
        direction="row"
        justify="center"
        wrap="wrap"
        mt={20}
      >
        <span>With Resolve, your future is </span>
        <motion.div
          style={{
            display: 'flex',
            position: 'relative',
            width: '152px',
            flexDirection: 'column',
            borderBottom: `2px solid ${theme.colors.secondary[0]}`,
            height: 28,
            overflow: 'hidden',
          }}
        >
          <motion.span
            animate={`${animationStep}`}
            variants={{
              '0': { translateY: 0 },
              '1': {
                translateY: -24,
              },
              '2': {
                translateY: 24,
                transition: {
                  duration: 0,
                },
              },
              '3': {
                translateY: 24,
              },
            }}
            style={{
              width: '100%',
              position: 'absolute',
              fontWeight: 700,
              color: theme.colors.secondary[1],
            }}
          >
            financially sound.
          </motion.span>
          <motion.span
            initial={{
              translateY: 24,
            }}
            animate={`${animationStep}`}
            variants={{
              '0': { translateY: 24 },
              '1': {
                translateY: 0,
              },
              '2': {
                translateY: -24,
              },
              '3': {
                translateY: 24,
                transition: {
                  duration: 0,
                },
              },
            }}
            style={{
              width: '100%',
              position: 'absolute',
              fontWeight: 700,
              color: theme.colors.secondary[1],
            }}
          >
            stress free.
          </motion.span>
          <motion.span
            initial={{
              translateY: 24,
            }}
            animate={`${animationStep}`}
            variants={{
              '0': {
                translateY: 24,
                transition: {
                  duration: 0,
                },
              },
              '1': { translateY: 24 },
              '2': {
                translateY: 0,
              },
              '3': {
                translateY: -24,
              },
            }}
            style={{
              width: '100%',
              position: 'absolute',
              fontWeight: 700,
              color: theme.colors.secondary[1],
            }}
          >
            clearer
          </motion.span>
          <motion.span
            animate={`${animationStep}`}
            variants={{
              '0': { translateY: -24 },
              '1': { translateY: 24, transition: { duration: 0 } },
              '2': { translateY: 24 },
              '3': { translateY: 0 },
            }}
            style={{
              width: '100%',
              position: 'absolute',
              fontWeight: 700,
              color: theme.colors.secondary[1],
            }}
          >
            in your control.
          </motion.span>
        </motion.div>
      </Flex>
    </StepSubtitle>
  );
};

export { ProductAnimatedSubtitle };
