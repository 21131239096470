import { Anchor, Button, Card, Flex, Group, Image, Text, useMantineTheme } from '@mantine/core';
import { useParams } from '@tanstack/router';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { staticStepRoute } from '../router/router';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { ArrowUpward } from '@/components/icons/ArrowUpward';

import dollarForSrc from '../assets/img/dollar-for.png';
import { useCallback, useEffect, useState } from 'react';
import { updateCase } from '@/state/updateCase';
import { navigateToNextStepOrUrl } from '@/utils/navigateToNextStepOrUrl';

enum faAnswers {
  yes = 'yes',
  rejected = 'rejected',
  no = 'no',
}

export const FinancialAid = () => {
  const theme = useMantineTheme();

  const { stepId } = useParams({ from: staticStepRoute.id });
  const { isLoading } = useCaseUpdater({});

  const [answer, setAnswer] = useState<faAnswers | ''>('');

  const handleSumit = useCallback(
    async (faAnswer: faAnswers) => {
      const form = new FormData();
      form.append('stepId', stepId);
      form.append('answer', faAnswer);
      const caseState = await updateCase(form);

      if (faAnswer !== faAnswers.yes) {
        navigateToNextStepOrUrl(caseState);
      }
    },
    [stepId],
  );

  useEffect(() => {
    if (answer) {
      handleSumit(answer);
    }
  }, [answer, handleSumit]);

  if (!stepId)
    throw new Error('StepId or stepName should be defined and should be a product or prep step.');

  return (
    <StepForm>
      <Card
        bg="tertiary.0"
        radius={12}
        w="100%"
        p={26}
      >
        <Image
          src={dollarForSrc}
          style={{
            width: 168,
          }}
          mb={15}
        />
        <Text
          size={22}
          c="white"
          fw={700}
          display="flex"
        >
          You&nbsp;
          <Text
            size={22}
            c="minor.0"
            fw={700}
            display="contents"
          >
            should
          </Text>
          &nbsp;qualify for Financial Aid!
        </Text>
        <Text
          size={16}
          c="white"
          mt={10}
        >
          Based on the income and household size you shared, you should qualify for Financial Aid
          from the hospital.
        </Text>
        <Text
          size={16}
          c="white"
          mt={10}
          display="flex"
        >
          We&apos;ve partnered with DollarFor to provide a&nbsp;
          <Text
            size={16}
            c="white"
            fw={700}
            display="contents"
          >
            FREE
          </Text>
          &nbsp;service to apply for financial aid on your behalf. DollarFor will use an online form
          to determine eligibility and then work with you through email and text to secure Financial
          Aid.
        </Text>
        <Flex
          w="100%"
          justify="center"
          align="center"
          mx="auto"
          mt={25}
          direction="column"
        >
          <Anchor
            href="https://dollarfor.org/resolve"
            target="_blank"
            fz="sm"
            maw={343}
            w="100%"
          >
            <PrimaryButton
              loading={isLoading}
              type="button"
              onClick={() => setAnswer(faAnswers.yes)}
              w="100%"
              mt={{ base: 12, md: 0 }}
            >
              Take Me to DollarFor
            </PrimaryButton>
          </Anchor>
          <Button
            type="button"
            onClick={() => setAnswer(faAnswers.rejected)}
            mt={20}
            ml={10}
            mr={10}
            loading={isLoading}
            w={{ base: '100%', xs: 200, sm: '100%', md: '100%' }}
            sx={{
              root: {
                backgroundColor: 'transparent',
                border: 'none',
              },
            }}
            p={0}
          >
            <Group spacing={2}>
              <Flex
                align="center"
                justify="center"
                wrap="wrap"
              >
                <Text
                  mr={4}
                  fw={700}
                  c="neutral.6"
                  align="center"
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                >
                  I&apos;ve already been rejected for financial aid
                </Text>
                <ArrowUpward color={theme.colors.secondary[0]} />
              </Flex>
            </Group>
          </Button>
        </Flex>
      </Card>
      <Button
        type="button"
        onClick={() => setAnswer(faAnswers.no)}
        mt={20}
        ml={10}
        mr={10}
        loading={isLoading}
        sx={{
          root: {
            backgroundColor: 'transparent',
            border: 'none',
          },
        }}
        p={0}
      >
        <Group spacing={2}>
          <>
            <Text
              mr={4}
              fw={700}
              c="primary.0"
            >
              I&apos;d rather Resolve work on my behalf
            </Text>
            <ArrowUpward color={theme.colors.tertiary[1]} />
          </>
        </Group>
      </Button>
    </StepForm>
  );
};
