import { z } from 'zod';

const env = z.object({
  // FIXME: tests fail when this is defined because the env value is an empty string
  // PROD: z.boolean(),
  VITE_API: z.string().url(),
  VITE_CORP_URL: z.string().url(),
  VITE_DASHBOARD_URL: z.string().url(),
  VITE_SENTRY_AUTH_TOKEN: z.string().nonempty(),
  VITE_STYTCH_PUBLIC_TOKEN: z.string().nonempty(),
  VITE_GOOGLE_SSO_URL: z.string().url(),
});

// NOTE: HMR in Vite works better when function is a declaration and not an expression
export function validateEnv() {
  return env.parse(import.meta.env);
}
