import { FieldName } from '@/state/state.types';
import { SetFieldValue } from '@mantine/form/lib/types';
import { useEffect } from 'react';
import { useFields } from './useFields';

/**
 * Allows re-fill the field with the answered value from the caseState
 * Could be used when the app is reloaded and session is not expired
 */
export const useOpenStepResponse = <T extends object>(
  fields: {
    name: FieldName;
    transform?: (value: string) => string extends keyof T ? T[keyof T & string] : unknown;
    destinationName?: string;
  }[],
  setFieldValue: SetFieldValue<T>,
) => {
  const caseFields = useFields();

  useEffect(() => {
    fields.forEach((field) => {
      const answeredValue = caseFields?.[field.name]?.toString();

      if (!answeredValue) return;

      if (field.transform) {
        setFieldValue(field.destinationName ?? field.name, field.transform(answeredValue));
      } else {
        setFieldValue(
          field.destinationName ?? field.name,
          answeredValue as string extends keyof T ? T[keyof T & string] : unknown,
        );
      }
    });
  }, [caseFields, fields, setFieldValue]);
};
