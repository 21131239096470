import { StepSubtitle } from '@/components/StepSubtitle';
import { loaderForProductContent } from '@/content/loaderForProductContent';
import { Carousel } from '@mantine/carousel';
import { Flex, Text, Transition, useMantineTheme } from '@mantine/core';
import { useParams } from '@tanstack/router';
import Autoplay from 'embla-carousel-autoplay';
import { useEffect, useRef, useState } from 'react';
import { BlueScreen } from '../components/BlueScreen';
import { StepTitle } from '../components/StepTitle';
import { FireflyAnimated } from '../components/icons/FireflyAnimated';
import { staticStepRoute } from '../router/router';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

export const LoaderForProduct = () => {
  const theme = useMantineTheme();
  const { stepId } = useParams({ from: staticStepRoute.id });

  const { title, testimonials = [] } = loaderForProductContent;

  const { mutate } = useCaseUpdater({});

  const consultationLink = useConsultationLink();

  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const autoplay = useRef(Autoplay({ delay: 2250, jump: false, stopOnInteraction: false }));

  useEffect(() => {
    const formData = new FormData();
    formData.append('stepId', stepId);
    formData.append('answer', 'true');
    setTimeout(() => {
      autoplay.current.stop();
      mutate(formData);
    }, 4500);
  }, [mutate, stepId]);

  if (!stepId) throw new Error('StepId should be define on LoaderForProduct step');

  return (
    <BlueScreen
      stepId={stepId}
      withPrimaryButton={false}
      bookingAppointmentButtonProps={{
        appointmentUrl: consultationLink,
        shouldOpenCallingApp: false,
      }}
    >
      <FireflyAnimated
        color="#4E3A65"
        floodColor={theme.colors.secondary[0]}
        style={{
          marginTop: 98,
        }}
      />
      {!!title &&
        (typeof title === 'string' ? (
          <StepTitle
            isInverted
            ta="center"
            mt={40}
            w="100%"
          >
            {title}
          </StepTitle>
        ) : (
          title()
        ))}

      <div
        style={{
          width: 300,
          borderRadius: '12px',
          marginTop: 100,
          backgroundColor: `${theme.colors.minor[1]}`,
        }}
      >
        <Carousel
          withControls={false}
          slideGap={0}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          onSlideChange={setCurrentSlideNumber}
          align="start"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Slide
              key={index}
              sx={{
                cursor: 'default',
              }}
            >
              <Transition
                mounted={index === currentSlideNumber}
                transition="fade"
                duration={500}
                timingFunction="ease-in-out"
              >
                {(styles) => (
                  <Flex
                    direction="column"
                    align="center"
                    style={{ ...styles }}
                    p={24}
                  >
                    <StepSubtitle
                      size="l"
                      color="primary.0"
                      ta="center"
                      mt={0}
                    >
                      {testimonial.quote}
                    </StepSubtitle>
                    <Text
                      color="tertiary.1"
                      size={16}
                      weight={700}
                      mt={10}
                    >
                      {testimonial.name}, saved over {testimonial.saving}
                    </Text>
                  </Flex>
                )}
              </Transition>
            </Carousel.Slide>
          ))}
        </Carousel>
      </div>
    </BlueScreen>
  );
};
