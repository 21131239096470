import { Checkmark } from '@/components/icons/Checkmark';
import { painPointContent } from '@/content/painPointContent';
import { Group, Space, Stack, Text } from '@mantine/core';
import { useParams } from '@tanstack/router';
import { useState } from 'react';
import { BlueScreen } from '../components/BlueScreen';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepTitle } from '../components/StepTitle';
import { CircleFilled } from '../components/icons/CircleFilled';
import { staticStepRoute } from '../router/router';
import { PainPointSchema } from '../state/state.types';
import { useFields } from './hooks/useFields';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

export const PainPointAddress = () => {
  const { stepId } = useParams({ from: staticStepRoute.id });
  const fields = useFields();

  const painPoints = fields?.['pain-point'];
  const painPointsArray = PainPointSchema.parse(painPoints);

  const [painPointIndex, setPainPointIndex] = useState(0);

  const consultationLink = useConsultationLink();

  if (!stepId) throw new Error('Current stepId should be defined at pain point address step');

  const { header, title, subtitle } = painPointContent[painPointsArray[painPointIndex]];

  const shouldHideProgressElements =
    painPointIndex === painPointsArray.length - 1 || painPointsArray.length === 1;

  return (
    <BlueScreen
      stepId={stepId}
      withPrimaryButton={shouldHideProgressElements}
      primaryButtonLabel="Next"
      bookingAppointmentButtonProps={{
        appointmentUrl: consultationLink,
        shouldOpenCallingApp: false,
      }}
    >
      <Stack
        px={8}
        w="100%"
        spacing={0}
        align="center"
      >
        {!!header && (
          <Group
            position="apart"
            w="100%"
            fz={12}
            h={46}
            mt={{ base: 80, md: 140 }}
            px={16}
            py={12}
            sx={(theme) => ({
              borderRadius: 4,
              // 20% opacity
              backgroundColor: `${theme.colors.primary[2]}33`,
            })}
            c="secondary.0"
          >
            <Text>{header}</Text>
            <Checkmark />
          </Group>
        )}

        {!!title &&
          (typeof title === 'string' ? (
            <StepTitle
              isInverted
              ta="start"
              mt={24}
              fw={700}
              w="100%"
            >
              {title}
            </StepTitle>
          ) : (
            title()
          ))}

        {!!subtitle && (
          <StepTitle
            ta="start"
            w="100%"
            c="secondary.0"
          >
            {subtitle}
          </StepTitle>
        )}
        {painPointsArray.length > 1 && (
          <Group
            spacing={12}
            mt={40}
          >
            {painPointsArray.map((_, i) => (
              <CircleFilled
                key={i}
                color="white"
                style={{
                  opacity: i === painPointIndex ? 1 : 0.5,
                }}
              />
            ))}
          </Group>
        )}
        <Space h={{ base: 40, md: 140 }} />
        {!shouldHideProgressElements && (
          <PrimaryButton
            isOutlineHover={true}
            type="button"
            onClick={() => setPainPointIndex((i) => i + 1)}
            align="center"
            mt={0}
          >
            Next
          </PrimaryButton>
        )}
      </Stack>
    </BlueScreen>
  );
};
