import { BlueScreen } from '@/components/BlueScreen';
import { StepHeader } from '@/components/StepHeader';
import { bookAppointmentRoute, errorRoute } from '@/router/router';
import { CaseState } from '@/state/state.types';
import { clearCaseStateAndLocalStorage } from '@/utils/clearCaseStateAndLocalStorage';
import { validateEnv } from '@/utils/validateEnv';
import { Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/router';
import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

export const GeneralError = () => {
  const navigate = useNavigate();
  const { data: caseState } = useQuery<CaseState>(['caseState']);
  const stepId = caseState?.step?.id;

  const consultationLink = useConsultationLink();

  useEffect(() => {
    Sentry.withScope((scope) => {
      scope.setExtra('step', caseState?.step);
      scope.setExtra('history', caseState?.history);
      scope.setExtra('case', caseState?.case);
      Sentry.captureMessage(
        `User visits Error Page. Step Field: ${caseState?.step?.field ?? '-'} Case ID: ${
          caseState?.case.id ?? '-'
        }`,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlueScreen
      stepId={stepId ?? errorRoute.id}
      primaryButtonLabel="Start again"
      withRedirectToBookingAppointmentButton
      primaryButtonProps={{
        onClick: (e) => {
          e.preventDefault();
          clearCaseStateAndLocalStorage();
          window.location.assign(validateEnv().VITE_CORP_URL);
        },
        mt: 0,
      }}
      bookingAppointmentButtonProps={{
        appointmentUrl: consultationLink,
        shouldOpenCallingApp: false,
        onClick: (e) => {
          e.preventDefault();
          navigate({ to: bookAppointmentRoute.id });
        },
      }}
    >
      <StepHeader
        isInverted={true}
        ta={{ base: 'left', md: 'center' }}
        fw={900}
        w="100%"
        fz={48}
        lh="67px"
      >
        Oops we had an issue
      </StepHeader>
      <Text
        mt={16}
        mb={24}
        c="minor.1"
        fz="lg"
      >
        The page you were accessing is not available. Start again or schedule a call with our team.
      </Text>
    </BlueScreen>
  );
};
