import { Dispatch, useEffect } from 'react';
import { FieldName } from '../../state/state.types';
import { useFields } from './useFields';

/**
 * Allows re-fill the field with the answered value from the caseState
 * Could be used when the app is reloaded and session is not expired
 */
export const useMultiChoiceStepResponse = (
  fieldName: FieldName,
  setState: Dispatch<React.SetStateAction<string[]>>,
  stepId?: string,
) => {
  const fields = useFields();

  useEffect(() => {
    const answeredValue = fields?.[fieldName]?.toString().split(',');

    if (answeredValue) {
      setState(answeredValue);
    }
  }, [fields, fieldName, setState, stepId]);
};
