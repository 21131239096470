import { getStepContent } from '@/utils/getStepContent';
import { getStepName } from '@/utils/stepUtils';
import { Checkbox as MantineCheckbox, Flex, SimpleGrid, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/router';
import { useState } from 'react';
import { CheckAndDisplaySalesBanner } from '../components/CheckAndDisplaySalesBanner';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { StepSubtitle } from '../components/StepSubtitle';
import { StepTitle } from '../components/StepTitle';
import { choiceAndOpenStepContent } from '../content/choiceAndOpenStepContent';
import { choiceStepRoute } from '../router/router';
import { FieldName, IQuestionChoice, Step, StepName } from '../state/state.types';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useMultiChoiceStepResponse } from './hooks/useMultiChoiceStepResponse';
import { ContentContainer } from '@/components/ContentContainer';
import { Checkbox } from '@/components/ui/Checkbox';

export const MultiChoiceStep = () => {
  const isMdBreakpoint = useMdBreakpoint();
  const { stepId } = useParams({ from: choiceStepRoute.id });
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });

  const { onFormSubmit, isLoading } = useCaseUpdater({});

  const stepName = getStepName(stepId);

  const [answers, setAnswers] = useState<string[]>([]);

  const getFieldName = () => {
    if (stepId === StepName.concerns) return FieldName.concerns;
    return FieldName.pain_point;
  };

  useMultiChoiceStepResponse(getFieldName(), setAnswers, stepId);

  const handleOnChange = (newValues: string[]) => {
    if (newValues.length > 3) return;

    setAnswers(newValues);
  };

  const cols = () => {
    if (stepId === StepName.stress_level) return 5;
    if ([StepName.pain_point_select_001, StepName.concerns].includes(stepId))
      return isMdBreakpoint ? 2 : 1;
    return 1;
  };

  if (!stepId || !stepName) throw new Error('StepId and StepName should be defined');

  const { title, subtitle } = getStepContent(stepId, choiceAndOpenStepContent);

  const isCheckboxDisabled = (choiceId: IQuestionChoice['id']) =>
    answers.length >= 3 && !answers.find((el) => el === choiceId);

  return (
    // To avoid memoizing the component and keeping the state
    // when we navigate between the same type of steps
    // we use pathname as a key
    <Flex direction="column">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          margin: '0 0 15px 0',
        }}
      >
        {!!title && (typeof title === 'string' ? <StepTitle>{title}</StepTitle> : title())}
        {!!subtitle && <StepSubtitle>{subtitle}</StepSubtitle>}
      </div>
      <ContentContainer>
        <StepForm
          onSubmit={onFormSubmit}
          style={{
            padding: isMdBreakpoint ? 0 : '0 0 50px 0',
          }}
        >
          <input
            name="stepId"
            type="hidden"
            value={stepId}
          />
          <MantineCheckbox.Group
            value={answers}
            onChange={handleOnChange}
          >
            <Stack spacing={12}>
              <SimpleGrid
                mt={isMdBreakpoint ? 24 : 5}
                cols={cols()}
                spacing="sm"
                verticalSpacing="sm"
              >
                {step?.choices?.map((choice) => (
                  <Checkbox
                    key={choice.id}
                    uniqueKey={choice.id}
                    isDisabed={isCheckboxDisabled(choice.id)}
                    value={choice.id}
                    label={choice.display}
                    isSelected={!!answers.find((el) => el === choice.id)}
                  />
                ))}
              </SimpleGrid>
            </Stack>
          </MantineCheckbox.Group>
          <Flex
            pos={isMdBreakpoint ? 'initial' : 'fixed'}
            bottom={0}
            left={0}
            px={isMdBreakpoint ? 0 : '2rem'}
            pb={isMdBreakpoint ? 0 : 30}
            style={{
              zIndex: 2,
              background: 'white',
              boxShadow: isMdBreakpoint ? 'none' : `0px -20px 20px white`,
              width: isMdBreakpoint ? 'auto' : '100%',
            }}
          >
            <PrimaryButton
              isLoading={isLoading}
              isDisabled={answers.length === 0 || answers.length > 3}
              align={isMdBreakpoint ? 'end' : 'center'}
              mx="auto"
              maw={343}
              w="100%"
            >
              Continue
            </PrimaryButton>
          </Flex>
        </StepForm>
        {CheckAndDisplaySalesBanner(stepId)}
      </ContentContainer>
    </Flex>
  );
};
