import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import {
  Anchor,
  Box,
  BoxProps,
  Button,
  Flex,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useParams } from '@tanstack/router';
import { z } from 'zod';
import { SecondaryButton } from '../components/SecondaryButton';
import { StepForm } from '../components/StepForm';
import { Checkmark } from '../components/icons/Checkmark';
import { ExportSquare } from '../components/icons/ExportSquare';
import { Warning } from '../components/icons/Warning';
import { staticStepRoute } from '../router/router';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useFields } from './hooks/useFields';
import { ArrowLeft } from '@/components/icons/ArrowLeft';
import { clearCaseStateAndLocalStorage } from '@/utils/clearCaseStateAndLocalStorage';
import { usePartnership } from './hooks/usePartnership';
import { isFinFitPartnership } from '@/utils/partnership';
import { freeFinancialAidLink } from '@/utils/constants';

const schema = z.object({
  email: z.string().email({ message: 'Email is not valid' }),
});

const CanNotHelpLegalEncumbrance = () => {
  const { stepId } = useParams({ from: staticStepRoute.id });
  const { onFormSubmit, isLoading } = useCaseUpdater({ shouldNavigate: false });
  const isMdBreakpoint = useMdBreakpoint();
  const partnership = usePartnership();

  const fields = useFields();

  const form = useForm({
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    initialValues: {
      email: fields?.email ?? '',
    },
  });

  const theme = useMantineTheme();

  const postedEmail = fields?.email;

  const isFinFit = isFinFitPartnership(`${partnership}`);

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        mb={20}
      >
        <Group position="apart">
          <Button
            type="button"
            styles={() => ({
              root: {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'primary.0',
              },
            })}
            pr={0}
            pl={2}
            py={0}
            onClick={async () => {
              await clearCaseStateAndLocalStorage();
              window.location.reload();
            }}
          >
            <ArrowLeft color={theme.colors.primary[0]} />
            <Text
              ml={12}
              c="primary.0"
              fw={700}
            >
              Back
            </Text>
          </Button>
        </Group>
      </Flex>
      <Flex direction="column">
        <Box
          p={16}
          bg={theme.colors.primary[1]}
          sx={{ borderRadius: 8, color: theme.colors.neutral[0] }}
        >
          <Flex
            direction={{ base: 'column', md: 'row' }}
            align="start"
            gap={16}
          >
            <Warning
              height={48}
              style={{ flexShrink: 0 }}
              color={theme.colors.secondary[0]}
            />
            <Stack spacing={8}>
              <Title
                color="neutral.5"
                order={3}
              >
                We&apos;re sorry, but we can&apos;t help you at this time.
              </Title>
              <Text color="neutral.5">
                We&apos;re currently unable to help you in your specific situation. However, we are
                constantly growing and improving, and that may change in the future.
              </Text>
            </Stack>
          </Flex>
        </Box>
        <Title
          order={4}
          mt={24}
          c="primary.0"
        >
          Resources for Medical Bills 101
        </Title>
        <Text
          color="primary.0"
          mt={{ base: 24, md: 0 }}
        >
          For help tackling your medical bills without an Advocate, explore our guides and
          resources.
        </Text>
        <LinkBlock
          label="Guide to Negotiating Your Medical Bills"
          link="https://www.resolvemedicalbills.com/the-ultimate-guide-to-lowering-your-medical-bills-part-1/"
        />

        <LinkBlock
          label="Free Financial Aid"
          link={freeFinancialAidLink(isFinFit)}
        />

        <LinkBlock
          label="Borrow from your 401k"
          link="https://meetbeagle.com/landing-fc-newloan?utm_source=relief&utm_campaign=resolve"
        />
        <LinkBlock
          label="How to crowdfund for medical bills"
          link="https://cofundhealth.com/campaign-success-center"
        />
        <Flex
          mt={{ base: 16, md: 24 }}
          align="start"
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
        >
          <Text
            color="primary.0"
            mt={{ base: 24, md: 0 }}
            w={{ base: '100%', md: 420 }}
            sx={{ flexShrink: 0 }}
          >
            Leave your email address to receive the resources directly in your mailbox.
          </Text>
          <StepForm
            style={{ marginTop: 0, width: '100%' }}
            onSubmit={onFormSubmit}
          >
            <input
              name="stepId"
              type="hidden"
              value={stepId}
            />

            <Flex
              gap={8}
              direction={{ base: 'column', md: 'row' }}
              mt={{ base: 16, md: 0 }}
              align="start"
              p={0}
            >
              <TextInput
                placeholder="yourname@email.com"
                styles={(theme) => ({
                  root: {
                    flexGrow: 1,
                    width: isMdBreakpoint ? '400px' : '100%',
                  },
                  input: {
                    height: 44,
                    flexGrow: 1,
                    border: `solid 1px ${theme.colors.neutral[3]}`,
                    borderRadius: 4,
                    backgroundColor: theme.colors.minor[1],
                  },
                  error: {
                    fontSize: theme.fontSizes.sm,
                    color: theme.colors.error[0],
                  },
                })}
                name="email"
                {...form.getInputProps('email')}
                rightSection={
                  !!postedEmail && (
                    <Checkmark
                      color={theme.colors.tertiary[0]}
                      style={{
                        flex: '0 0 24px',
                        marginRight: 20,
                        marginBottom: 1,
                      }}
                    />
                  )
                }
              />
              {!postedEmail && (
                <SecondaryButton
                  loading={isLoading}
                  mt={{ base: 12, md: 0 }}
                  disabled={!form.isValid('email')}
                  align="start"
                >
                  Submit
                </SecondaryButton>
              )}
            </Flex>
            {postedEmail && (
              <Text
                color="tertiary.0"
                mt={8}
              >
                Thank you! You&apos;ll receive the resources in 1-2 business days.
              </Text>
            )}
          </StepForm>
        </Flex>
      </Flex>
    </>
  );
};

interface LinkBlockProps extends BoxProps {
  label: string;
  link: string;
}

const LinkBlock = ({ label, link, ...rest }: LinkBlockProps) => {
  return (
    <Box
      bg="primary.4"
      sx={{ borderRadius: 4 }}
      {...rest}
    >
      <Anchor
        target="_blank"
        color="tertiary.0"
        href={link}
        py={24}
        fz={20}
        fw={700}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors.primary[2]}`,
        })}
      >
        {label}
        <ExportSquare
          color="tertiary.0"
          width={24}
          height={24}
        />
      </Anchor>
    </Box>
  );
};

export { CanNotHelpLegalEncumbrance };
