import { DefaultMantineColor, MantineThemeOverride, Tuple } from '@mantine/core';

type ExtendedCustomColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'jade'
  | 'minor'
  | 'neutral'
  | 'error'
  | 'info'
  | 'success'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}

const fontFamily =
  'Libre Franklin, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji';

export const resolveTheme: MantineThemeOverride = {
  fontFamily,
  lineHeight: 1.4,
  colors: {
    primary: ['#2E193E', '#9788C0', '#E1D4EB', '#332042'],
    secondary: ['#F8FA22', '#FEFF8F'],
    tertiary: ['#025C5C', '#2AAAAA', '#F2FFFE', '#3C9571'],
    minor: ['#FFF1E3', '#FEFEFA'],
    neutral: ['#1A1A1A', '#656565', '#A1A1A1', '#C7C7C7', '#E4E4E4', '#F1F1F1', '#FFFFFF'],
    error: ['#FF0000', '#FF6666', '#FF9999', '#FFCCCC', '#FFF2F2', '#C83939'],
    info: ['#FFA500', '#FFC966', '#FFDB99', '#FFEDCC', '#FFFAF2'],
    success: ['#3C9571', '#99BEAC', '#BBD4C7', '#DDE9E3', '#F6FAF8'],
  },
  primaryColor: 'primary',
  headings: {
    fontFamily,
    fontWeight: 700,
    //REVIEW: it's changed for Desktop in Design System
    // We have it here from old UI Kit https://www.figma.com/file/nHI8boDTOCNDYNSEA1gNbT/Resolve-UI-Kit-2022?type=design&node-id=7-104&t=DT4b87maYIApFJFS-0
    sizes: {
      h1: {
        fontSize: '3rem',
        lineHeight: 1.4,
      },
      h2: {
        fontSize: '2.5rem',
        lineHeight: 1.4,
      },
      h3: {
        fontSize: '2.25rem',
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '2rem',
        lineHeight: 1.4,
      },
      h5: {
        fontSize: '1.5rem',
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '1.25rem',
        lineHeight: 1.4,
      },
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  breakpoints: {
    xs: '20.5rem',
    sm: '27.5rem',
    md: '52.5rem',
    xl: '75.5rem',
  },
  components: {
    Text: {
      defaultProps: {
        size: 'md',
      },
    },
    Button: {
      sizes: {
        md: () => ({
          root: {
            padding: `0.625rem 1.5rem`,
            lineHeight: 1.4,
            height: 'auto',
          },
        }),
        sm: () => ({
          root: {
            padding: `0.375rem 1.25rem`,
            lineHeight: 1.4,
            height: 'auto',
          },
        }),
      },
    },
    Title: {
      defaultProps: {},

      // NOTE: using new Design System font sizes for mobile and desktop
      // https://www.figma.com/file/mq404nLTUnMqLG6g7edlgK/Resolve-Design-system-2023?type=design&node-id=883%3A5305&t=ZGE8XJHEiPrYRGq7-1
      sizes: {
        h1: (theme) => ({
          root: {
            fontSize: '3rem',
            fontWeight: 900,

            [theme.fn.largerThan('md')]: {
              fontSize: '4rem',
            },
          },
        }),
        h2: (theme) => ({
          root: {
            fontSize: '2.125rem',
            fontWeight: 700,

            [theme.fn.largerThan('md')]: {
              fontSize: '3.375rem',
            },
          },
        }),
        h3: (theme) => ({
          root: {
            fontSize: '1.75rem',
            fontWeight: 700,

            [theme.fn.largerThan('md')]: {
              fontSize: '2.25rem',
            },
          },
        }),
        h4: (theme) => ({
          root: {
            fontSize: '1.375rem',
            fontWeight: 700,

            [theme.fn.largerThan('md')]: {
              fontSize: '1.75rem',
            },
          },
        }),
        h5: (theme) => ({
          root: {
            fontSize: '1.25rem',
            fontWeight: 700,

            [theme.fn.largerThan('md')]: {
              fontSize: '1.375rem',
            },
          },
        }),
        h6: () => ({
          root: {
            fontSize: '1rem',
            fontWeight: 400,
          },
        }),
      },
    },
  },
  other: {
    rootContainerPaddingX: {
      sm: '1rem',
      md: '2.5rem',
    },
    rootContainerPaddingTop: {
      sm: '12px',
      md: '24px',
      xl: '64px',
    },
    rootContainerPaddingBottom: {
      sm: '12px',
      md: '24px',
    },
    rootHeaderHeight: {
      sm: '4.75rem',
      md: '5rem',
    },
    rootHeaderWithProgressHeight: {
      sm: '4.75rem',
      md: '6rem',
    },
    partnershipTop: '5.5rem',
  },
};
