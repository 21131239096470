import { fetchCaseState } from '@/fetchers/backend-fetchers';
import { useQuery } from '@tanstack/react-query';

export const useFields = () => {
  const { data: fields } = useQuery({
    queryKey: ['caseState'],
    queryFn: fetchCaseState,
    select: (state) => state.case.fields,
  });
  return fields;
};
