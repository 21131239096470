import '@total-typescript/ts-reset';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import Sentry from './metrics/sentry';

Sentry.init();

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;600;700;900"
    />
    <App />
  </>,
);
