import { clearCaseStateAndLocalStorage } from '@/utils/clearCaseStateAndLocalStorage';
import { DASHBOARD_ROUTES, getDashboardURL } from '@/utils/dashboardUrl';
import { validateEnv } from '@/utils/validateEnv';
import { StytchUIClient } from '@stytch/vanilla-js';

export const stytch = new StytchUIClient(validateEnv().VITE_STYTCH_PUBLIC_TOKEN);

stytch.session.onChange(async (session) => {
  if (!session) {
    await clearCaseStateAndLocalStorage();

    window.location.assign(`${getDashboardURL()}/${DASHBOARD_ROUTES.signIn}`);
  }
});
