import { Product, ProductContentDependency } from '../state/state.types';
import { BulletItem, StepContent, StepRecordContentByDependency } from './stepContent.types';
import { TestimonialSlideContent } from './testimonialContent';

const defaultBulletList: BulletItem[] = [
  {
    id: '1',
    text: 'Tell us your story',
    iconName: 'CheckmarkFilled',
    strike: true,
    dim: true,
  },
];

const defaultStepContent: StepContent = {
  header: 'Let our pros take over',
  title: "here's our plan to help you.",
  bulletList: defaultBulletList,
};

const pointInTime: Record<ProductContentDependency, StepContent> = {
  'insurance-no-collection-no': {
    ...defaultStepContent,
    bulletList: [
      ...defaultBulletList,
      {
        id: '2',
        text: 'No bills in collections',
        iconName: 'CheckmarkFilled',
        strike: true,
        dim: true,
      },
      {
        id: '3',
        text: 'No insurance coverage',
        iconName: 'CheckmarkFilled',
        strike: true,
        dim: true,
      },
      {
        id: '4',
        text: 'Bills and documents analysis',
        iconName: 'ArrowRightCircledFilled',
        highlight: true,
        footnote: 'Our advocate will identify potential errors and gather missing information.',
      },
      {
        id: '5',
        text: 'Bill negotiation',
        iconName: 'ArrowRightCircledFilled',
        footnote:
          'We run a fair price analysis on your bill(s) and leverage advanced negotiation techniques to save you money.',
      },
    ],
  },
  'insurance-yes-collection-no': {
    ...defaultStepContent,
    bulletList: [
      ...defaultBulletList,
      {
        id: '2',
        text: 'No bills in collections',
        iconName: 'CheckmarkFilled',
        strike: true,
        dim: true,
      },
      {
        id: '3',
        text: 'Bills and documents analysis',
        iconName: 'ArrowRightCircledFilled',
        highlight: true,
        footnote: 'Our advocate will identify potential errors and gather missing information.',
      },
      {
        id: '4',
        text: 'Insurance coverage',
        iconName: 'ArrowRightCircledFilled',
        footnote:
          'We work with your insurance to investigate if they should cover more of the bill.',
      },
      {
        id: '5',
        text: 'Bill negotiation',
        iconName: 'ArrowRightCircledFilled',
        footnote:
          'We run a fair price analysis on your bill(s) and leverage advanced negotiation techniques to save you money.',
      },
    ],
  },
  'insurance-yes-collection-yes-or-credit-collections-pain-point-yes': {
    ...defaultStepContent,
    bulletList: [
      ...defaultBulletList,
      {
        id: '2',
        text: 'Bills and documents analysis',
        iconName: 'ArrowRightCircledFilled',
        highlight: true,
        footnote: 'Our advocate will identify potential errors and gather missing information.',
      },
      {
        id: '3',
        text: 'Insurance coverage',
        iconName: 'ArrowRightCircledFilled',
        footnote:
          'We work with your insurance to investigate if they should cover more of the bill.',
      },
      {
        id: '4',
        text: 'Bill negotiation',
        iconName: 'ArrowRightCircledFilled',
        footnote:
          'We run a fair price analysis on your bill(s) and leverage advanced negotiation techniques to save you money.\n' +
          'Our objective is to help protect your credit.',
      },
    ],
  },
  'insurance-no-collection-yes-or-credit-collections-pain-point-yes': {
    ...defaultStepContent,
    bulletList: [
      ...defaultBulletList,
      {
        id: '2',
        text: 'No insurance coverage',
        iconName: 'CheckmarkFilled',
        strike: true,
        dim: true,
      },
      {
        id: '3',
        text: 'Bills and documents analysis',
        iconName: 'ArrowRightCircledFilled',
        highlight: true,
        footnote: 'Our advocate will identify potential errors and gather missing information.',
      },
      {
        id: '4',
        text: 'Bill negotiation',
        iconName: 'ArrowRightCircledFilled',
        footnote:
          'We run a fair price analysis on your bill(s) and leverage advanced negotiation techniques to save you money.\n' +
          'Our objective is to help protect your credit.',
      },
    ],
  },
};

const defaultSubscriptionContent: StepContent = {
  ...defaultStepContent,
  bulletList: [
    ...defaultBulletList,
    {
      id: '2',
      text: 'No bills in collections',
      iconName: 'CheckmarkFilled',
      strike: true,
      dim: true,
    },
    {
      id: '3',
      text: 'Bills and documents analysis',
      iconName: 'ArrowRightCircledFilled',
      highlight: true,
      footnote: 'Our advocate will identify potential errors and gather missing information.',
    },
    {
      id: '4',
      text: 'Future care and bills',
      iconName: 'ArrowRightCircledFilled',
      footnote:
        'We coordinate with you to incorporate both your past and future bills into our strategy and maximize the outcome for you.',
    },
    {
      id: '5',
      text: 'Insurance coverage',
      iconName: 'ArrowRightCircledFilled',
      footnote:
        'We work with your insurance to investigate if they should cover the bill entirely.',
    },
    {
      id: '6',
      text: 'Bill negotiation',
      iconName: 'ArrowRightCircledFilled',
      footnote:
        'We run a fair price analysis on your bill(s) and leverage advanced negotiation techniques to save you money.',
    },
  ],
};

const subscription: Record<ProductContentDependency, StepContent> = {
  'insurance-no-collection-no': defaultSubscriptionContent,
  'insurance-yes-collection-no': defaultSubscriptionContent,
  'insurance-yes-collection-yes-or-credit-collections-pain-point-yes': defaultSubscriptionContent,
  'insurance-no-collection-yes-or-credit-collections-pain-point-yes': defaultSubscriptionContent,
};

export const productContentDiscontinued2306: StepRecordContentByDependency<
  Exclude<Product, 'ezfile'>,
  Record<ProductContentDependency, StepContent>
> = {
  'bill-reduction-25': pointInTime,
  'bill-reduction-10': pointInTime,
  subscription: subscription,
};

export const productContent: StepContent = {
  header: 'We can help!',
  description: 'Let our experts take over to analyze and lower your bills. You’ll get:',
  aboveButtonText: `We've saved over <span style="color:#2AAAAA;font-weight:700;font-size:19px;">$40M</span> for patients like you.`,
  bulletList: [
    {
      id: '1',
      text: 'We deal with hospitals and/or collections',
      iconName: 'Checkmark',
    },
    {
      id: '2',
      text: 'We support you every step of the way',
      iconName: 'Checkmark',
    },
    {
      id: '3',
      text: 'We help you stay ahead of your bills',
      iconName: 'Checkmark',
    },
  ],
};

export const testimonialSlides: TestimonialSlideContent[] = [
  {
    quote:
      "The peace of mind we had... was life changing. We are still in shock... and couldn't be more grateful to Tania and Resolve",
    name: 'Sonja',
    saving: 'saved $500,000',
  },
  {
    quote: 'This company was amazing to work with... Resolve gets all the stars',
    name: 'Kelsey',
    saving: 'saved over $10,000',
  },
  {
    quote: 'They saved us from bankruptcy. Thank you to everyone on the team at Resolve!',
    name: 'Louisa',
    saving: 'saved over $35,000',
  },
];

export const consultFocusContent: StepContent = {
  header: 'Given the size of your bills, we recommend a consultation with a Patient Advisor.',
  subtitle: 'During this time you will:',
  bulletList: [
    {
      id: '1',
      text: 'Discuss your situation with a live person.',
    },
    {
      id: '2',
      text: 'Learn more about how we can help.',
    },
    {
      id: '3',
      text: 'Get started more confidently.',
    },
  ],
};
