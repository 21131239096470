import { SEQUENCE_ID } from '@/fetchers/backend-fetchers';
import { patchCase } from '@/state/patchCase';
import { CaseState } from '@/state/state.types';
import { navigateToNextStepOrUrl } from '@/utils/navigateToNextStepOrUrl';
import { useQuery } from '@tanstack/react-query';
import { Router, useRouter } from '@tanstack/router';
import { useEffect, useState } from 'react';

const patchSequenceId = async (
  caseState: CaseState | undefined,
): Promise<CaseState | undefined> => {
  if (!caseState || !caseState.case || !caseState.case.sequenceId) return caseState;

  if (caseState.case.sequenceId !== SEQUENCE_ID) {
    const formData = new FormData();
    formData.append('sequenceId', SEQUENCE_ID);

    return await patchCase(formData);
  }

  return caseState;
};

const patchNextStep = (caseState: CaseState | undefined, router: Router) => {
  const step = caseState?.step;
  const route = router.state.currentLocation;

  if (step) {
    // we want to avoid redirecting from the first step submit route
    // and if we are already on the step we want - next un-answered step
    const shouldNotRedirect = ['/submit/', `/${step.type}/${step.id}`, '/info'].some((href) =>
      route.href.includes(href),
    );

    if (!shouldNotRedirect) {
      // If we reach here, it means the user is attempting to access a question onMount which is different from where they should be.
      // We redirect the user to the appropriate step according to their case.
      // This is always the last un-answered question.

      navigateToNextStepOrUrl(caseState);
    }
  }
};

const safeGuardRoute = async (
  caseState: CaseState | undefined,
  router: Router,
  callback: () => void,
) => {
  const patchedCaseState = await patchSequenceId(caseState);
  patchNextStep(patchedCaseState, router);
  callback();
};

/**
 * Circumstances lead to a few situations when the app is not going to be able to render.
 * This can arise from a few different cases.
 * Examples:
 *   - CaseState has an old sequence ID
 *   - Route is un renderable due to missing information in CaseState
 *
 * In situations like these, we aim to fix the issue JIT.
 * This can mean patching the case state or redirecting the user, for instance.
 */
export const useRouteSafeGuard = (init: boolean) => {
  const [routeIsSafe, setRouteIsSafe] = useState(init);
  const { data: caseState } = useQuery<CaseState>(['caseState']);
  const router = useRouter();

  useEffect(() => {
    safeGuardRoute(caseState, router, () => setRouteIsSafe(true));
    // we only want to run this once onMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [routeIsSafe, setRouteIsSafe];
};
