import { FlexProps } from '@mantine/core';
import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { z } from 'zod';
import { Branch, FieldName, Fields, StepName } from '../state/state.types';
import { TestimonialSlideContent } from './testimonialContent';

interface Field {
  name: FieldName;
  label?: string;
  helperText?: string;
  inputTypeAttribute?: HTMLInputTypeAttribute;
  placeholder?: string;
  errorMessage?: string;
  inputElement?: 'input' | 'textarea';
  prefix?: string;
}

export type BulletItemIconName =
  | 'none'
  | 'ArrowRight'
  | 'ArrowRightCircledFilled'
  | 'Checkmark'
  | 'CheckmarkCircleFilled'
  | 'CheckmarkFilled'
  | 'CircleFilled'
  | 'Confetti'
  | number;

export interface BulletItem {
  id: string;
  text: string;
  textFn?: (fields: Fields) => string;
  color?: string;
  iconName?: BulletItemIconName;
  highlight?: boolean;
  strike?: boolean;
  dim?: boolean;
  footnote?: string;
  boldWordNumber?: number;
  subBulletList?: BulletItem[];
  showTimeline?: boolean;
}

export interface StepContent {
  header?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: string | ((data?: any) => ReactNode);
  subtitle?: string;
  description?: string;
  field?: Field;
  choiceOrientation?: FlexProps['direction'];
  bulletList?: BulletItem[];
  bulletListTitle?: string;
  footnoteList?: BulletItem[];
  buttonLabel?: string;
  aboveButtonText?: string;
  testimonials?: Array<TestimonialSlideContent>;
}

export type StepRecordContent<T> = Partial<
  Record<`${StepName}`, Partial<Record<Branch, T>> | undefined>
>;

export type StepRecordContentByDependency<T extends string, V = StepContent> = Record<T, V>;

export const Amount = z.number();
export type Amount = z.infer<typeof Amount>;

export type StepRecordContentByAmountByDependency<T extends string, V = StepContent> = Record<
  T,
  Record<Amount, V>
>;
