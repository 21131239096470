import { mapStepIdToRoute } from '@/utils/mapStepIdToRoute';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/router';
import { useCallback } from 'react';
import { choiceStepRoute } from '../../router/router';
import { queryClient } from '../../state/queryClient';
import { CaseState, Sequence, Step } from '../../state/state.types';
import { updateCase } from '../../state/updateCase';
import { navigateToErrorRoute } from '../../utils/navigateToErrorRoute';

export const useFirstStepSubmitter = () => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (formData: FormData) => {
      const step = await updateCase(formData);
      return step;
    },
    onSuccess: async (caseState: CaseState) => {
      if (!caseState) {
        throw new Error('Case is not found');
      }

      const firstStepId = (queryClient.getQueryData<Sequence>(['sequence'])?.steps[0] as Step).id;

      // Navigate to first step to keep it in history
      await navigate({
        to: choiceStepRoute.id,
        params: { stepId: firstStepId },
        replace: true,
      });

      const nextPath = mapStepIdToRoute(caseState);

      navigate({
        to: nextPath,
        params: { stepId: caseState.step?.id ?? '' },
        replace: false,
      });
    },
    onError: (error: Error) => navigateToErrorRoute(error, 'useFirstStepSubmitter'),
  });

  const submit = useCallback(
    (formData: FormData) => {
      mutate(formData);
    },
    [mutate],
  );

  return { isLoading, submit };
};
