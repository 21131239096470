import { Flex, Image } from '@mantine/core';

import { isDarkBackground } from '@/utils/showLayoutElements';
import { isFinFitPartnership, partnerLogoMap, partnerSvgLogoMap } from '@/utils/partnership';
import { useFields } from '@/routes/hooks/useFields';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { isLoaderProductStep } from '@/utils/stepUtils';

type PartnershipProps = {
  stepId: string;
};

export const Partnership = ({ stepId }: PartnershipProps) => {
  const fields = useFields();
  const partnership = fields?.partnership;
  const isDark = isDarkBackground(stepId) || isLoaderProductStep(stepId);
  const isSvg = isFinFitPartnership(`${partnership}`);
  const isMdBreakpoint = useMdBreakpoint();

  const logoSrc = partnerLogoMap.get(`${partnership}`);
  const SvgComponent = partnerSvgLogoMap.get(`${partnership}`);

  return (
    <Flex
      align="center"
      style={{
        zIndex: 1,
        marginLeft: 10,
        borderLeft: '1px solid #BCBCBC',
      }}
    >
      {isSvg && SvgComponent ? (
        <Flex ml={10}>
          <SvgComponent
            width={!isMdBreakpoint ? 60 : 78}
            height={!isMdBreakpoint ? 20 : 30}
            color={isDark ? 'white' : '#1d47f3'}
          />
        </Flex>
      ) : (
        <Image
          src={logoSrc}
          style={{
            width: !isMdBreakpoint ? 60 : 78,
            marginLeft: 10,
          }}
        />
      )}
    </Flex>
  );
};
