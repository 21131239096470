import { Box, BoxProps, Center } from '@mantine/core';

export const ErrorBanner = ({ errorText, ...rest }: BoxProps & { errorText: string }) => (
  <Center>
    <Box
      mt={12}
      p={16}
      c="error.0"
      fz="sm"
      w={{ base: 350, md: '100%' }}
      sx={(theme) => ({
        borderRadius: '4px',
        border: `2px solid ${theme.colors.error[0]}`,

        // 10% opacity
        backgroundColor: `${theme.colors.error[0]}1A`,
      })}
      {...rest}
    >
      {errorText}
    </Box>
  </Center>
);
