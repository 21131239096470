import stretchDollar from '../assets/img/partners/stretch_dollar.jpg';
import finfit from '../assets/img/partners/finfit.jpg';
import FinFit from '../assets/img/partners/finfit.tsx';
import { ComponentPropsWithoutRef } from 'react';

export const partnersMap = new Map<string, string>([
  ['stretchdollar', 'stretchdollar'],
  ['finfit', 'finfit'],
]);

export const partnerLogoMap = new Map<string, string>([
  ['stretchdollar', stretchDollar],
  ['finfit', finfit],
]);

export const partnerSvgLogoMap = new Map<
  string,
  (props: ComponentPropsWithoutRef<'svg'>) => JSX.Element
>([['finfit', FinFit]]);

export const hasValidPartnership = (partnership: string) => partnersMap.has(partnership);

export const isFinFitPartnership = (partnership: string) => partnership === 'finfit';
