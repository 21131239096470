import * as Sentry from '@sentry/browser';

const init = () => {
  Sentry.init({
    beforeSend: (event) => {
      // Don't send events if not in a release
      if (document.location.hostname.includes('localhost')) return null;
      return event;
    },
    dsn: 'https://9302c18a85b2492db70b0016678a1939@o4505047975002112.ingest.sentry.io/4505047980572672',
    release: import.meta.env.VITE_VERSION, // the release needs to match the version in the package.json
    environment: document.location.hostname.includes('localhost') ? 'local' : import.meta.env.MODE,
    integrations: [new Sentry.BrowserTracing({ tracePropagationTargets: [] }), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? '.1'),
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default {
  init,
};
