import { Anchor, Flex, FlexProps, Stack, Text, useMantineTheme } from '@mantine/core';

import { useFields } from '@/routes/hooks/useFields';
import { BillSize } from '@/state/state.types';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { useSmBreakpoint } from '@/theme/hooks/useSmBreakpoint';
import { bookAppointmentRoute } from '../router/router';
import { useCasePatcher } from '../routes/hooks/useCasePatcher';
import { PrimaryButton } from './PrimaryButton';
import { StepTitle } from './StepTitle';
import { Chat } from './icons/Chat';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

interface HelpBannerProps {
  stepId: string;
  title?: string | null;
  description?: string | null;
}

export const HelpBanner = ({
  stepId,
  title,
  description,
  ...flexProps
}: HelpBannerProps & FlexProps) => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();
  const isSmBreakpoint = useSmBreakpoint();
  const consultationLink = useConsultationLink(true);
  const { onFormSubmit, isLoading, isSuccess } = useCasePatcher({
    search: {
      appointmentUrl: consultationLink,
    },
    to: bookAppointmentRoute.id,
  });

  const fields = useFields();

  const BillSizeUnder5k = (
    <Anchor href="mailto:advocate@resolvemedicalbills.com">
      <PrimaryButton
        isOutlineHover
        mt={8}
        loading={isLoading || isSuccess}
        size="sm"
      >
        Send us an email
      </PrimaryButton>
    </Anchor>
  );

  const BillSizeOver5k = (
    <form
      onSubmit={onFormSubmit}
      id="help-banner-form"
      name="help-banner-form"
      style={{ width: isMdBreakpoint ? 'auto' : '100%' }}
    >
      <input
        name="talk-to-an-advisor-click"
        type="hidden"
        value={stepId}
      />

      <Stack
        spacing={16}
        w="100%"
        align="center"
      >
        <PrimaryButton
          isOutlineHover
          mt={8}
          loading={isLoading || isSuccess}
          size="sm"
        >
          Schedule a free consultation
        </PrimaryButton>

        <Anchor
          type="button"
          href="mailto:advocate@resolvemedicalbills.com"
          c="minor.1"
          sx={(theme) => ({
            borderRadius: '50px',
            border: `1px solid ${theme.colors.minor[1]}`,
          })}
          pl={16}
          w="100%"
          ta="center"
          py={6}
          px={20}
        >
          <Text
            fw={700}
            fz="sm"
          >
            Send us an email
          </Text>
        </Anchor>
      </Stack>
    </form>
  );

  const isBillSizeValid = BillSize.safeParse(fields?.['bill-size']);
  const HelpContent =
    isBillSizeValid.success && isBillSizeValid.data === BillSize.Enum['less-than-5k']
      ? BillSizeUnder5k
      : BillSizeOver5k;

  return (
    <Flex
      mt={{ base: 44, md: 64 }}
      justify="space-between"
      align="start"
      direction={{ base: 'column', md: 'row' }}
      w={isMdBreakpoint ? '100%' : isSmBreakpoint ? 400 : '100%'}
      bg="primary.1"
      gap={{ base: 8, md: 16 }}
      p={24}
      sx={{
        borderRadius: isMdBreakpoint ? '4px' : '10px',
      }}
      mx="auto"
      {...flexProps}
    >
      <Chat
        color={theme.colors.secondary[0]}
        style={{ flexShrink: 0 }}
      />
      <Stack
        spacing={8}
        ta={{ base: 'center', md: 'left' }}
      >
        {title !== null && (
          <StepTitle
            order={4}
            weight="600"
            c="tertiary.2"
            fz={28}
            ta="left"
          >
            {title ? title : `Still have questions?`}
          </StepTitle>
        )}
        {description !== null && (
          <Text
            c="tertiary.2"
            ta="left"
          >
            {description
              ? description
              : `If you have questions and need assistance with anything, we're happy to help. You can also visit our `}
            <Anchor
              href="#faq"
              fz="sm"
              sx={{
                textDecoration: 'underline',
              }}
            >
              Frequently Asked Questions.
            </Anchor>
          </Text>
        )}
      </Stack>

      {HelpContent}
    </Flex>
  );
};
