import { Flex, Stack, Transition, useMantineTheme, Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCaseUpdater } from '../routes/hooks/useCaseUpdater';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';

export const GreenScreen = ({
  primaryButtonLabel = `Continue`,
  primaryButtonProps = {},
  children,
  stepId,
  withPrimaryButton = true,
  buttonVariant = 'primary',
}: {
  withPrimaryButton?: boolean;
  primaryButtonLabel?: string;
  primaryButtonProps?: PrimaryButtonProps;
  children: React.ReactNode;
  stepId: string;
  buttonVariant?: 'primary' | 'third';
}) => {
  const theme = useMantineTheme();
  const [mounted, setMounted] = useState(false);
  const isMdBreakpoint = useMdBreakpoint();

  // TODO: find a better way to enable animation after the navigation to the page
  useEffect(() => {
    setTimeout(() => setMounted(true), 0);
  }, []);

  const { onFormSubmit, isLoading } = useCaseUpdater({});

  return (
    <Transition
      mounted={mounted}
      transition="fade"
      duration={0}
    >
      {(styles) => (
        <>
          <Flex
            direction="column"
            align="center"
            justify="center"
            bg="tertiary.0"
            style={{
              boxShadow: `0 0 0 100vmax ${theme.colors.tertiary[0]}`,
              alignSelf: 'center',
              width: isMdBreakpoint ? 'auto' : '100%',
              ...styles,
            }}
          >
            <Stack
              spacing={0}
              align="center"
              w="100%"
            >
              {children}
            </Stack>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align="center"
              rowGap={24}
              columnGap={28}
              w="100%"
            >
              {withPrimaryButton && (
                <form
                  onSubmit={onFormSubmit}
                  id="blue-screen"
                  style={{
                    width: '100%',
                  }}
                >
                  <input
                    name="stepId"
                    type="hidden"
                    value={stepId}
                  />
                  <input
                    name="answer"
                    type="hidden"
                    value="true"
                  />
                  {buttonVariant === 'primary' && (
                    <PrimaryButton
                      type="submit"
                      isLoading={isLoading}
                      align="center"
                      isOutlineHover
                      isBackgroundTransparentOnHover
                      style={{
                        order: isMdBreakpoint ? 'unset' : -1,
                      }}
                      {...primaryButtonProps}
                    >
                      {primaryButtonLabel}
                    </PrimaryButton>
                  )}

                  {buttonVariant === 'third' && (
                    <Button
                      type="submit"
                      bg="transparent"
                      mt={24}
                      // NOTE: there is a race conditions
                      // https://github.com/mantinedev/mantine/issues/3719
                      w={{ base: '100%', sm: 'auto', md: 'auto' }}
                      size="md"
                      radius="3.125rem"
                      loaderPosition="left"
                      loading={isLoading}
                      px={70}
                      styles={(theme) => ({
                        root: {
                          boxShadow: `0px 2px 4px ${theme.colors.primary[0]}1A`,
                          border: `1px solid ${theme.colors.tertiary[2]}`,
                          transition: '250ms linear',
                          color: theme.colors.tertiary[2],

                          '&:hover': {
                            backgroundColor: theme.colors.tertiary[2],
                            color: theme.colors.primary[0],
                          },

                          '&:disabled': {
                            color: theme.colors.primary[1],
                            backgroundColor: theme.colors.primary[2],
                          },

                          '&[data-loading]': {
                            backgroundColor: theme.colors.primary[2],
                            color: theme.colors.primary[0],
                            border: `1px solid ${theme.colors.primary[2]}`,
                          },

                          // override Mantine overlay style
                          '&[data-loading]::before': {
                            backgroundColor: 'transparent',
                          },
                        },
                        label: {
                          color: 'inherit',
                          fontWeight: 700,
                        },
                      })}
                      {...primaryButtonProps}
                    >
                      {primaryButtonLabel}
                    </Button>
                  )}
                </form>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Transition>
  );
};
