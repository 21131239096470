import { getStepName } from '@/utils/stepUtils';
import { Carousel } from '@mantine/carousel';
import { Container, Flex, Group, List, Text, useMantineTheme } from '@mantine/core';
import { useParams } from '@tanstack/router';
import { Footnotes } from '../components/Footnotes';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { StepSubtitle } from '../components/StepSubtitle';
import { StepTitle } from '../components/StepTitle';
import { Checkmark } from '../components/icons/Checkmark';
import { Star } from '../components/icons/Star';
import { productContent, testimonialSlides } from '../content/productContent';
import { BulletItemIconName } from '../content/stepContent.types';
import { staticStepRoute } from '../router/router';
import { FieldName, StepName } from '../state/state.types';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { ProductBoxes } from '@/components/ProductBoxes';
import { ProductAnimatedSubtitle } from '@/components/ProductAnimatedSubtitle';
import { useMultiChoiceStepResponse } from './hooks/useMultiChoiceStepResponse';
import { useCallback, useEffect, useState } from 'react';

const ProductStep = () => {
  const isMdBreakpoint = useMdBreakpoint();
  const theme = useMantineTheme();

  const nameToIcon: Record<Extract<BulletItemIconName, 'Checkmark'>, JSX.Element> = {
    Checkmark: (
      <Checkmark
        width={24}
        color={theme.colors.minor[1]}
      />
    ),
  };

  const [painPointAnswers, setPainPointAnswers] = useState<string[]>([]);
  const [concernsAnswers, setConcernsAnswers] = useState<string[]>([]);
  const [checkmarks, setCheckmarks] = useState<string[]>([]);

  useMultiChoiceStepResponse(FieldName.pain_point, setPainPointAnswers);
  useMultiChoiceStepResponse(FieldName.concerns, setConcernsAnswers);

  const { stepId } = useParams({ from: staticStepRoute.id });
  const stepName = getStepName(stepId);
  const { onFormSubmit, isLoading } = useCaseUpdater({});

  const { header, bulletList, title, aboveButtonText } = productContent;

  const footnotes = bulletList?.map((bulletList) => bulletList.footnote).filter(Boolean);

  if (!stepId || !stepName)
    throw new Error('StepId or stepName should be defined and should be a product step');

  const isProductStep = stepName === StepName.product;

  const getCheckmarks = useCallback(() => {
    let checkmarks: string[] = [];
    const painPointAnswersLength = painPointAnswers.length;
    const concernsAnswersLength = concernsAnswers.length;

    const multipleInArray = (arr: string[], values: string[]) =>
      values.some((value) => arr.includes(value));

    const updateCheckmarks = (value: string) => {
      checkmarks = checkmarks.concat(value);
    };

    if (painPointAnswersLength > 0) {
      if (multipleInArray(painPointAnswers, ['effort', 'credit-collections'])) {
        updateCheckmarks('We deal with hospitals and/or collections');
      }

      if (multipleInArray(painPointAnswers, ['overwhelmed'])) {
        updateCheckmarks('We handle the process from start to finish');
      }

      if (multipleInArray(painPointAnswers, ['affordability', 'other'])) {
        updateCheckmarks('We help reduce what you owe');
      }

      if (multipleInArray(painPointAnswers, ['billing-errors', 'fair-price'])) {
        updateCheckmarks('We fight for accurate and fair prices');
      }

      checkmarks = checkmarks.slice(0, 2);
    }

    if (concernsAnswersLength > 0) {
      if (multipleInArray(concernsAnswers, ['bankrupcy'])) {
        updateCheckmarks('We help you avoid bankruptcy');
      }

      if (multipleInArray(concernsAnswers, ['stress'])) {
        updateCheckmarks('We support you every step of the way');
      }

      if (multipleInArray(concernsAnswers, ['legal-action'])) {
        updateCheckmarks('We help you avoid legal action');
      }

      if (multipleInArray(concernsAnswers, ['credit-score'])) {
        updateCheckmarks('We fight to protect your credit score');
      }

      if (multipleInArray(concernsAnswers, ['falling-behind'])) {
        updateCheckmarks('We help you stay ahead of your bills');
      }

      if (multipleInArray(concernsAnswers, ['skipping-treatment'])) {
        updateCheckmarks('We keep you in good standing with medical providers');
      }

      checkmarks = checkmarks.slice(0, 4);
    }

    setCheckmarks(checkmarks);
  }, [painPointAnswers, concernsAnswers]);

  useEffect(() => {
    getCheckmarks();
  }, [painPointAnswers, concernsAnswers, getCheckmarks]);

  return (
    <StepForm
      onSubmit={onFormSubmit}
      style={{
        marginTop: 0,
        alignItems: 'center',
        backgroundColor: theme.colors.primary[0],
        boxShadow: `0 0 0 100vmax ${theme.colors.primary[0]}`,
      }}
    >
      <input
        name="stepId"
        type="hidden"
        value={stepId}
      />
      <input
        name="answer"
        type="hidden"
        value="true"
      />

      {!!header && (
        <StepTitle
          align="center"
          fz={{ base: 32, md: 48 }}
          c="minor.1"
          // NOTE: there is a race conditions
          // https://github.com/mantinedev/mantine/issues/3719
          w="100%"
          mt={5}
          sx={{
            lineHeight: isMdBreakpoint ? '48px' : '40px',
          }}
        >
          {header}
        </StepTitle>
      )}

      {!!title &&
        (typeof title === 'string' ? (
          <StepSubtitle
            c="minor.1"
            ta="center"
            w={{ base: 270, md: 560 }}
            sx={{ lineHeight: '22px' }}
          >
            {title}
          </StepSubtitle>
        ) : (
          title()
        ))}

      <ProductBoxes />

      <ProductAnimatedSubtitle />

      {!!bulletList?.length && (
        <Container
          size={400}
          p={0}
          mt={24}
          mb={24}
          w={{ base: '100%', md: 450 }}
        >
          <List
            px={{ base: 10, md: 16 }}
            center
            spacing={10}
            mx="auto"
          >
            {checkmarks.map((item, index) => (
              <List.Item
                icon={nameToIcon.Checkmark}
                key={index}
                fz="sm"
                c="minor.1"
                sx={{ lineHeight: '18px' }}
              >
                <Text c="minor.1">{item}</Text>
              </List.Item>
            ))}
          </List>
        </Container>
      )}

      {aboveButtonText && (
        <Text
          c="minor.1"
          fz="md"
          mt={24}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: aboveButtonText,
            }}
          />
        </Text>
      )}

      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify={{ base: 'revert', md: 'center' }}
        rowGap={24}
        columnGap={28}
        mt={!aboveButtonText ? 40 : 24}
        w="100%"
      >
        <PrimaryButton
          isOutlineHover
          isDisabled={isLoading}
          isLoading={isLoading}
          align="center"
          mt={0}
          w={{ base: '100%', sm: 310, md: 300 }}
        >
          Lower My Bills
        </PrimaryButton>
      </Flex>

      {!isProductStep && (
        <Flex
          mt={24}
          justify="center"
        >
          <Text
            weight={700}
            fz={28}
            c="tertiary.1"
            sx={{ lineHeight: '36px' }}
          >
            4.9
          </Text>
          <Group
            spacing={8}
            ml={12}
          >
            <Star
              color={theme.colors.tertiary[1]}
              width={20}
            />
            <Star
              color={theme.colors.tertiary[1]}
              width={20}
            />
            <Star
              color={theme.colors.tertiary[1]}
              width={20}
            />
            <Star
              color={theme.colors.tertiary[1]}
              width={20}
            />
            <Star
              color={theme.colors.tertiary[1]}
              width={20}
            />
            {isMdBreakpoint ? (
              <Text
                ml={12}
                pt={2}
                weight={600}
                c="minor.1"
              >
                Google Rating
              </Text>
            ) : null}
          </Group>
        </Flex>
      )}

      {!isProductStep && (
        <Carousel
          slideSize={isMdBreakpoint ? '33.33%' : 266}
          dragFree
          withControls={false}
          align="start"
          mt={24}
          slideGap={12}
          w="100%"
        >
          {testimonialSlides.map((testimonial) => (
            <Carousel.Slide key={testimonial.name}>
              <Flex
                direction="column"
                p={18}
                sx={(theme) => ({
                  background: theme.colors.minor[1],
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                  borderBottomLeftRadius: '12px',
                  height: '180px',
                })}
              >
                <Text
                  color="primary.0"
                  sx={{ lineHeight: '18px', flexGrow: 1 }}
                >
                  {testimonial.quote}
                </Text>
                <Flex
                  mt="auto"
                  pt={4}
                >
                  <Text
                    fw={600}
                    c="tertiary.1"
                  >
                    {testimonial.name},&nbsp;
                  </Text>
                  <Text
                    color="tertiary.1"
                    fw={600}
                  >
                    {testimonial.saving}
                  </Text>
                </Flex>
              </Flex>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
      {!!footnotes?.length && <Footnotes footnotes={footnotes} />}
    </StepForm>
  );
};

export { ProductStep };
