import { ComponentPropsWithoutRef } from 'react';

export const Search = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.57112 9.04288C-4.92972 23.551 9.26221 38.9306 24.0141 33.292C24.2077 33.2182 24.4246 33.2684 24.5593 33.4246C26.9435 36.1657 33.671 43.9075 36.5221 47.1859C37.239 48.0101 38.4912 48.0908 39.3071 47.3647L44.5247 42.7332C45.3775 41.9767 45.413 40.6593 44.6017 39.8582C41.0142 36.3231 36.7469 31.8247 33.4459 28.4869L32.6286 29.1982C34.8755 32.149 38.0035 36.1344 40.2874 39.0573C40.7289 39.614 41.159 40.1816 41.6052 40.7355C41.7965 40.9732 41.7699 41.319 41.538 41.5196L38.5304 44.1346C38.294 44.3406 37.9385 44.3148 37.7327 44.0809C35.0023 40.9402 28.547 33.513 26.094 30.6914C25.5253 30.0371 24.5982 29.8316 23.8092 30.1872C23.7735 30.2027 23.7378 30.2182 23.707 30.2332C11.248 35.7627 -1.19063 22.6902 5.17397 10.4432C7.59414 5.85874 12.6328 2.85358 17.8204 2.93335C25.7744 2.86742 32.8252 10.0127 32.4929 17.9893C32.439 19.8864 31.9981 21.7654 31.2678 23.5265C30.9293 24.2602 30.5714 25.0549 30.2173 25.7801L31.1903 26.2557C31.5413 25.6643 31.9031 25.0002 32.2459 24.3995C33.7709 21.556 34.6538 18.3758 34.4336 15.097C32.9482 -1.79693 10.5137 -5.49893 2.57112 9.04288Z"
      fill="#9788C0"
    />
  </svg>
);
