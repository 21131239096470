import { Flex } from '@mantine/core';
import { ReactNode } from 'react';

type ContentContainerProps = {
  children: ReactNode;
};

export const ContentContainer = ({ children }: ContentContainerProps) => {
  return (
    <Flex
      direction="column"
      maw={760}
      w="100%"
      mx="auto"
    >
      {children}
    </Flex>
  );
};
