import { fetchCaseState, fetchJSON } from '../fetchers/backend-fetchers';
import { queryClient } from './queryClient';
import { AllowedPatchField, Case, CaseState, isAllowedPatchFieldGuard } from './state.types';

type AllowedElements = { name: AllowedPatchField; value: FormDataEntryValue }[];

export const patchCase = async (formData: FormData): Promise<CaseState> => {
  const caseState = queryClient.getQueryData<CaseState>(['caseState']);
  const caseId = caseState?.case?.id;

  if (!caseId) {
    throw new Error('Case is not found');
  }

  const { sequenceId, ...values } = Object.fromEntries(formData);

  const fields = Object.entries(values).reduce((prev: AllowedElements, curr) => {
    const [key, value] = curr;
    if (!isAllowedPatchFieldGuard(key)) {
      throw new Error('Not allowed field name for case patching');
    }

    return [...prev, { name: key, value }];
  }, [] as AllowedElements);

  if (sequenceId) {
    await fetchJSON<Case>(`/case/${caseId}`, {
      method: 'PATCH',
      data: {
        sequenceId,
      },
    });
  }

  if (fields.length) {
    await fetchJSON<Case>(`/case/${caseId}`, {
      method: 'PATCH',
      data: {
        fields,
      },
    });
  }

  const patchedCaseState = await fetchCaseState();
  if (!patchedCaseState) throw new Error('Case state is undefined after PATCH');

  queryClient.setQueryData(['caseState'], patchedCaseState);

  return patchedCaseState;
};
