import { Flex, Group, Stack, Text, Transition, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useBillSize } from '../routes/hooks/useBillSize';
import { useCaseUpdater } from '../routes/hooks/useCaseUpdater';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';
import {
  RedirectToBookAppointmentButton,
  RedirectToBookAppointmentButtonProps,
} from './RedirectToBookAppointmentButton';
import { ArrowUpward } from './icons/ArrowUpward';

const animationDuration = 300;

export const BlueScreen = ({
  primaryButtonLabel = `Continue`,
  primaryButtonProps = {},
  bookingAppointmentButtonProps = {
    appointmentUrl: 'https://calendly.com/melissa-753/20-minute-consultation-melissajohnson',
    shouldOpenCallingApp: false,
  },
  children,
  stepId,
  withPrimaryButton = true,
  withRedirectToBookingAppointmentButton = false,
}: {
  withPrimaryButton?: boolean;
  withRedirectToBookingAppointmentButton?: boolean;
  primaryButtonLabel?: string;
  primaryButtonProps?: PrimaryButtonProps;
  bookingAppointmentButtonProps?: Omit<RedirectToBookAppointmentButtonProps, 'stepId'>;
  children: React.ReactNode;
  stepId: string;
}) => {
  const theme = useMantineTheme();
  const [mounted, setMounted] = useState(false);
  const { billSizeUnder15k } = useBillSize();
  const isMdBreakpoint = useMdBreakpoint();

  // TODO: find a better way to enable animation after the navigation to the page
  useEffect(() => {
    setTimeout(() => setMounted(true), 0);
  }, []);

  const { onFormSubmit, isLoading } = useCaseUpdater({});

  return (
    <Transition
      mounted={mounted}
      transition="fade"
      duration={animationDuration}
      timingFunction="ease-out"
    >
      {(styles) => (
        <>
          <Flex
            direction="column"
            align="center"
            justify="space-between"
            bg="primary.0"
            style={{
              boxShadow: `0 0 0 100vmax ${theme.colors.primary[0]}`,
              ...styles,
            }}
          >
            <Stack
              spacing={0}
              align="center"
              w="100%"
            >
              {children}
            </Stack>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align="center"
              justify={{ base: 'revert', md: 'center' }}
              rowGap={24}
              columnGap={28}
              w="100%"
            >
              {withPrimaryButton && (
                <form
                  onSubmit={onFormSubmit}
                  id="blue-screen"
                  style={{
                    width: isMdBreakpoint ? 'unset' : '100%',
                  }}
                >
                  <input
                    name="stepId"
                    type="hidden"
                    value={stepId}
                  />
                  <input
                    name="answer"
                    type="hidden"
                    value="true"
                  />
                  <PrimaryButton
                    isLoading={isLoading}
                    align="center"
                    isOutlineHover={true}
                    style={{
                      order: isMdBreakpoint ? 'unset' : -1,
                    }}
                    {...primaryButtonProps}
                  >
                    {primaryButtonLabel}
                  </PrimaryButton>
                </form>
              )}

              {withRedirectToBookingAppointmentButton && !billSizeUnder15k && (
                <RedirectToBookAppointmentButton
                  stepId={stepId}
                  {...bookingAppointmentButtonProps}
                >
                  <Group spacing={2}>
                    <Text
                      c="minor.1"
                      fw={700}
                    >
                      Finish over the phone
                    </Text>
                    <ArrowUpward color={theme.colors.tertiary[1]} />
                  </Group>
                </RedirectToBookAppointmentButton>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Transition>
  );
};
