import { StepContent } from '@/content/stepContent.types';
import { Step } from '@/state/state.types';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { Flex, Group, List, Text } from '@mantine/core';
import { StepSubtitle } from './StepSubtitle';
import { StepTitle } from './StepTitle';
import { DocSigned } from './icons/DocSigned';

export const CheckoutCard = ({
  content: { title, subtitle, bulletList, description },
  completeText,
}: {
  content: StepContent;
  completeText?: string;
  step: Step;
}) => {
  const isMdBreakpoint = useMdBreakpoint();

  return (
    <Flex
      gap={0}
      direction={{ base: 'column', md: completeText ? 'row' : 'column' }}
      justify="space-between"
      align={{ base: 'start', md: completeText ? 'center' : 'start' }}
    >
      {!!title &&
        (typeof title === 'string' ? (
          <StepTitle
            size="h5"
            align="left"
            style={{
              lineHeight: 'inherit',
            }}
          >
            {title}
          </StepTitle>
        ) : (
          title()
        ))}

      {completeText ? (
        <Group
          c="primary.0"
          align="center"
          mt={{ base: 6, md: 0 }}
          spacing={8}
        >
          <DocSigned />
          <Text
            fz="sm"
            fw={600}
          >
            {completeText}
          </Text>
        </Group>
      ) : (
        <>
          {subtitle && (
            <StepSubtitle
              c="primary.0"
              mt={8}
              fw={700}
            >
              {subtitle}
            </StepSubtitle>
          )}

          {!!description && (
            <Text
              ta="left"
              c="primary.0"
              mt={8}
              fz={14}
            >
              {description}
            </Text>
          )}

          {!!bulletList?.length && (
            <List
              center
              mt={8}
              spacing={16}
              styles={{
                itemIcon: {
                  alignSelf: 'start',
                },
                root: {
                  paddingLeft: 0,
                },
              }}
              style={{
                // To override browser default styling
                paddingLeft: isMdBreakpoint ? 8 : undefined,
              }}
            >
              {bulletList
                .sort((a, b) => {
                  if (a.id > b.id) return 1;
                  else return -1;
                })
                .map((item) => (
                  <List.Item key={item.id}>
                    <Text c="primary.0">{item.text}</Text>
                  </List.Item>
                ))}
            </List>
          )}
        </>
      )}
    </Flex>
  );
};
