import { Branch, StepName } from '../state/state.types';

export const whyActivateContent: Record<string, any> = {
  [StepName.intake_notes]: {
    title: 'Why do we ask for this?',
    description: (
      <div>
        <p>This is one of the most important steps to help us get started.</p>
        <p>
          While we can connect the dots for data-driven insights with some of the other answers you
          provide, the details within your story help us begin to strategically think about the
          unique approaches we can take to get you the savings you deserve.
        </p>
      </div>
    ),
  },
  [StepName.has_insurance]: {
    title: 'Why do we ask about health insurance?',
    description: (
      <div>
        <p>
          Since we have relationships and direct contacts with the majority of health insurance
          providers, knowing who we may be dealing with helps us develop our reduction strategy.
        </p>
        <p>
          Our insights and data from past bill reductions helps us understand how to approach your
          situation to maximize the amount we reduce your bill.
        </p>
        <p>
          If you don&apos;t have insurance, that&apos;s fine! It&apos;s another detail we use to
          determine how to build your reduction strategy.
        </p>
      </div>
    ),
  },
  [StepName.in_collections]: {
    title: 'Why do we ask about bill collections?',
    description: (
      <div>
        <p>
          Knowing this lets us know there&apos;s another party to consider in the process beyond
          just hospitals and insurance providers.
        </p>
        <p>
          Your Advocate will also work to stay between you and the collectors until we reduce your
          bill to a level you deserve.
        </p>
      </div>
    ),
  },
  [`${StepName.referral_method_001}.${Branch.create_account}`]: {
    title: 'Why do we ask about this?',
    description: (
      <div>
        <p>
          Unaffordable medical bills unfortunately is becoming a bigger and bigger burden on
          Americans every year. Your insights here help us plan ahead for how to connect with
          patients to let them know they have options!
        </p>
      </div>
    ),
  },
  [StepName.legal_encumbrance]: {
    title: 'Why do we ask about this?',
    description: (
      <div>
        <p>
          Unfortunately, there is very little we can do once a medical bill goes to legal action. We
          ask this to both help plan our strategy and to not waste your time in the event we’re
          unable to help.
        </p>
      </div>
    ),
  },
  [StepName.household_size]: {
    title: 'Why do we ask for this?',
    description: (
      <div>
        <p>
          This is one of many inputs that helps us identify what sort of financial benefits, breaks,
          and other discounts you may be entitled to.
        </p>
      </div>
    ),
  },
  [StepName.above_fpl]: {
    title: 'Why do we ask this?',
    description: (
      <div>
        <p>
          This is one of many inputs that helps us identify what sort of financial benefits, breaks,
          and other discounts you may be entitled to.
        </p>
      </div>
    ),
  },
  [StepName.email]: {
    title: 'Why do we ask for your email?',
    description: (
      <div>
        <p>
          You&apos;ll use this to automatically save your progress as you enter details about your
          bills, to log in to your dashboard to monitor the progress we&apos;re making on your
          behalf, and to receive notifications related to your process.
        </p>
      </div>
    ),
  },
  [StepName.complete_account]: {
    title: 'Why do we ask for your email?',
    description: (
      <div>
        <p>
          You&apos;ll use this to automatically save your progress as you enter details about your
          bills, to log in to your dashboard to monitor the progress we&apos;re making on your
          behalf, and to receive notifications related to your process.
        </p>
      </div>
    ),
  },
  [StepName.medical_provider_name]: {
    title: 'Why do we ask for this?',
    description: (
      <div>
        <p>
          Since we have relationships and direct contacts with the majority of hospitals and medical
          providers across the U.S., knowing who we may be dealing with helps us plan our reduction
          strategy.
        </p>
        <p>
          Our insights and data from past bill reductions helps us understand how to approach your
          situation with this provider to maximize the amount we reduce your bill.
        </p>
      </div>
    ),
  },
};
