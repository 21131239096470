import { StepTitle } from '@/components/StepTitle';
import { FieldName, StepName } from '../state/state.types';
import { StepContent, StepRecordContent } from './stepContent.types';

export const choiceAndOpenStepContent: StepRecordContent<StepContent> = {
  [StepName.bill_size]: {
    default: {
      title: 'How much do you owe in medical bills?',
      subtitle: `This is the remaining amount you owe. Don't include payments already made or discounts received.`,
      field: {
        name: FieldName.bill_size,
      },
    },
  },
  [StepName.max_bill_size]: {
    default: {
      title: 'How much do you still owe on your largest bill?',
      subtitle:
        'An estimate is okay! If you have multiple bills, you can enter that information later.',
      field: {
        name: FieldName.max_bill_size,
        inputTypeAttribute: 'number',
        prefix: '$ ',
        placeholder: '$',
      },
    },
  },
  // Compatibility with sequence 423543b6-9a59-4f65-8de3-f5a759c5b6a7 and earlier
  [StepName.anticipate_bills_001]: {
    default: {
      title: `Do you have ongoing medical bills that you need help with?`,
      field: {
        name: FieldName.bill_frequency,
      },
    },
  },
  [StepName.anticipate_bills_002]: {
    default: {
      title: `What best describes your situation?`,
      field: {
        name: FieldName.bill_frequency,
      },
    },
  },
  [StepName.stress_level]: {
    default: {
      title: `How much stress are you feeling from these bills?`,
      field: {
        name: FieldName.stress_level,
      },
      choiceOrientation: 'row',
    },
  },
  [StepName.recurring_bill_size]: {
    default: {
      title: 'Approximately how much are your recurring monthly medical bills?',
      field: {
        name: FieldName.recurring_bill_size,
      },
    },
  },
  [StepName.has_insurance]: {
    default: {
      title: 'Do you have health insurance?',
      subtitle: `It's okay if you don't - we can still help you save. Knowing if you do helps us evaluate your options.`,
      field: {
        name: FieldName.has_insurance,
      },
    },
  },
  [StepName.referral_method_001]: {
    default: {
      title: `We're curious how you heard about Resolve`,
      subtitle: `This helps us understand the best places to reach others who may need our help lowering medical bills.`,
      field: {
        name: FieldName.referral_method,
      },
    },
    'cannot-help': {
      title: `We're curious how you heard about Resolve`,
      subtitle: `This helps us understand the best places to reach others who may need our help lowering medical bills.`,
      field: {
        name: FieldName.referral_method,
      },
    },
  },
  [StepName.feeling]: {
    default: {
      title: 'How are you feeling about your medical bills?',
      field: {
        name: FieldName.feeling,
      },
    },
  },
  [StepName.in_collections]: {
    default: {
      title: 'Are any of your medical bills in collections?',
      subtitle: `Don't worry if they are - we can still help.`,
      field: {
        name: FieldName.in_collections,
      },
    },
  },
  [StepName.legal_encumbrance]: {
    default: {
      title:
        'Are there any lawsuits, wage garnishments, liens, or pending court dates for your medical bills?',
      subtitle: `This helps us determine ways we can help.`,
      field: {
        name: FieldName.legal_encumbrance,
      },
    },
  },
  [StepName.pain_point_select_001]: {
    default: {
      title: 'What has been most frustrating about your medical bills?',
      subtitle: `Select up to 3.`,
      field: {
        name: FieldName.pain_point,
      },
    },
  },
  [StepName.concerns]: {
    default: {
      title: 'What makes you most nervous about not resolving your medical bills?',
      subtitle: `Select up to 3.`,
      field: {
        name: FieldName.concerns,
      },
    },
  },
  [StepName.insurance_company_name]: {
    default: {
      title: 'What is the name of your insurance company?',
      field: {
        name: FieldName.insurance_company_name,
        inputElement: 'input',
        helperText: `If you don't know, simply click "Continue".`,
        placeholder: 'Enter name',
        label: 'Health insurance company',
      },
    },
  },
  [StepName.intake_notes]: {
    default: {
      title: 'Please share the story behind your medical bills.',
      subtitle: `Everyone's situation is unique and the details matter. Our experts will use these to find ways to lower your bill(s). You can always provide more details later.`,
      field: {
        name: FieldName.intake_notes,
        inputElement: 'textarea',
        placeholder: 'I went to the hospital after feeling...',
        helperText: 'Don\'t feel like sharing? Simply click "Continue".',
      },
    },
  },
  [StepName.medical_provider_name]: {
    default: {
      title: 'Which hospital or medical provider sent your largest bill?',
      subtitle: `We have relationships with most billing departments to make negotiations move faster.`,
      field: {
        name: FieldName.medical_provider_name,
        inputElement: 'input',
        helperText: `If you don't know, simply click "Continue".`,
        placeholder: 'Enter medical provider',
        errorMessage: 'Please enter a medical provider name',
      },
    },
  },
  [StepName.collections_company_name]: {
    default: {
      title: 'What is the name of the collection agency?',
      field: {
        name: FieldName.collections_company_name,
        inputElement: 'input',
        helperText: `If you're not sure, enter "I don't know"`,
        placeholder: 'Enter collections agency name',
        label: 'Name of collections agency',
        errorMessage: 'Please provide a collections company name',
      },
    },
  },
  [StepName.offer_consult]: {
    default: {
      title: 'Would you like to talk to a live expert?',
      subtitle: `We're here to help if you have questions or want assistance signing up.`,
    },
  },
  [StepName.financial_aid]: {
    default: {
      title: 'You should qualify for Financial Aid!',
    },
  },
  [StepName.household_size]: {
    default: {
      title: 'How many people live in your household?',
      subtitle: 'This helps us determine your options for different financial discounts.',
      field: {
        name: FieldName.household_size,
        inputTypeAttribute: 'number',
      },
    },
  },
  [StepName.above_fpl]: {
    default: {
      title: (data: { amount: string }) => (
        <StepTitle>
          Is your current household income{' '}
          <span
            style={{
              color: '#2AAAAA',
            }}
          >
            more than {data.amount}
          </span>
          ?
        </StepTitle>
      ),
      subtitle: 'This also helps us evaluate your options for different financial discounts.',
      field: {
        name: FieldName.above_fpl,
      },
    },
  },
};
