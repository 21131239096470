import { Case } from '@/state/state.types';
import { useEffect, useRef } from 'react';

export const usePreviousCase = (case_: Case | undefined) => {
  const ref = useRef<Case>();

  useEffect(() => {
    if (!case_) return;

    ref.current = case_;
  }, [case_]);

  return ref.current;
};
