import { sevenDays } from '@/utils/constants';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { RouterProvider } from '@tanstack/router';
import { localStoragePersister, queryClient } from '../state/queryClient';
import { router } from './router';

export const RouterAndStateCache = () => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: localStoragePersister,
        maxAge: sevenDays,
        buster: '',
      }}
    >
      <RouterProvider router={router} />
      <ReactQueryDevtools
        initialIsOpen
        position="bottom-left"
        toggleButtonProps={{
          style: {
            marginLeft: 10,
            transform: `scale(.7)`,
            transformOrigin: 'bottom left',
          },
        }}
      />
    </PersistQueryClientProvider>
  );
};
