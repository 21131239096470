import { isDarkBackground } from '@/utils/showLayoutElements';
import { Button, Text, useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams, useRouter } from '@tanstack/router';
import { Step } from '../state/state.types';
import { ArrowLeft } from './icons/ArrowLeft';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';

export const BackButton = () => {
  const { stepId } = useParams();
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });
  const isMdBreakpoint = useMdBreakpoint();
  const theme = useMantineTheme();
  const router = useRouter();

  if (!stepId) return null;

  const color = isDarkBackground(stepId) ? theme.colors.minor[1] : theme.colors.primary[0];

  return (
    <>
      {step?.counter && step.counter > 0 ? (
        <Button
          type="button"
          styles={() => ({
            root: {
              backgroundColor: 'transparent',
              border: 'none',
              color,
            },
          })}
          pr={0}
          pl={2}
          py={0}
          onClick={() => router.history.back()}
        >
          <ArrowLeft color={color} />
          <Text
            ml={12}
            c={color}
            fw={700}
          >
            Back
          </Text>
        </Button>
      ) : (
        <Text
          c={color}
          fw={700}
          size={isMdBreakpoint ? 16 : 12}
        >
          Let&apos;s start
        </Text>
      )}
    </>
  );
};
