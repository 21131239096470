import { queryClient } from '@/state/queryClient';
import { QueryKey } from '@tanstack/react-query';

/**
 * Detects if queryFn is presented for a specific queryKey
 * NOTE: Unfortunately, It uses queryClient internal API which could be changed in future
 * We need the helper for re-establishing queryFn after hydration
 */
export const isQueryFnPresented = (queryKey: QueryKey) => {
  const check = queryClient
    .getQueryCache()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .queries.find((el) => el.queryHash === JSON.stringify(queryKey))?.options?.queryFn;
  return !!check;
};
