import { CheckAndDisplaySalesBanner } from '@/components/CheckAndDisplaySalesBanner';

import { getStepContent } from '@/utils/getStepContent';
import { getStepName } from '@/utils/stepUtils';
import { Center, Flex, Group, Loader, SimpleGrid, Stack, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/router';
import { useState } from 'react';
import { StepForm } from '../components/StepForm';
import { StepSubtitle } from '../components/StepSubtitle';
import { StepTitle } from '../components/StepTitle';
import { choiceAndOpenStepContent } from '../content/choiceAndOpenStepContent';
import { bookAppointmentRoute, choiceStepRoute } from '../router/router';
import { Feeling, Step, StepName } from '../state/state.types';
import { useCaseUpdater } from './hooks/useCaseUpdater';

import { ContentContainer } from '@/components/ContentContainer';
import { SalesAvatars } from '@/components/SalesAvatars';
import { WhyDoWeAsk } from '@/components/WhyDoWeAsk';
import { Overwhelmed } from '@/components/icons/feeling/Overwhelmed';
import { Person } from '@/components/icons/feeling/Person';
import { Search } from '@/components/icons/feeling/Search';
import { Wrong } from '@/components/icons/feeling/Wrong';
import { StressLevelChartMobile } from '@/components/icons/stressLevel/CharMobile';
import { StressLevelChart } from '@/components/icons/stressLevel/Chart';
import { SingleSelectButton } from '@/components/ui/SingleSelectButton';
import { whyActivateContent } from '@/content/whyActivateContent';
import { validateHeapSafely } from '@/metrics/heap.types';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';
import { updateCase } from '@/state/updateCase';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { FinancialAid } from './FinancialAid';
import { useCasePatcher } from './hooks/useCasePatcher';

const ChoiceStep = () => {
  const { stepId } = useParams({ from: choiceStepRoute.id });
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });

  const consultationLink = useConsultationLink();

  const { onFormSubmit, data } = useCaseUpdater({});

  const isSequenceFinished = data?.remaining === 0;

  const stepName = getStepName(stepId);

  const [answer, setAnswer] = useState<string>('');

  const isMdBreakpoint = useMdBreakpoint();

  if (!stepId || !stepName) throw new Error('StepId and stepName should be defined');

  const { title, subtitle, choiceOrientation } = getStepContent(stepId, choiceAndOpenStepContent);

  const isOfferConsult = stepName === StepName.offer_consult;
  const isFinancialAid = stepName === StepName.financial_aid;

  const cols = () => {
    if (stepName === StepName.stress_level && isMdBreakpoint) return 5;
    if (stepName === StepName.feeling && isMdBreakpoint) return 4;
    return 1;
  };

  const icon = (id: string) => {
    if (stepName === StepName.feeling) {
      const style = { height: isMdBreakpoint ? 'auto' : 30 };
      if (Feeling.Values['need-an-expert'] === id) return <Person style={style} />;
      if (Feeling.Values['wrong-and-unfair'] === id) return <Wrong style={style} />;
      if (Feeling.Values['overwhelmed'] === id) return <Overwhelmed style={style} />;
      if (Feeling.Values['researching-options'] === id) return <Search style={style} />;
    }

    if (stepName === StepName.stress_level) {
      if (isMdBreakpoint) return <StressLevelChart level={Number(id)} />;

      return <StressLevelChartMobile level={Number(id)} />;
    }

    return undefined;
  };

  const variant = () => {
    if (stepName === StepName.feeling) return 'withIcons';
    return undefined;
  };

  const stressLevelInfo = (id: string) => {
    if (id === '1') return 'No stress';
    if (id === '2') return 'A little stressed';
    if (id === '3') return 'Somewhat stressed';
    if (id === '4') return 'Very stressed';
    if (id === '5') return 'Extremely stressed';
    return '';
  };

  const { submit } = useCasePatcher({
    search: {
      appointmentUrl: consultationLink,
    },
    to: bookAppointmentRoute.id,
  });

  const redirectToBookAppointment = () => {
    if (validateHeapSafely()) {
      window.heap?.track('offer-consult-redirect-to-calendly-event', {
        stepId,
      });
    }

    const formData = new FormData();
    formData.append('talk-to-an-advisor-click', stepId);
    submit(formData);
  };

  if (isFinancialAid) {
    return <FinancialAid />;
  }

  const { title: whyActivateTitle, description: whyActivateDescription } =
    whyActivateContent?.[stepId] ?? {};

  return (
    <>
      {isSequenceFinished ? (
        <Center mt="xl">
          <Stack
            spacing={24}
            align="center"
          >
            <StepTitle>Please wait, you will be redirected to the site shortly</StepTitle>
            <Loader
              size="xl"
              color="primary.0"
            />
          </Stack>
        </Center>
      ) : (
        <>
          {stepId === StepName.bill_size ? (
            <>
              <Flex
                align="center"
                justify="center"
                mt="auto"
                direction="column"
                style={{
                  gap: 10,
                  alignItems: 'center',
                  marginBottom: 50,
                  background: '#2e193e',
                  padding: 20,
                  borderRadius: 20,
                }}
              >
                <Text
                  c="#fff0e4"
                  weight="bold"
                  style={{ textAlign: 'center', fontSize: 18 }}
                >
                  As of Sept 30, 2024 we can no longer accept new patients from your organization
                </Text>
                <Text
                  c="#fff0e4"
                  style={{ textAlign: 'center', marginTop: 10 }}
                >
                  <span style={{ textDecoration: 'underline' }}>Existing patients</span> - we will
                  continue to work on lowering your medical bills. You can access your account by
                  going to resolvemedicalbills.com and clicking the Login button in the upper right
                  hand corner.
                </Text>

                <Text
                  c="#fff0e4"
                  style={{ textAlign: 'center', marginTop: 10 }}
                >
                  See below for some additional resources to help lower your medical bills.
                </Text>

                <Text
                  c="#fff0e4"
                  style={{ textAlign: 'center', marginTop: 10 }}
                >
                  We apologize for the inconvenience and are truly thankful to the thousands of
                  people we&apos;ve worked with to find medical bills savings.
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="center"
                mt="auto"
                direction="column"
                style={{
                  gap: 10,
                  alignItems: 'center',
                  marginBottom: 50,
                  background: '#e1d4eb',
                  padding: 20,
                  borderRadius: 20,
                }}
              >
                <Text
                  c="#2e193e"
                  weight="bold"
                  style={{ textAlign: 'center', fontSize: 30 }}
                >
                  Additional Key Resources
                </Text>
                <ul>
                  <li style={{ color: '#2e193e', marginBottom: 5 }}>
                    <a
                      href="https://app.umbrahealthadvocacy.com/search"
                      style={{ fontWeight: 600, color: '#2e193e' }}
                    >
                      Umbra Health Advocacy
                    </a>{' '}
                    (directory of patient advocates)
                  </li>
                  <li style={{ color: '#2e193e', marginBottom: 5 }}>
                    <a
                      href="https://dollarfor.org/"
                      style={{ fontWeight: 600, color: '#2e193e' }}
                    >
                      Get Relief from Hospital Bills – Dollar For
                    </a>{' '}
                    (see if qualify for income based financial aid)
                  </li>
                  <li style={{ color: '#2e193e' }}>
                    <a
                      href="https://www.resolvemedicalbills.com/guides/medical-bill-guide"
                      style={{ fontWeight: 600, color: '#2e193e' }}
                    >
                      Resolve Guide to Lowering Medical Bills
                    </a>{' '}
                    (our completely free DIY guide to lowering your medical bills (warning - it’s
                    long)
                  </li>
                </ul>
              </Flex>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  margin: '0 0 15px 0',
                }}
              >
                {!!title &&
                  (typeof title === 'string' ? (
                    <StepTitle fz={{ base: 28, md: 32 }}>{title}</StepTitle>
                  ) : (
                    title()
                  ))}
                {!!subtitle && <StepSubtitle>{subtitle}</StepSubtitle>}
              </div>
              <ContentContainer>
                <StepForm
                  onSubmit={async (e) => {
                    if (isOfferConsult && answer === 'yes') {
                      e.preventDefault();
                      const form = new FormData(e.currentTarget);
                      await updateCase(form);
                      redirectToBookAppointment();
                    } else {
                      onFormSubmit(e);
                    }
                  }}
                >
                  {isOfferConsult && (
                    <Group
                      spacing={4}
                      mb={20}
                      mx="auto"
                      mt={20}
                    >
                      <SalesAvatars />
                    </Group>
                  )}
                  <input
                    name="stepId"
                    type="hidden"
                    value={stepId}
                  />
                  <input
                    name="answer"
                    type="hidden"
                    value={answer}
                  />
                  <SimpleGrid
                    mt={isMdBreakpoint ? 24 : 5}
                    cols={cols()}
                    spacing={12}
                    verticalSpacing={12}
                    maw={760}
                    w="100%"
                    mx="auto"
                  >
                    {step?.choices?.map((choice, index) => (
                      <SingleSelectButton
                        key={index}
                        uniqueKey={choice.id || `${index}`}
                        isLoading
                        value={choice.id}
                        onClick={(value) => {
                          setAnswer(value);
                        }}
                        isRow={isMdBreakpoint && choiceOrientation === 'row'}
                        answer={answer}
                        label={
                          stepName === StepName.stress_level ? (
                            <Flex
                              align="center"
                              justify={isMdBreakpoint ? 'center' : 'flex-start'}
                              gap={16}
                            >
                              <Text size={22}>{choice.display}</Text>
                              {!isMdBreakpoint && (
                                <Text fw={400}>{stressLevelInfo(choice.id)}</Text>
                              )}
                            </Flex>
                          ) : (
                            choice.display
                          )
                        }
                        isLast={step?.choices?.length === index + 1}
                        icon={icon(choice.id)}
                        variant={variant()}
                      />
                    ))}
                  </SimpleGrid>
                  {isMdBreakpoint && choiceOrientation === 'row' && (
                    <Flex
                      direction="row"
                      justify="space-between"
                      mt={16}
                      maw={760}
                      w="100%"
                      mx="auto"
                    >
                      <Text c="primary.0">Very little</Text>
                      <Text c="primary.0">Overwhelmed</Text>
                    </Flex>
                  )}
                </StepForm>
                {CheckAndDisplaySalesBanner(stepId)}
              </ContentContainer>
              {whyActivateTitle && whyActivateDescription && (
                <Flex
                  align="center"
                  justify="center"
                  mt="auto"
                >
                  <WhyDoWeAsk
                    title={whyActivateTitle}
                    description={whyActivateDescription}
                    stepId={stepId}
                  />
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { ChoiceStep };
