import { StepHeader } from '@/components/StepHeader';
import { Flex, Text, useMantineTheme } from '@mantine/core';
import { Email } from '../components/icons/Email';

const SsoAuthenticate = () => {
  const theme = useMantineTheme();

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      bg="minor.1"
      mt={188}
      py={{ base: 24, md: 80 }}
      px={{ base: 24, md: 120 }}
      mx={8}
      sx={{
        boxShadow: `0 0 0 100vmax ${theme.colors.minor[0]}`,
        borderRadius: '12px',
      }}
    >
      <StepHeader
        order={3}
        size="h3"
      >
        Welcome back
      </StepHeader>
      <Text
        align="center"
        mt={{ base: 8, md: 24 }}
        c="primary.0"
      >
        Logging in...
      </Text>
      <Flex
        direction="row"
        align="center"
        mt={{ base: 24, md: 48 }}
        gap={10}
      >
        <Text
          color="tertiary.1"
          fw={700}
        >
          An email is waiting for you
        </Text>
        <Email
          color={theme.colors.tertiary[1]}
          width={22}
          height={19}
        />
      </Flex>
    </Flex>
  );
};

export { SsoAuthenticate };
