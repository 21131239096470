import { Product } from '../state/state.types';
import {
  BulletItem,
  StepContent,
  StepRecordContentByAmountByDependency,
} from './stepContent.types';

const defaultStepContent: StepContent = {
  header: 'Before we can start',
  description: `Our patient advocates are ready to start working as soon as you do the following:`,
};

const pointInTimeBulletList: BulletItem[] = [
  {
    id: '1',
    text: 'Provide us your documents',
    iconName: 'ArrowRightCircledFilled',
    highlight: true,
  },
  {
    id: '2',
    text: 'Sign a document allowing us to represent you',
    iconName: 'ArrowRightCircledFilled',
    highlight: true,
  },
];

const pointInTime10_99_BulletList: BulletItem[] = [
  ...pointInTimeBulletList,
  {
    id: '3',
    text: 'Accept our pricing and conditions',
    iconName: 'ArrowRightCircledFilled',
    highlight: true,
    subBulletList: [
      {
        id: '1',
        text: `We charge 10% of the savings \u{00B9}`,
        iconName: 'CircleFilled',
      },
      {
        id: '2',
        text: `You make a $99 refundable deposit \u{00B2} to confirm your willingness to use our services.`,
        iconName: 'CircleFilled',
      },
    ],
  },
];

const pointInTime10_250_BulletList: BulletItem[] = [
  ...pointInTimeBulletList,
  {
    id: '3',
    text: 'Accept our pricing and conditions',
    iconName: 'ArrowRightCircledFilled',
    highlight: true,
    subBulletList: [
      {
        id: '1',
        text: `We charge 10% of the savings \u{00B9}`,
        iconName: 'CircleFilled',
      },
      {
        id: '2',
        text: `You make a $250 refundable deposit \u{00B2} to confirm your willingness to use our services.`,
        iconName: 'CircleFilled',
      },
    ],
  },
];

const pointInTime25_99_BulletList: BulletItem[] = [
  ...pointInTimeBulletList,
  {
    id: '3',
    text: 'Accept our pricing and conditions',
    iconName: 'ArrowRightCircledFilled',
    highlight: true,
    subBulletList: [
      {
        id: '1',
        text: `We charge 25% of the savings \u{00B9}`,
        iconName: 'CircleFilled',
      },
      {
        id: '2',
        text: `You make a $99 refundable deposit \u{00B2} to confirm your willingness to use our services.`,
        iconName: 'CircleFilled',
      },
    ],
  },
];

const pointInTime25_250_BulletList: BulletItem[] = [
  ...pointInTimeBulletList,
  {
    id: '3',
    text: 'Accept our pricing and conditions',
    iconName: 'ArrowRightCircledFilled',
    highlight: true,
    subBulletList: [
      {
        id: '1',
        text: `We charge 25% of the savings \u{00B9}`,
        iconName: 'CircleFilled',
      },
      {
        id: '2',
        text: `You make a $250 refundable deposit \u{00B2} to confirm your willingness to use our services.`,
        iconName: 'CircleFilled',
      },
    ],
  },
];

const pointInTimeStepContent: StepContent = {
  ...defaultStepContent,
  footnoteList: [
    {
      id: '1',
      text: 'Based on the information you provided so far.',
    },
    {
      id: '2',
      text: `The deposit is refunded to you if we don't generate savings.`,
    },
  ],
};

export const termsStepContent: StepRecordContentByAmountByDependency<Product> = {
  'bill-reduction-25': {
    9900: { ...pointInTimeStepContent, bulletList: pointInTime25_99_BulletList },
    25000: { ...pointInTimeStepContent, bulletList: pointInTime25_250_BulletList },
  },
  'bill-reduction-10': {
    9900: { ...pointInTimeStepContent, bulletList: pointInTime10_99_BulletList },
    25000: { ...pointInTimeStepContent, bulletList: pointInTime10_250_BulletList },
  },
  subscription: {
    4999: {
      ...defaultStepContent,
      bulletList: [
        {
          id: '1',
          text: 'Sign a document allowing us to represent you',
          iconName: 'ArrowRightCircledFilled',
          highlight: true,
        },
        {
          id: '2',
          text: 'Accept our pricing and conditions',
          iconName: 'ArrowRightCircledFilled',
          highlight: true,
          subBulletList: [
            {
              id: '1',
              text: `$49.99 per month`,
              iconName: 'CircleFilled',
            },
            {
              id: '2',
              text: 'A fee equivalent to 10% of the savings generated on each bill',
              iconName: 'CircleFilled',
            },
          ],
        },
        {
          id: '1',
          text: 'Provide us your documents',
          iconName: 'ArrowRightCircledFilled',
          highlight: true,
        },
      ],
    },
  },
  ezfile: {},
};
