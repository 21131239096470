import { ComponentPropsWithoutRef } from 'react';

export const QuestionMark = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1666 15.3587H10.8333L7.12496 17.8254C6.99981 17.9088 6.85436 17.9567 6.70413 17.9639C6.55389 17.9712 6.40451 17.9375 6.27192 17.8665C6.13933 17.7955 6.02849 17.6898 5.95124 17.5608C5.874 17.4317 5.83323 17.2841 5.83329 17.1337V15.3587C3.33329 15.3587 1.66663 13.6921 1.66663 11.1921V6.19206C1.66663 3.69206 3.33329 2.02539 5.83329 2.02539H14.1666C16.6666 2.02539 18.3333 3.69206 18.3333 6.19206V11.1921C18.3333 13.6921 16.6666 15.3587 14.1666 15.3587Z"
      stroke="#2E193E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9.4668V9.2918C10 8.72513 10.35 8.42513 10.7 8.18346C11.0417 7.95013 11.3834 7.65013 11.3834 7.10013C11.3834 6.33346 10.7667 5.7168 10 5.7168C9.23337 5.7168 8.6167 6.33346 8.6167 7.10013M9.99587 11.4585H10.0042"
      stroke="#2E193E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
