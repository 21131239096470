import { Case, FieldName } from '../state/state.types';
import { validateHeapSafely } from './heap.types';

const identifyHeapUser = (case_: Case) => {
  if (!case_?.patientId) return;
  if (case_?.patientId && case_.patientId === window.heap.identity) return;

  window.heap.identify(case_.patientId);

  // We want to add user properties at the moment when user is identified
  // from emitHeapEvents we add user properties when those properties are changed.
  window.heap.addUserProperties({
    [FieldName.bill_size]: case_.fields['bill-size'],
    [FieldName.bill_frequency]: case_.fields['bill-frequency'],
    [FieldName.feeling]: case_.fields.feeling,
  });
};

export const setHeapProps = (case_: Case | undefined) => {
  if (!validateHeapSafely()) return;
  if (!case_) return;

  // According to product decision, we don't want to reset identity even
  // the customer is logged out. After logged out, the customer is still has
  // heap identity when new non-authenticated case is started in the same browser.
  // In this case heap provides identity from cookies.
  identifyHeapUser(case_);

  // it's a cheap call to set event properties.
  // So, we don't need to check if properties already set.
  // It doesn't send any request to heap.
  // The properties will be included in the all events: autocaptured and custom.
  // https://developers.heap.io/reference/addeventproperties
  window.heap.addEventProperties({
    caseId: case_.id,
    sequenceId: case_.sequenceId,
    backendSessionId: localStorage.getItem('sessionId') ?? undefined,
  });
};
