import { Navigate, RouteComponent } from '@tanstack/router';
import { errorRoute } from './router';

export const navigateToErrorRouteComponent: RouteComponent<{
  error: Error;
  info: {
    componentStack: string;
  };
}> = (content) => {
  const { error } = content;
  const stack = error.stack?.split('\n').slice(0, 2).join(',');

  return (
    <Navigate
      to={errorRoute.id}
      search={{
        message: error.message,
        stack,
      }}
    />
  );
};
