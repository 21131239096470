import { useStytch } from '@stytch/react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/router';
import { BFFErrorResponse } from '../../fetchers/fetcher.types';
import { magicLinkSentRoute } from '../../router/router';
import { navigateToErrorRoute } from '../../utils/navigateToErrorRoute';
import { DASHBOARD_ROUTES, getDashboardURL } from '@/utils/dashboardUrl';

export const useMagicLinkSending = () => {
  const navigate = useNavigate();
  const stytch = useStytch();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (formData: FormData) => {
      const email = formData.get('answer');
      if (!email || typeof email !== 'string') throw new Error('Email should be provided');

      await stytch.magicLinks.email.send(email, {
        login_magic_link_url: `${getDashboardURL()}/${DASHBOARD_ROUTES.authenticate}`,
        login_expiration_minutes: 60,
      });
    },
    onSuccess: () => {
      navigate({ to: magicLinkSentRoute.id });
    },
    onError: (error: Error | BFFErrorResponse) =>
      navigateToErrorRoute(error, 'useMagicLinkSending'),
  });

  return { mutate, isLoading };
};
