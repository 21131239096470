import { validateEnv } from '@/utils/validateEnv';
import { Anchor, Flex, Group, Header, useMantineTheme } from '@mantine/core';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { Logo } from './icons/Logo';
import {
  isDarkBackground,
  isErrorPage,
  isMilestone,
  shouldHideBackButton,
} from '@/utils/showLayoutElements';
import { BackButton } from './BackButton';
import { useParams } from '@tanstack/router';
import { useHistory } from '@/routes/hooks/useHistory';
import { ProgressBar } from './ProgressBar';
import { isLoaderProductStep } from '@/utils/stepUtils';
import { Partnership } from './Partnership';
import { usePartnership } from '@/routes/hooks/usePartnership';

export const LayoutHeader = ({ showProgressBar }: { showProgressBar: boolean }) => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();
  const { stepId = '' } = useParams();
  const caseHistory = useHistory();
  const partnership = usePartnership();

  const isDark = stepId
    ? isLoaderProductStep(stepId) || isDarkBackground(stepId)
    : isErrorPage() || false;
  const isGreen = stepId ? isMilestone(stepId) : false;

  return (
    <Header
      pos="fixed"
      height={{
        base: showProgressBar
          ? theme.other.rootHeaderWithProgressHeight.sm
          : theme.other.rootHeaderHeight.sm,
        md: showProgressBar
          ? theme.other.rootHeaderWithProgressHeight.md
          : theme.other.rootHeaderHeight.md,
      }}
      bg={
        isDark
          ? isGreen
            ? theme.colors.tertiary[0]
            : theme.colors.primary[0]
          : theme.colors.minor[1]
      }
      styles={() => ({
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
        },
      })}
    >
      {showProgressBar && <ProgressBar stepId={stepId} />}
      <Flex
        direction="row"
        w="100%"
        h="100%"
        pt={showProgressBar ? '16px' : 0}
      >
        <Flex
          direction="row"
          align="center"
          px={isMdBreakpoint ? 50 : 25}
          pt={0}
          style={{
            flex: 1,
          }}
        >
          {stepId && (
            <Group position="apart">{!shouldHideBackButton(stepId) && <BackButton />}</Group>
          )}
        </Flex>
        <Flex
          direction="row"
          align="center"
          justify="center"
          style={{
            flex: 1,
          }}
        >
          {caseHistory?.includes('validation') ? (
            <Logo
              variant={isDark ? (isGreen ? 'tertiary-light' : 'lightLavender') : 'lavender'}
              height={isMdBreakpoint ? 18 : 12}
            />
          ) : (
            <Anchor
              href={validateEnv().VITE_CORP_URL}
              display="flex"
            >
              <Logo
                variant={isDark ? (isGreen ? 'tertiary-light' : 'lightLavender') : 'lavender'}
                height={isMdBreakpoint ? 18 : 12}
              />
            </Anchor>
          )}
          {partnership && <Partnership stepId={stepId} />}
        </Flex>
        <Flex
          direction="row"
          align="center"
          justify="end"
          px={isMdBreakpoint ? 50 : 25}
          style={{
            flex: 1,
          }}
        />
      </Flex>
    </Header>
  );
};
