import { useParams } from '@tanstack/router';
import { useFields } from './useFields';
import { isCheckoutStep } from '@/utils/stepUtils';
import { useNoDepositPartnership } from './useNoDepositPartnership';

export const useCompletedCheckout = () => {
  const { stepId = '' } = useParams();
  const fields = useFields();
  const isHipaaSigned = fields?.['hipaa-signed'];
  const hasActivatedServices = fields?.['activated-services'] === 'yes';
  const isPaid = !!fields?.['deposit-payment-id'] || !!fields?.['subscription-payment-id'];
  const isTCSigned = fields?.agreement;
  const isNoDeposit = useNoDepositPartnership();
  const isCheckoutComplete =
    !!isHipaaSigned && !!isTCSigned && (isNoDeposit ? !!hasActivatedServices : !!isPaid);
  const isCompletedCheckoutStep = isCheckoutStep(stepId) && isCheckoutComplete;

  return { isCompletedCheckoutStep, isCheckoutComplete };
};
