import { clearCaseStateAndLocalStorage } from '@/utils/clearCaseStateAndLocalStorage';
import { DASHBOARD_ROUTES, getDashboardURL } from '@/utils/dashboardUrl';
import { validateEnv } from '@/utils/validateEnv';
import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { stytch } from '../auth/stytch';
import { CaseState, Sequence } from '../state/state.types';

export const SEQUENCE_ID = 'ad054d0c-27c7-4253-afae-47669e642cad';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const fetchSequence = async () => {
  const result = await fetchJSON<Sequence>(`/sequence/${SEQUENCE_ID}`);

  return result;
};

export const fetchCaseState = async () => {
  const result = await fetchJSON<CaseState[]>('/intake', {
    method: 'GET',
  });

  return result[0] ?? null;
};

export const createCase = async (sequenceId?: string, partnership?: string) => {
  if (!sequenceId) {
    throw new Error('sequenceId is required');
  }

  return await fetchJSON<CaseState>('/intake', {
    method: 'POST',
    data: {
      sequenceId,
      partnership,
    },
  });
};

export const authenticateOauth = async (token: string) => {
  if (!token) {
    throw new Error('token is required');
  }

  const stytchResponse = await stytch.oauth.authenticate(token, {
    session_duration_minutes: 10080,
  });
  if (stytchResponse.status_code == 200) {
    return {
      session_token: stytchResponse.session_token,
      jwt_token: stytchResponse.session_jwt,
      first_name: stytchResponse.user.name.first_name,
      last_name: stytchResponse.user.name.last_name,
      email: stytchResponse.user.emails[0],
    };
  } else {
    console.error(stytchResponse.status_code);
    throw new Error("Couldn't verify SSO token.");
  }
};

export type FetchCallback<T> = (url: string, options?: AxiosRequestConfig) => Promise<T>;

export const fetchJSON = async <T>(url: string, options?: AxiosRequestConfig): Promise<T> => {
  options = addHeaderToOptions({
    key: 'x-request-api-version',
    options,
    value: '5',
  });
  const sessionId = localStorage.getItem('sessionId');
  options = addHeaderToOptions({
    key: 'x-session-id',
    options,
    value: sessionId,
  });

  const authSessionToken = stytch.session.getTokens()?.session_token;
  options = addHeaderToOptions({
    options,
    key: 'Authorization',
    value: authSessionToken && `Bearer ${authSessionToken}`,
  });

  return await axios({
    url: `${validateEnv().VITE_API}${url}`,
    withCredentials: true,
    transitional: {
      silentJSONParsing: false, // throw SyntaxError if JSON parsing failed
    },
    ...options,
  })
    .then((response) => {
      const responseSessionId = response.headers['x-session-id'];
      if (responseSessionId) {
        localStorage.setItem('sessionId', responseSessionId);
      }

      return response.data;
    })
    .catch(async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          await clearCaseStateAndLocalStorage();
          window.location.replace(`${getDashboardURL()}/${DASHBOARD_ROUTES.signIn}`);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data.error);
        }

        throw new Error(
          `Status code is ${error.response.status}. Error: ${JSON.stringify(
            error.toJSON(),
            null,
            2,
          )}`,
        );
      } else if (error.request) {
        throw new Error(
          `Failed to receive a response from the server: ${JSON.stringify(
            {
              url: error.config.url,
              timestamp: Date().toLocaleString(),
              ...options,
            },
            null,
            2,
          )}`,
        );
      } else {
        throw new Error(`Unexpected error: ${JSON.stringify(error.toJSON(), null, 2)}`);
      }
    });
};

const addHeaderToOptions = ({
  key,
  options,
  value,
}: {
  key: string;
  options?: AxiosRequestConfig;
  value?: string | null;
}) => {
  if (!value) return options;

  options = {
    ...options,
    headers: {
      ...options?.headers,
      [`${key}`]: value,
    },
  };
  return options;
};
