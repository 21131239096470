import { Title, TitleProps } from '@mantine/core';

export const StepHeader = ({
  isInverted,
  order,
  size,
  ...rest
}: TitleProps & { isInverted?: boolean }) => {
  return (
    <Title
      order={order ?? 2}
      size={size ?? 'h3'}
      align="left"
      c={isInverted ? 'minor.1' : 'primary.0'}
      {...rest}
    />
  );
};
