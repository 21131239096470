import { StepHeader } from '@/components/StepHeader';
import { getStepContent } from '@/utils/getStepContent';
import { getStepName } from '@/utils/stepUtils';
import { Flex, MantineTheme, Stack, Text, Textarea, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/router';
import { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';
import { CheckAndDisplaySalesBanner } from '../components/CheckAndDisplaySalesBanner';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { StepSubtitle } from '../components/StepSubtitle';
import { StepTitle } from '../components/StepTitle';
import { choiceAndOpenStepContent } from '../content/choiceAndOpenStepContent';
import { openStepRoute } from '../router/router';
import { FieldName, isStepNameGuard, Step } from '../state/state.types';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useOpenStepResponse } from './hooks/useOpenStepResponse';
import { ContentContainer } from '@/components/ContentContainer';
import { WhyDoWeAsk } from '@/components/WhyDoWeAsk';
import { whyActivateContent } from '@/content/whyActivateContent';
import { Input } from '@/components/ui/Input';
import { SingleSelectButton } from '@/components/ui/SingleSelectButton';

// NOTE: current term open question means that we have **only one** field in the form
// and we post **only one** value to the backend
const OpenStep = () => {
  const [customValue, setCustomValue] = useState('');
  const isMdBreakpoint = useMdBreakpoint();
  const openStepField = z.object({
    answer: z
      .string()
      .refine(
        (value) => {
          if (!step?.optional && !value) return false;

          return true;
        },
        { message: 'This field is required.' },
      )
      .transform((value) => {
        if (field?.inputTypeAttribute === 'number') {
          const transformedValues = parseInt(value.replace(/[^0-9-]/g, ''));
          return transformedValues;
        }

        return value;
      }),
  });
  const form = useForm<{ answer: string }>({
    validate: zodResolver(openStepField),
    validateInputOnBlur: true,
    initialValues: {
      answer: '',
    },
  });

  const { stepId } = useParams({ from: openStepRoute.id });
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });

  const formTransformer = () => {
    const validatedValues = openStepField.parse(form.values);
    const formData = new FormData();
    formData.append('stepId', stepId);
    formData.append('answer', `${validatedValues['answer']}`);

    return formData;
  };

  const { onFormSubmit, isLoading, mutate } = useCaseUpdater({
    formTransformer,
  });

  const stepName = getStepName(stepId);

  const openStepFieldList = useMemo(() => {
    const content =
      stepName && isStepNameGuard(stepName) ? getStepContent(stepId, choiceAndOpenStepContent) : {};

    return content?.field?.name ? [{ name: content.field.name, destinationName: `answer` }] : [];
  }, [stepName, stepId]);

  useOpenStepResponse(openStepFieldList, form.setFieldValue);

  if (!stepId || !stepName) throw new Error('StepId or stepName should be defined');

  const { title, subtitle, field, header } = getStepContent(stepId, choiceAndOpenStepContent);

  const { title: whyActivateTitle, description: whyActivateDescription } =
    whyActivateContent?.[stepId] ?? {};

  const isTextArea = field?.inputElement === 'textarea';
  const isNumeric = field?.inputTypeAttribute === 'number';

  const isHouseholdSize = stepId === FieldName.household_size;
  const isMedicalProviderName = stepId === FieldName.medical_provider_name;

  const stackWidth = isTextArea
    ? 644
    : isMedicalProviderName
    ? 516
    : isHouseholdSize
    ? 240
    : 'auto';

  useEffect(() => {
    if (isHouseholdSize && form.values.answer && !customValue) {
      const numericValue = Number(form.values.answer);
      setCustomValue(numericValue > 6 ? '7' : form.values.answer);
    }
  }, [isHouseholdSize, form.values.answer, customValue]);

  return (
    <Flex
      direction="column"
      h="100%"
    >
      {!!header && <StepHeader>{header}</StepHeader>}
      {!!title &&
        (typeof title === 'string' ? (
          <StepTitle fz={{ base: 28, md: 32 }}>{title}</StepTitle>
        ) : (
          title()
        ))}
      {!!subtitle && <StepSubtitle>{subtitle}</StepSubtitle>}
      <ContentContainer>
        {isHouseholdSize && (
          <Flex
            mt={24}
            direction="column"
            gap={36}
            justify="center"
          >
            <Flex
              justify="center"
              align="center"
              gap={10}
              direction={isMdBreakpoint ? 'row' : 'column'}
            >
              {Array.from({ length: 7 }, (_, i) => i + 1).map((value) => (
                <Flex
                  key={value}
                  style={{
                    width: 64,
                    height: 64,
                  }}
                >
                  <SingleSelectButton
                    uniqueKey={`${value}`}
                    isLoading={isLoading}
                    value={`${value}`}
                    onClick={(val, event) => {
                      if (val !== '7') {
                        event?.preventDefault();
                        setCustomValue(val);
                        form.setFieldValue('answer', val);
                        const validatedValues = openStepField.parse({
                          answer: val,
                        });
                        const formData = new FormData();
                        formData.append('stepId', stepId);
                        formData.append('answer', `${validatedValues['answer']}`);

                        mutate(formData);
                        return;
                      } else {
                        form.setFieldValue('answer', '');
                      }
                      setCustomValue(val);
                    }}
                    isRow
                    answer={customValue}
                    label={`${value}${value === 7 ? '+' : ''}`}
                    variant="withIcons"
                  />
                </Flex>
              ))}
            </Flex>
            {customValue === '7' && (
              <StepSubtitle>
                Please enter the amount of people in your household below.
              </StepSubtitle>
            )}
          </Flex>
        )}
        <StepForm onSubmit={onFormSubmit}>
          <input
            name="stepId"
            type="hidden"
            value={stepId}
          />
          <Flex direction="column">
            {!!field && (
              <Stack
                spacing={4}
                sx={{ flexGrow: 1 }}
                style={{
                  maxWidth: stackWidth,
                  minWidth: isMdBreakpoint ? stackWidth : 'auto',
                  margin:
                    isTextArea || isHouseholdSize || isMedicalProviderName ? '0 auto' : 'none',
                }}
              >
                {isTextArea ? (
                  <Textarea
                    name={field.name}
                    disabled={isLoading}
                    label={field.label}
                    labelProps={{ fz: 'sm', fw: '600', c: 'primary.0', mb: 4 }}
                    autosize
                    minRows={3}
                    mt={{ base: 16, md: 24 }}
                    styles={(theme) => ({
                      root: {
                        flex: 1,
                      },
                      input: {
                        borderColor: form.errors[field.name]
                          ? `${theme.colors.error[5]} !important`
                          : theme.colors.primary[1],
                        borderWidth: 2,
                        fontSize: theme.fontSizes.md,
                        height: 64,
                        color: `${theme.colors.primary[0]} !important`,
                        backgroundColor: theme.colors.minor[1],
                        padding: 16,
                        borderRadius: 8,

                        '&:focus': {
                          borderColor: form.errors[field.name]
                            ? 'inherit'
                            : theme.colors.primary[0],
                        },

                        '&::placeholder': {
                          color: '#2e193e4d !important',
                        },
                      },
                      error: {
                        fontSize: theme.fontSizes.sm,
                        color: theme.colors.error[5],
                        fontWeight: 600,
                      },
                    })}
                    placeholder={field.placeholder}
                    required={!step?.optional}
                    {...form.getInputProps('answer')}
                  />
                ) : isNumeric ? (
                  isHouseholdSize && customValue !== '7' ? null : (
                    <NumericFormat
                      name={field.name}
                      thousandSeparator=","
                      prefix={field.prefix}
                      decimalScale={0}
                      placeholder={field.placeholder}
                      label={field.label}
                      labelProps={{ fz: 'sm', fw: '600', c: 'primary.0', mb: 4 }}
                      customInput={TextInput}
                      mt={{ base: 16, md: 24 }}
                      styles={(theme: MantineTheme) => ({
                        root: {
                          flex: 1,
                        },
                        input: {
                          borderColor: form.errors[field.name]
                            ? `${theme.colors.error[5]} !important`
                            : theme.colors.primary[1],
                          borderWidth: 2,
                          fontSize: theme.fontSizes.md,
                          height: 64,
                          color: `${theme.colors.primary[0]} !important`,
                          backgroundColor: theme.colors.minor[1],
                          padding: 16,
                          borderRadius: 8,

                          '&:focus': {
                            borderColor: form.errors[field.name]
                              ? 'inherit'
                              : theme.colors.primary[0],
                          },

                          '&::placeholder': {
                            color: '#2e193e4d !important',
                          },
                        },
                        error: {
                          fontSize: theme.fontSizes.sm,
                          color: theme.colors.error[5],
                          fontWeight: 600,
                        },
                      })}
                      {...form.getInputProps('answer')}
                    />
                  )
                ) : (
                  <Input
                    type={field.inputTypeAttribute ?? 'text'}
                    name={field.name}
                    isLoading={isLoading}
                    placeholder={field.placeholder ?? ''}
                    inputProps={form.getInputProps('answer')}
                    error={form.errors['answer']}
                  />
                )}
                {!!field.helperText && step?.optional && (
                  <Text
                    size={14}
                    align="left"
                    c="primary.1"
                    weight="normal"
                    style={{
                      margin: '4px auto 0 auto',
                    }}
                  >
                    {field.helperText}
                  </Text>
                )}
              </Stack>
            )}

            {(!isHouseholdSize || (isHouseholdSize && customValue === '7')) && (
              <PrimaryButton
                isLoading={isLoading}
                isDisabled={!form.isValid('answer')}
                mt={24}
                align={
                  isMdBreakpoint ? (field?.inputElement === 'textarea' ? 'end' : 'start') : 'center'
                }
                mx="auto"
                maw={343}
                w="100%"
              >
                Continue
              </PrimaryButton>
            )}
          </Flex>
        </StepForm>
        {CheckAndDisplaySalesBanner(stepId)}
      </ContentContainer>
      {whyActivateTitle && whyActivateDescription && (
        <Flex
          align="center"
          justify="center"
          mt="auto"
        >
          <WhyDoWeAsk
            title={whyActivateTitle}
            description={whyActivateDescription}
            stepId={stepId}
          />
        </Flex>
      )}
    </Flex>
  );
};

export { OpenStep };
