import { Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithoutRef } from 'react';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';

export type SecondaryButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonProps & {
    align?: 'start' | 'center' | 'end';
  };

export const SecondaryButton = (props: SecondaryButtonProps) => {
  const isMdBreakpoint = useMdBreakpoint();
  const { align, ...rest } = props;

  return (
    <Button
      type="submit"
      variant="outline"
      w={{ base: '100%', sm: 'auto', md: 'auto' }}
      size="md"
      radius="3.125rem"
      loaderPosition="left"
      loaderProps={{
        color: 'tertiary.1',
        size: 16,
      }}
      fw={700}
      styles={(theme) => ({
        root: {
          alignSelf: align ? align : isMdBreakpoint ? 'end' : 'center',
          border: `1px solid ${theme.colors.primary[0]}`,
          color: theme.colors.primary[0],
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: theme.colors.tertiary[0],
            color: theme.colors.minor[1],
            border: `1px solid ${theme.colors.tertiary[0]}`,
          },

          // override Mantine overlay style
          '&[data-loading]::before': {
            backgroundColor: 'transparent',
          },

          '&[data-loading]': {
            backgroundColor: theme.colors.tertiary[0],
            color: theme.colors.minor[1],
            border: `1px solid ${theme.colors.tertiary[0]}`,
          },
        },
        label: {
          color: 'inherit',
        },
      })}
      {...rest}
    />
  );
};
