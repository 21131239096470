import { FAQs } from '@/components/FAQs';
import { HelpBanner } from '@/components/HelpBanner';
import { PrimaryButton } from '@/components/PrimaryButton';
import { StepHeader } from '@/components/StepHeader';
import { TCCard } from '@/components/TCCard';
import { FAQItems } from '@/content/faqsContent';
import { urlScheme } from '@/router/router.types';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { shouldShowHelpBanner } from '@/utils/showLayoutElements';
import { Anchor, Card, Flex, Stack, Text, useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from '@tanstack/router';
import { CheckoutCard } from '../components/CheckoutCard';
import { PaymentFormWithTC } from '../components/PaymentFormWithTC';
import { StepSubtitle } from '../components/StepSubtitle';
import { StepTitle } from '../components/StepTitle';
import {
  checkoutContent,
  hipaaCardContent,
  partnershipContent,
  paymentCardContent,
} from '../content/checkoutContent';
import { Amount } from '../content/stepContent.types';
import { partialStepRoute, redirectToDashboardRoute } from '../router/router';
import { CheckoutComplete, Product, Step } from '../state/state.types';
import { useBillSize } from './hooks/useBillSize';
import { useCasePatcher } from './hooks/useCasePatcher';
import { useFields } from './hooks/useFields';
import { usePartnership } from './hooks/usePartnership';
import { ContentContainer } from '@/components/ContentContainer';
import { useEffect, useState } from 'react';
import { useCompletedCheckout } from './hooks/useCompletedCheckout';
import { useNoDepositPartnership } from './hooks/useNoDepositPartnership';

export const Checkout = () => {
  const isMdBreakpoint = useMdBreakpoint();
  const theme = useMantineTheme();
  const { stepId } = useParams({ from: partialStepRoute.id });
  const partnership = usePartnership();
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });
  const navigate = useNavigate();
  const { billSizeUnder15k } = useBillSize();
  const [isHIPPAAButtonLoading, setIsHIPPAAButtonLoadingIs] = useState(false);

  const { submit } = useCasePatcher({});

  const { isCompletedCheckoutStep } = useCompletedCheckout();

  const fields = useFields();
  const isHipaaSigned = fields?.['hipaa-signed'];
  const isPaid = !!fields?.['deposit-payment-id'] || !!fields?.['subscription-payment-id'];
  const isTCSigned = fields?.agreement;
  const hasActivatedServices = fields?.['activated-services'] === 'yes';
  const isNoDeposit = useNoDepositPartnership();
  const isCheckoutComplete = isCompletedCheckoutStep;

  const { header, title, description } =
    checkoutContent[CheckoutComplete.parse(isCheckoutComplete)];

  if (!stepId) throw new Error('StepId should be define at checkout step');

  if (!step) throw new Error('Step should be defined at checkout page');

  const product = Product.parse(fields?.product);
  const amount = Amount.parse(step.properties?.amount);
  const faq = FAQItems[product];

  useEffect(() => {
    if (isCheckoutComplete) {
      navigate({ to: redirectToDashboardRoute.id });
    }
  }, [isCheckoutComplete, navigate]);

  return (
    <>
      <Flex
        direction="column"
        align="center"
      >
        {!!header && (
          <StepHeader
            align="center"
            fz={34}
            c="primary.0"
          >
            {header}
          </StepHeader>
        )}

        {!!title &&
          (typeof title === 'string' ? (
            <StepSubtitle
              mb="xl"
              ta="center"
              w={350}
              mt={16}
            >
              {title}
            </StepSubtitle>
          ) : (
            title()
          ))}

        {description && (
          <Text
            ta="left"
            c="primary.0"
            mt={8}
            px={{ base: 4, sm: 0 }}
          >
            {description}
          </Text>
        )}

        <ContentContainer>
          <Stack
            spacing={12}
            mt={24}
            w="100%"
          >
            <Card
              bg="minor.0"
              radius={isMdBreakpoint ? 4 : 12}
              w="100%"
              px={20}
              pb={20}
            >
              <CheckoutCard
                content={hipaaCardContent[product]}
                completeText={isHipaaSigned ? 'HIPAA authorization signed' : undefined}
                step={step}
              />
              {!isHipaaSigned && (
                <Anchor
                  href={
                    !isHipaaSigned
                      ? urlScheme.parse(step.properties?.signURL, {
                          errorMap: () => {
                            return { message: 'signURL should be an URL' };
                          },
                        })
                      : undefined
                  }
                  onClick={() => {
                    if (!isHipaaSigned) {
                      setIsHIPPAAButtonLoadingIs(true);
                    }
                  }}
                >
                  <PrimaryButton
                    size="sm"
                    mt={20}
                    isLoading={isHIPPAAButtonLoading}
                  >
                    Review and sign
                  </PrimaryButton>
                </Anchor>
              )}
            </Card>
            <Card
              bg="minor.0"
              radius={isMdBreakpoint ? 4 : 12}
              w="100%"
              px={20}
              pt={12}
              pb={20}
            >
              <TCCard step={step} />
            </Card>
            <Card
              bg="minor.0"
              radius={isMdBreakpoint ? 4 : 12}
              w="100%"
              px={20}
              pt={12}
            >
              {!isNoDeposit ? (
                <>
                  <CheckoutCard
                    content={paymentCardContent[product][amount]}
                    completeText={isPaid ? 'Payment completed' : undefined}
                    step={step}
                  />
                  {!isPaid && <PaymentFormWithTC step={step} />}
                </>
              ) : (
                <Flex direction="column">
                  <CheckoutCard
                    content={partnershipContent(partnership ?? '', hasActivatedServices)}
                    step={step}
                  />
                  {!hasActivatedServices && (
                    <PrimaryButton
                      onClick={() => {
                        const formData = new FormData();
                        formData.append('activated-services', 'yes');
                        submit(formData);
                      }}
                      disabled={!(isHipaaSigned && isTCSigned)}
                      mt={30}
                      mb={10}
                      size="sm"
                      w="auto"
                      sx={{ alignSelf: 'flex-start' }}
                    >
                      Activate Resolve Services
                    </PrimaryButton>
                  )}
                </Flex>
              )}
            </Card>
          </Stack>

          {shouldShowHelpBanner(stepId) && !billSizeUnder15k && (
            <HelpBanner
              stepId={stepId}
              mt={24}
              w="100%"
              direction={{ base: 'column', sm: 'row', md: 'row' }}
              title={
                isHipaaSigned && isTCSigned
                  ? 'Still have questions? Talk to a live person.'
                  : undefined
              }
              description={isHipaaSigned && isTCSigned ? null : undefined}
            />
          )}
        </ContentContainer>
      </Flex>

      {!!faq && faq.length && (
        <ContentContainer>
          <Stack
            spacing={0}
            mt={36}
          >
            <StepTitle
              ta="center"
              id="faq"
            >
              Frequently Asked Questions
            </StepTitle>

            <FAQs
              items={faq}
              backgroundColor={theme.colors.minor[1]}
            />
          </Stack>
        </ContentContainer>
      )}
    </>
  );
};
