import { validateHeapSafely } from '@/metrics/heap.types';
import { bookAppointmentRoute } from '@/router/router';
import { useCasePatcher } from '@/routes/hooks/useCasePatcher';
import { Anchor, Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithoutRef, SyntheticEvent } from 'react';

export type RedirectToBookAppointmentButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonProps & {
    appointmentUrl: string;
    stepId: string;
    shouldOpenCallingApp: boolean;
  };
export const RedirectToBookAppointmentButton = ({
  appointmentUrl,
  stepId,
  shouldOpenCallingApp,
  ...buttonProps
}: RedirectToBookAppointmentButtonProps) => {
  const { isLoading, submit } = useCasePatcher({
    search: {
      appointmentUrl,
    },
    to: !shouldOpenCallingApp ? bookAppointmentRoute.id : undefined,
  });

  const handleOnClick = (event: SyntheticEvent<HTMLFormElement | HTMLButtonElement>) => {
    if (!shouldOpenCallingApp) {
      event.preventDefault();
    }

    if (validateHeapSafely()) {
      if (shouldOpenCallingApp) {
        window.heap?.track('call-877-click-event', {
          stepId,
        });
      } else {
        window.heap?.track('redirect-to-calendly-click-event', {
          stepId,
        });
      }
    }

    const formData = new FormData();
    formData.append('talk-to-an-advisor-click', stepId);
    submit(formData);
  };

  if (!stepId) return null;

  const ButtonElement = (
    <Button
      type={shouldOpenCallingApp ? 'button' : 'submit'}
      onClick={handleOnClick}
      loading={isLoading}
      sx={{
        root: {
          backgroundColor: 'transparent',
          border: 'none',
        },
      }}
      p={0}
      {...buttonProps}
    />
  );

  return (
    <form
      onSubmit={handleOnClick}
      id="redirect-to-book-appointment"
    >
      <input
        name="talk-to-an-advisor-click"
        type="hidden"
        value={stepId}
      />
      {shouldOpenCallingApp ? (
        <Anchor href="tel:8772454244">{ButtonElement}</Anchor>
      ) : (
        <> {ButtonElement} </>
      )}
    </form>
  );
};
