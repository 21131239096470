import { StepRecordContent } from '@/content/stepContent.types';
import { Branch } from '@/state/state.types';
import { getBranches, getStepName } from './stepUtils';

export const getStepContent = <T>(stepId: string, contentSource: StepRecordContent<T>) => {
  const stepName = getStepName(stepId);

  if (!stepName) throw new Error('step name should be defined');

  const content = [...getBranches(stepId), Branch.default]
    .map((branch) => {
      return contentSource[stepName]?.[branch];
    })
    .filter(Boolean)[0];

  if (!content) throw new Error(`No step content found for particular stepId ${stepId} and branch`);

  return content;
};
