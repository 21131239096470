import { Checkbox as MantineCheckbox, Group, Flex, useMantineTheme } from '@mantine/core';
import { Checkmark } from '../icons/Checkmark';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { ReactNode } from 'react';

export type CheckboxProps = {
  uniqueKey: string;
  isDisabed: boolean;
  value: string;
  label: ReactNode;
  isSelected: boolean;
};

export const Checkbox = ({ uniqueKey, isDisabed, value, label, isSelected }: CheckboxProps) => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();

  return (
    <MantineCheckbox
      key={uniqueKey}
      label={
        <Group
          position="apart"
          noWrap
        >
          {label}
          <Flex
            w={24}
            h={24}
            style={{
              borderRadius: 4,
              background: isSelected ? 'white' : '#9788C01A',
              border: `2px solid ${theme.colors.primary[1]}`,
            }}
          >
            {isSelected && (
              <Checkmark
                width={20}
                height={20}
                color={theme.colors.primary[1]}
                style={{ rotate: '5deg' }}
              />
            )}
          </Flex>
        </Group>
      }
      icon={() => null}
      disabled={isDisabed}
      name={value}
      value={value}
      display="flex"
      h="auto"
      mih={isMdBreakpoint ? 64 : 56}
      styles={(theme) => ({
        root: {
          border: `2px solid ${theme.colors.primary[1]}`,
          borderRadius: 8,
          backgroundColor: isSelected ? theme.colors.primary[1] : 'white',
          transition: 'background 250ms linear',
          opacity: isDisabed ? 0.5 : 1,

          '&:hover': {
            backgroundColor: isDisabed
              ? 'inherit'
              : isSelected
              ? theme.colors.primary[1]
              : '#EFEAFC',
          },
        },
        label: {
          whiteSpace: 'normal',
          cursor: 'pointer',
          fontSize: theme.fontSizes.md,
          fontWeight: 700,
          color: isSelected ? 'white' : theme.colors.primary[1],
          userSelect: 'none',
          lineHeight: theme.lineHeight,
          padding: 16,

          '&[data-disabled]': {
            color: `${theme.colors.primary[1]} !important`,
          },
        },
        inner: {
          width: 0,
          height: 0,
          display: 'none',
        },
        body: {
          height: '100%',
          width: '100%',
        },
        labelWrapper: {
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        },
      })}
    />
  );
};
