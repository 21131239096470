import { Divider, Flex, Group, Text, useMantineTheme } from '@mantine/core';

import { useFields } from '@/routes/hooks/useFields';
import { BillSize } from '@/state/state.types';
import { RedirectToBookAppointmentButton } from './RedirectToBookAppointmentButton';
import { ArrowDownward } from './icons/ArrowDownward';
import { Calendar } from './icons/Calendar';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

interface SalesBannerProps {
  stepId: string;
}
export const SalesBanner = ({ stepId }: SalesBannerProps) => {
  const theme = useMantineTheme();
  const fields = useFields();
  const hasScheduledCall = fields?.['offer-consult'];
  const billSize = BillSize.parse(fields?.['bill-size']);
  const isBillSizeOver15k = [
    BillSize.Enum['15k-50k'],
    BillSize.Enum['50k-250k'],
    BillSize.Enum['more-than-250k'],
  ].includes(billSize);

  const consultationLink = useConsultationLink();

  return (
    <>
      <Flex
        align="center"
        h={25}
        mt={20}
      >
        <Divider
          color="primary.2"
          w="100%"
          mx="auto"
        />
      </Flex>
      <Flex
        justify="center"
        align="center"
        direction={{ base: 'column', md: 'row' }}
        my={10}
      >
        <Flex
          align="center"
          px={20}
        >
          <Text
            c="primary.0"
            fz="m"
            align="center"
          >
            Need help? Select the chat icon on the bottom right of your screen to chat with an
            expert!&nbsp;&nbsp;&nbsp;
            <ArrowDownward color={theme.colors.tertiary[1]} />
          </Text>
        </Flex>
      </Flex>
      {hasScheduledCall !== 'yes' && (
        <>
          <Flex
            align="center"
            h={25}
          >
            <Divider
              color="primary.2"
              w="100%"
              mx="auto"
            />
            <Text
              c="primary.0"
              fz="m"
              mx={10}
            >
              or
            </Text>
            <Divider
              color="primary.2"
              w="100%"
              mx="auto"
            />
          </Flex>
          <Flex
            align="center"
            justify="center"
            mt={10}
          >
            <RedirectToBookAppointmentButton
              appointmentUrl={consultationLink}
              stepId={stepId}
              shouldOpenCallingApp={!isBillSizeOver15k}
              radius="3.125rem"
              px={20}
              py={8}
              styles={(theme) => ({
                root: {
                  border: `1px solid ${theme.colors.primary[0]}`,
                },
              })}
            >
              <Group
                spacing={7}
                align="center"
              >
                <Calendar color="black" />
                <Text
                  c="primary.0"
                  fw={700}
                  fz="sm"
                >
                  Schedule a free consult
                </Text>
              </Group>
            </RedirectToBookAppointmentButton>
          </Flex>
        </>
      )}
    </>
  );
};
