import { validateEnv } from '@/utils/validateEnv';
import { responseCreateAccount } from '../state/state.types';
import { isBFFErrorResponseGuard } from './fetcher.types';

export const createAccount = async (formData: FormData) => {
  const formObject = Object.fromEntries(formData);
  const response = await fetch(`${validateEnv().VITE_API}/users`, {
    method: 'POST',
    body: JSON.stringify({ email: formObject.answer }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const json = await response.json();
    if (isBFFErrorResponseGuard(json)) {
      throw json;
    } else {
      throw new Error(JSON.stringify(json));
    }
  }

  const data = await response.json();

  const result = responseCreateAccount.parse(data);

  return result;
};
