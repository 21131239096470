import { CheckAndDisplaySalesBanner } from '@/components/CheckAndDisplaySalesBanner';

import { getStepContent } from '@/utils/getStepContent';
import { getStepName } from '@/utils/stepUtils';
import { Center, Flex, Group, Loader, SimpleGrid, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { StepForm } from '../components/StepForm';
import { StepSubtitle } from '../components/StepSubtitle';
import { StepTitle } from '../components/StepTitle';
import { choiceAndOpenStepContent } from '../content/choiceAndOpenStepContent';
import { bookAppointmentRoute } from '../router/router';
import { Step, StepName } from '../state/state.types';
import { useCaseUpdater } from './hooks/useCaseUpdater';

import { SalesAvatars } from '@/components/SalesAvatars';
import { validateHeapSafely } from '@/metrics/heap.types';
import { updateCase } from '@/state/updateCase';
import { FinancialAid } from './FinancialAid';
import { useCase } from './hooks/useCase';
import { useCasePatcher } from './hooks/useCasePatcher';
import { ContentContainer } from '@/components/ContentContainer';
import { whyActivateContent } from '@/content/whyActivateContent';
import { WhyDoWeAsk } from '@/components/WhyDoWeAsk';
import { SingleSelectButton } from '@/components/ui/SingleSelectButton';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

function calculateFederalPovertyLevel(householdSize: number) {
  return (9680 + 5380 * householdSize) * 3;
}

const AboveFpl = () => {
  const stepId = 'above-fpl';
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });

  const { onFormSubmit, isLoading, data } = useCaseUpdater({});

  const isSequenceFinished = data?.remaining === 0;

  const stepName = getStepName(stepId);

  const [answer, setAnswer] = useState<string>('');

  const consultationLink = useConsultationLink();

  if (!stepId || !stepName) throw new Error('StepId and stepName should be defined');

  const householdSize = useCase()?.fields['household-size'] as number;
  const federalPovertyLevel = calculateFederalPovertyLevel(householdSize);
  const formattedFpl = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
  }).format(federalPovertyLevel);

  const { title, subtitle, choiceOrientation } = getStepContent(stepId, choiceAndOpenStepContent);

  const isOfferConsult = stepName === StepName.offer_consult;
  const isFinancialAid = stepName === StepName.financial_aid;

  const cols = () => {
    if (stepName === StepName.stress_level) return 5;
    return 1;
  };

  const { submit } = useCasePatcher({
    search: {
      appointmentUrl: consultationLink,
    },
    to: bookAppointmentRoute.id,
  });

  const redirectToBookAppointment = () => {
    if (validateHeapSafely()) {
      window.heap?.track('offer-consult-redirect-to-calendly-event', {
        stepId,
      });
    }

    const formData = new FormData();
    formData.append('talk-to-an-advisor-click', stepId);
    submit(formData);
  };

  if (isFinancialAid) {
    return <FinancialAid />;
  }

  const { title: whyActivateTitle, description: whyActivateDescription } =
    whyActivateContent?.[stepId] ?? {};

  return (
    <>
      {isSequenceFinished ? (
        <Center mt="xl">
          <Stack
            spacing={24}
            align="center"
          >
            <StepTitle>Please wait, you will be redirected to the site shortly</StepTitle>
            <Loader
              size="xl"
              color="primary.0"
            />
          </Stack>
        </Center>
      ) : (
        <Flex
          direction="column"
          h="100%"
        >
          {!!title && typeof title === 'function' && title({ amount: formattedFpl })}
          {!!subtitle && <StepSubtitle>{subtitle}</StepSubtitle>}
          <ContentContainer>
            <StepForm
              onSubmit={async (e) => {
                if (isOfferConsult && answer === 'yes') {
                  e.preventDefault();
                  const form = new FormData(e.currentTarget);
                  await updateCase(form);
                  redirectToBookAppointment();
                } else {
                  onFormSubmit(e);
                }
              }}
            >
              {isOfferConsult && (
                <Group
                  spacing={4}
                  mb={20}
                >
                  <SalesAvatars />
                </Group>
              )}
              <input
                name="stepId"
                type="hidden"
                value={stepId}
              />
              <input
                name="answer"
                type="hidden"
                value={answer}
              />
              <SimpleGrid
                mt={24}
                cols={cols()}
                spacing="sm"
                verticalSpacing="sm"
              >
                {step?.choices?.map((choice, index) => (
                  <SingleSelectButton
                    key={index}
                    uniqueKey={choice.id || `${index}`}
                    isLoading={isLoading}
                    value={choice.id}
                    onClick={(value) => {
                      setAnswer(value);
                    }}
                    isRow={choiceOrientation === 'row'}
                    answer={answer}
                    label={choice.display}
                  />
                ))}
              </SimpleGrid>
            </StepForm>
            {CheckAndDisplaySalesBanner(stepId)}
          </ContentContainer>
          {whyActivateTitle && whyActivateDescription && (
            <Flex
              align="center"
              justify="center"
              mt="auto"
            >
              <WhyDoWeAsk
                title={whyActivateTitle}
                description={whyActivateDescription}
                stepId={stepId}
              />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export { AboveFpl };
