import { ArchetypeAndStressLevel } from '@/state/state.types';
import { BulletItem, StepContent, StepRecordContentByDependency } from './stepContent.types';

const bulletList: BulletItem[] = [
  {
    id: '1',
    text: 'Let’s learn more about your situation to see how we can help',
    iconName: 1,
    highlight: true,
  },
  {
    id: '2',
    text: 'We’ll walk you through your options',
    iconName: 2,
    highlight: true,
  },
  {
    id: '3',
    text: 'We’ll lower your bills or our service is free. Guaranteed.',
    iconName: 3,
    highlight: true,
  },
];
const bulletListTitle = 'Let’s build a custom plan to lower your bills.';

const delegator: StepContent = {
  title: "Medical costs are out of control. But we're experts at fighting back.",
  bulletListTitle,
  bulletList,
};

const delegatorStressed: StepContent = {
  title: 'Out of control medical costs can be overwhelming. We can take over.',
  description:
    "Ready for an expert to reduce what you owe? We'll start to identify the options to lower your bills over the next few steps.",
  bulletListTitle,
  bulletList,
};

const personInDistress: StepContent = {
  title: 'Dealing with medical bills can be overwhelming. We can take over.',
  bulletListTitle,
  bulletList,
};

export const validationContent: StepRecordContentByDependency<ArchetypeAndStressLevel> = {
  delegator: delegator,
  delegator_stressed: delegatorStressed,
  crusader: delegatorStressed,
  person_in_distress: personInDistress,
  optimizer: {
    title: "We can help! We'll cut through the complexity to maximize your savings.",
    bulletListTitle,
    bulletList,
  },
  optimizer_stressed: {
    title: 'We can cut through the complexity and frustration to maximize your savings.',
    bulletListTitle,
    bulletList,
  },
  other: delegator,
  other_stressed: personInDistress,
};
