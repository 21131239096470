import { ComponentPropsWithoutRef } from 'react';

export const StressLevelChart = (props: ComponentPropsWithoutRef<'svg'> & { level: number }) => (
  <svg
    width="76"
    height="32"
    viewBox="0 0 76 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <rect
      x="0.5"
      y="26.5"
      width="11"
      height="5"
      rx="1.5"
      fill={props.level >= 1 ? '#9788C0' : '#E1D4EB'}
      stroke="#9788C0"
    />
    <rect
      x="16.5"
      y="20.5"
      width="11"
      height="11"
      rx="1.5"
      fill={props.level >= 2 ? '#9788C0' : '#E1D4EB'}
      stroke="#9788C0"
    />
    <rect
      x="32.5"
      y="14.5"
      width="11"
      height="17"
      rx="1.5"
      fill={props.level >= 3 ? '#9788C0' : '#E1D4EB'}
      stroke="#9788C0"
    />
    <rect
      x="48.5"
      y="8.5"
      width="11"
      height="23"
      rx="1.5"
      fill={props.level >= 4 ? '#9788C0' : '#E1D4EB'}
      stroke="#9788C0"
    />
    <rect
      x="64.5"
      y="0.5"
      width="11"
      height="31"
      rx="1.5"
      fill={props.level >= 5 ? '#9788C0' : '#E1D4EB'}
      stroke="#9788C0"
    />
  </svg>
);
