import { usePartnership } from '@/routes/hooks/usePartnership';
import { hasValidPartnership, partnersMap } from '@/utils/partnership';

export const useConsultationLink = (isPhoneConsultation = false) => {
  const partnership = usePartnership();

  if (partnership && hasValidPartnership(partnership)) {
    if (partnersMap.get(partnership) === partnersMap.get('finfit')) {
      return 'https://calendly.com/melissa-753/20-minute-consultation-melissajohnson';
    }
  }

  if (isPhoneConsultation) {
    return 'https://calendly.com/melissa-753/20-minute-consultation-melissajohnson';
  }

  return 'https://calendly.com/melissa-753/20-minute-consultation-melissajohnson';
};
