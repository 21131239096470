import { getStepName } from '@/utils/stepUtils';
import { Dispatch, SetStateAction } from 'react';
import { z } from 'zod';

export const responseCreateAccount = z.object({
  session: z.string(),
});

export type ResponseCreateAccount = z.infer<typeof responseCreateAccount>;

export interface ICaseContext {
  setCaseState: Dispatch<SetStateAction<CaseState | undefined>>;
  sequence: Sequence | undefined;
  caseState: CaseState | undefined;
  caseId: string | undefined;
}

export enum StepType {
  open = 'questionopen',
  choice = 'questionchoice',
  multi_choice = 'questionmulti',
  static = 'static',
  questionpartial = 'questionpartial',
}

export interface IQuestionChoice {
  id: string;
  display: string;
}

export interface ServerStep {
  id: `${StepName}`;
  type: StepType;
  field?: string;
  display?: string;
  choices?: ReadonlyArray<IQuestionChoice>;
  properties?: Record<string, string | number | boolean>;
  optional: boolean;
}

export interface Step extends ServerStep {
  counter?: number;
  remaining?: number;
  // TODO: extract this to a separate object inside location.state
  error?: Error;
}

// We don't use this type and rely on BE for providing next step
export type BranchStep = unknown;

export interface Sequence {
  id: string;
  steps: ReadonlyArray<Step | BranchStep>;
}

export interface SequenceWithFirstStep extends Sequence {
  steps: ReadonlyArray<Step>;
}

export type ICaseField = {
  name: string;
  value: string | number;
};

export type Fields = Partial<Record<FieldName, string | number | boolean>>;

export interface Case {
  id: string;
  fields: Fields;
  patientId: string | null;
  sequenceId: string;
  created: string;
  modified: string;
}

// TODO: implement with zod
export interface CaseState {
  case: Case;
  step: ServerStep | undefined;
  history: string[];
  remaining?: number;
}

export enum StepName {
  bill_size = 'bill-size',
  max_bill_size = 'max-bill-size',
  anticipate_bills_001 = 'anticipate-bills-001',
  anticipate_bills_002 = 'anticipate-bills-002',
  recurring_bill_size = 'recurring-bill-size',
  has_insurance = 'has-insurance',
  stress_level = 'stress-level',
  referral_method_001 = 'referral-method-001',
  complete_account = 'complete-account',
  feeling = 'feeling',
  intake_notes = 'intake-notes',
  medical_provider_name = 'medical-provider-name',
  insurance_company_name = 'insurance-company-name',
  in_collections = 'in-collections',
  collections_company_name = 'collections-company-name',
  legal_encumbrance = 'legal-encumbrance',
  validation = 'validation',
  email = 'email',
  congrats_first_step = 'congrats-first-step',
  pain_point_select = 'pain-point-select',
  pain_point_select_001 = 'pain-point-select-001',
  product_cannot_help_email = 'product-cannot-help-email',
  loader_product = 'loader-product',
  product = 'product',
  terms = 'terms',
  checkout = 'checkout',
  household_size = 'household-size',
  pain_point_address = 'pain-point-address',
  consult_focus = 'consult-focus',
  concerns = 'concerns-select',
  offer_consult = 'offer-consult',
  scheduled_call_info = 'scheduled-call-info',
  financial_aid = 'financial-aid',
  above_fpl = 'above-fpl',
  product_cannot_help_legal_encumbrance = 'product-cannot-help-legal-encumbrance',
}

export enum Branch {
  default = 'default',
  create_account = 'create-account',
  cannot_help = 'cannot-help',
}

const PainPoint = z.enum([
  'affordability',
  'fair-price',
  'credit-collections',
  'effort',
  'billing-errors',
  'overwhelmed',
  'other',
]);

export const PainPointSchema = z.string().transform((value) => {
  const values = value.split(',').map((item) => item.trim());
  return values.every((item) => PainPoint.parse(item)) ? (values as PainPoint[]) : [];
});

export type PainPoint = z.infer<typeof PainPoint>;

export enum ContentDependency {
  default = 'default',
  insurance_no_collection_no = 'insurance-no-collection-no',
  insurance_yes_collection_no = 'insurance-yes-collection-no',
  insurance_yes_collection_yes_or_credit_collections_pain_point_yes = 'insurance-yes-collection-yes-or-credit-collections-pain-point-yes',
  insurance_no_collection_yes_or_credit_collections_pain_point_yes = 'insurance-no-collection-yes-or-credit-collections-pain-point-yes',
  point_in_time_10 = 'point-in-time-10',
  point_in_time_25 = 'point-in-time-25',
  subscription = 'subscription',
}

export const ProductContentDependency = z.enum([
  'insurance-no-collection-no',
  'insurance-yes-collection-no',
  'insurance-yes-collection-yes-or-credit-collections-pain-point-yes',
  'insurance-no-collection-yes-or-credit-collections-pain-point-yes',
]);

export type ProductContentDependency = z.infer<typeof ProductContentDependency>;

export const DealLost = z.enum(['bill-size-too-small', 'in-collections', 'ezfile']);

export const Feeling = z.enum([
  'need-an-expert',
  'wrong-and-unfair',
  'overwhelmed',
  'researching-options',
  'other',
]);

export const stressLevelSchema = z.coerce
  .number()
  .int()
  .gte(1, { message: 'stress level should be more than 0' })
  .lte(5, { message: 'stress level should be less than 6' });

export const ArchetypeAndStressLevel = z.enum([
  'delegator',
  'delegator_stressed',
  'crusader',
  'person_in_distress',
  'optimizer',
  'optimizer_stressed',
  'other',
  'other_stressed',
]);

export type ArchetypeAndStressLevel = z.infer<typeof ArchetypeAndStressLevel>;

export const MilestoneSteps = z.enum(['congrats-first-step']);

export type MilestoneSteps = z.infer<typeof MilestoneSteps>;

export const Product = z.enum(['bill-reduction-25', 'bill-reduction-10', 'subscription', 'ezfile']);

export type Product = z.infer<typeof Product>;

export const EZFileProduct = z.enum(['ezfile']);

export type EZFileProduct = z.infer<typeof EZFileProduct>;

export const BillSize = z.enum(['less-than-5k', '5k-15k', '15k-50k', '50k-250k', 'more-than-250k']);

export type BillSize = z.infer<typeof BillSize>;

export const IntakeNotes = z.enum(['fulfilled', 'skipped']);

export type IntakeNotes = z.infer<typeof IntakeNotes>;

export const LegalEncumbrance = z.enum(['yes', 'no']);

export type LegalEncumbrance = z.infer<typeof LegalEncumbrance>;

export const CheckoutComplete = z.boolean().transform((val) => (val ? 'true' : 'false'));

export type CheckoutComplete = z.infer<typeof CheckoutComplete>;

export enum FieldName {
  above_fpl = 'above-fpl',
  bill_size = 'bill-size',
  max_bill_size = 'max-bill-size',
  bill_frequency = 'bill-frequency',
  recurring_bill_size = 'recurring-bill-size',
  has_insurance = 'has-insurance',
  stress_level = 'stress-level',
  first_name = 'first-name',
  last_name = 'last-name',
  email = 'email',
  phone = 'phone',
  validation_need_an_expert = 'validation-need-an-expert',
  validation_researching_options = 'validation-researching-options',
  validation_researching_options_stressed = 'validation-researching-options-stressed',
  validation_overwhelmed = 'validation-overwhelmed',
  validation_wrong_and_unfair = 'validation-wrong-and-unfair',
  validation_need_an_expert_stressed = 'validation-need-an-expert-stressed',
  validation_other = 'validation-other',
  validation_other_stressed = 'validation-other-stressed',
  referral_method = 'referral-method',
  feeling = 'feeling',
  persona = 'persona',
  in_collections = 'in-collections',
  legal_encumbrance = 'legal-encumbrance',

  insurance_company_name = 'insurance-company-name',
  collections_company_name = 'collections-company-name',
  intake_notes = 'intake-notes',
  medical_provider_name = 'medical-provider-name',
  household_income = 'household-income',
  household_size = 'household-size',
  recurring_bill_details = 'recurring-bill-details',
  hipaa_signed = 'hipaa-signed',
  __passed__ = '__passed__',
  pain_point = 'pain-point',
  concerns = 'concerns',
  deposit_payment_id = 'deposit-payment-id',
  subscription_payment_id = 'subscription-payment-id',
  product = 'product',
  agreement = 'agreement',
  max_payment_lumpsum = 'max-payment-lumpsum',
  max_payment_monthly = 'max-payment-monthly',
  deal_lost = 'deal-lost',
  payment_error = 'payment-error',
  offer_consult = 'offer-consult',
  partnership = 'partnership',
  activated_services = 'activated-services',
}

export enum AllowedPatchField {
  'deal-lost' = 'deal-lost',
  fbclid = 'fbclid',
  gclid = 'gclid',
  msclkid = 'msclkid',
  'talk-to-an-advisor-click' = 'talk-to-an-advisor-click',
  'utm-campaign' = 'utm-campaign',
  'utm-content' = 'utm-content',
  'utm-medium' = 'utm-medium',
  'utm-network' = 'utm-network',
  'utm-source' = 'utm-source',
  'utm-term' = 'utm-term',
  'activated-services' = 'activated-services',
}

export const isStepNameGuard = (id: string | undefined): id is StepName => {
  if (!id) return false;

  return Object.values(StepName).includes(id);
};

export const isStepGuard = (candidate: unknown): candidate is Step => {
  if (!candidate) return false;

  const castedCandidate = candidate as { id?: unknown; type?: unknown };
  if (
    !castedCandidate.id ||
    typeof castedCandidate.id !== 'string' ||
    typeof castedCandidate.type !== 'string'
  )
    return false;

  const stepName = getStepName(castedCandidate.id);
  if (!stepName) return false;

  return (
    Object.values(StepName).includes(stepName) &&
    !!castedCandidate.type &&
    Object.values(StepType).includes(castedCandidate.type)
  );
};

export const isBranchGuard = (candidate: string): candidate is Branch => {
  return Object.values(Branch).some((branch) => candidate.startsWith(branch));
};

export const isAllowedPatchFieldGuard = (candidate: string): candidate is AllowedPatchField => {
  return Object.values(AllowedPatchField).includes(candidate);
};
