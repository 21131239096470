import { ReactElement } from 'react';
import { useRouter } from '@tanstack/router';
import { AnimatePresence, motion } from 'framer-motion';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';

function PageTransitionLayout({
  children,
  noAnimation,
}: {
  children: ReactElement<unknown>;
  noAnimation: boolean;
}) {
  const router = useRouter();
  const isMdBreakpoint = useMdBreakpoint();

  const { pathname } = router.state.currentLocation;

  if (isMdBreakpoint === undefined) return null;

  if (noAnimation || !isMdBreakpoint || pathname === '/') return children;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={pathname}
        initial="initialState"
        animate="animateState"
        exit="exitState"
        style={{
          display: 'flex',
          minHeight: '100%',
          width: '100%',
        }}
        variants={{
          initialState: {
            opacity: 0,
            x: '100vw',
          },
          animateState: {
            opacity: 1,
            x: 0,
            transition: {
              opacity: {
                duration: 0.3,
              },
              x: {
                duration: 0.3,
              },
            },
          },
          exitState: {
            opacity: 0,
            x: '-100vw',
            transition: {
              opacity: {
                duration: 0.3,
              },
              x: {
                duration: 0.3,
              },
            },
          },
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export { PageTransitionLayout };
