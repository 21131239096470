import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { Button, Modal } from '@mantine/core';
import { WhyDoWeAsk } from './icons/WhyDoWeAsk';
import { ReactNode } from 'react';

export const WhyDoWeAskModal = ({
  opened,
  close,
  title,
  description,
}: {
  opened: boolean;
  close: () => void;
  title: ReactNode;
  description: ReactNode;
}) => {
  const isMdBreakpoint = useMdBreakpoint();

  return (
    <Modal.Root
      opened={opened}
      onClose={close}
      centered
      radius={20}
      padding={24}
    >
      <Modal.Overlay
        color="#2E193E"
        opacity={0.85}
      />
      <Modal.Content>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 16,
          }}
        >
          <Modal.Title
            style={{
              fontSize: 20,
              fontWeight: 700,
              color: '#2E193E',
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            fontSize: 14,
            color: '#2E193E',
            paddingBottom: 16,
          }}
        >
          {description}
        </Modal.Body>
        <Modal.Body
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <Button
            onClick={close}
            radius={50}
            styles={(theme) => ({
              root: {
                border: `1px solid ${theme.colors.primary[0]}`,
                transition: '250ms linear',
                width: '100%',
                maxWidth: 311,
                padding: '11px 24px',
                color: theme.colors.primary[0],
                fontSize: 16,
                fontWeight: 700,
                '&:hover': {
                  backgroundColor: theme.colors.primary[0],
                  color: 'white',
                  border: `1px solid ${theme.colors.primary[0]}`,
                },

                '&:disabled': {
                  color: theme.colors.primary[1],
                  backgroundColor: theme.colors.primary[2],
                },

                '&[data-loading]': {
                  backgroundColor: theme.colors.primary[2],
                  color: theme.colors.primary[0],
                  border: `1px solid ${theme.colors.primary[2]}`,
                },
                '&[data-loading]::before': {
                  backgroundColor: 'transparent',
                },
              },
              label: {
                color: 'inherit',
                fontWeight: 700,
              },
            })}
          >
            Close
          </Button>
          <WhyDoWeAsk width={!isMdBreakpoint ? '240px' : 'auto'} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
