import { useMutation } from '@tanstack/react-query';
import { NavigateOptions, useNavigate } from '@tanstack/router';
import { SyntheticEvent } from 'react';
import { NextPath } from '../../router/router.types';
import { patchCase } from '../../state/patchCase';

export const useCasePatcher = ({
  search = {},
  to,
}: {
  search?: NavigateOptions['search'];
  to?: NextPath;
}) => {
  const navigate = useNavigate();

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: async (formData: FormData) => {
      patchCase(formData);
    },
    onSuccess: () => {
      if (to) {
        navigate({ search, to });
      }
    },
  });

  const submit = (formData: FormData) => {
    mutate(formData);
  };

  const onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate(new FormData(event.currentTarget));
  };

  return { isLoading, submit, onFormSubmit, isSuccess };
};
