import { useParams } from '@tanstack/router';
import { staticStepRoute } from '../router/router';

import { GreenScreen } from '@/components/GreenScreen';
import { StepTitle } from '@/components/StepTitle';
import { validationContent } from '@/content/validationContent';
import { CaseState } from '@/state/state.types';
import { mapCaseToArchetypeAndStressLevel } from '@/utils/mapCaseToArchetypeAndStressLevel';
import { Flex, Space, Image, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import milestone1Src from '../assets/img/milestone_1.webp';
import { useLgBreakpoint } from '@/theme/hooks/useLgBreakpoint';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';

export const Validation = () => {
  const { data: caseState } = useQuery<CaseState>(['caseState']);
  const { stepId } = useParams({ from: staticStepRoute.id });
  const isLgBreakpoint = useLgBreakpoint();
  const isMdBreakpoint = useMdBreakpoint();

  if (!stepId) throw new Error('StepId should defined at validation step');
  if (!caseState) throw new Error('Case state should be defined at validation step');

  const validationDependency = mapCaseToArchetypeAndStressLevel(caseState);
  const { title, description } = validationContent[validationDependency];

  return (
    <Flex
      w="100%"
      wrap="wrap"
      justify="center"
      align="center"
      my="auto"
      rowGap={50}
      direction={isLgBreakpoint ? 'row' : 'column'}
    >
      <GreenScreen
        stepId={stepId}
        primaryButtonLabel="Continue"
        primaryButtonProps={{
          style: {
            display: 'flex',
            minWidth: !isLgBreakpoint ? '100%' : 345,
            justifyContent: 'center',
          },
        }}
      >
        <Flex
          maw={610}
          direction="column"
          align="center"
          justify="center"
        >
          {!!title &&
            (typeof title === 'string' ? (
              <StepTitle
                isInverted
                ta="left"
                w="100%"
                size={34}
              >
                {title}
              </StepTitle>
            ) : (
              title()
            ))}

          {!!description && (
            <Text
              ta="left"
              c="minor.1"
              size={16}
              mt={16}
            >
              {description}
            </Text>
          )}
        </Flex>
        <Space h={{ base: 10 }} />
      </GreenScreen>

      <Flex
        maw={595}
        miw={200}
        w="100%"
        direction="column"
        align="center"
        style={{
          flex: 1,
        }}
      >
        <Text
          ta="left"
          c="#9ED0D0"
          size={isMdBreakpoint ? 20 : 16}
          mt={16}
          mb={10}
          weight={700}
        >
          Our patients save an average of&nbsp;&nbsp;
          <span
            style={{
              fontSize: isMdBreakpoint ? 22 : 20,
              color: 'white',
            }}
          >
            65%
          </span>
        </Text>
        <Image src={milestone1Src} />
      </Flex>
    </Flex>
  );
};
