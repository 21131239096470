import { navigateToNextStepOrUrl } from '@/utils/navigateToNextStepOrUrl';
import { useMutation } from '@tanstack/react-query';
import { SyntheticEvent } from 'react';
import { ZodError } from 'zod';
import { Url, urlScheme } from '../../router/router.types';
import { CaseState } from '../../state/state.types';
import { updateCase } from '../../state/updateCase';
import { navigateToErrorRoute } from '../../utils/navigateToErrorRoute';

export const useCaseUpdater = ({
  shouldNavigate = true,
  externalUrl,
  onError,
  replace,
  avoidUrlNavigation,
  formTransformer,
}: {
  shouldNavigate?: boolean;
  onError?: (error: Error | ZodError, context: FormData) => void;
  externalUrl?: Url;
  replace?: boolean;
  avoidUrlNavigation?: boolean;
  formTransformer?: () => FormData;
}) => {
  const { mutate, isLoading, error, data } = useMutation({
    mutationFn: async (formData: FormData) => {
      const caseState = await updateCase(formData);
      return caseState;
    },
    onSuccess: (caseState: CaseState) => {
      if (!shouldNavigate) return;

      if (externalUrl && urlScheme.safeParse(externalUrl).success) {
        if (replace) {
          // we have to wait until state persistence is done no way to persist it in sync way
          // That async call will be behind persist call in async queue, because we have throttleTime: 0 in persistOptions
          setTimeout(() => {
            window.location.replace(externalUrl);
          }, 0);
          return;
        }

        setTimeout(() => {
          window.location.assign(externalUrl);
        }, 0);
        return;
      }

      if (!caseState) {
        throw new Error('Case is not found');
      }

      navigateToNextStepOrUrl(caseState, replace, 5000, avoidUrlNavigation);
    },
    onError: (error: Error | ZodError, context) => {
      if (onError) return onError(error, context);

      navigateToErrorRoute(error, 'useCaseUpdater', Object.fromEntries(context));
    },
  });

  const onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    let form;
    if (formTransformer) {
      form = formTransformer();
    } else {
      form = new FormData(event.currentTarget);
    }

    mutate(form);
  };

  return { onFormSubmit, mutate, isLoading, error, data };
};
