import { Fields } from '@/state/state.types';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    Beacon: (value: string, data?: unknown) => unknown;
  }
}

/**
 * Push an event to dataLayer array.
 * We setup custom triggers in Google Tag Manager to make GTM script look into dataLayer array and find the event.
 * If it finds the event, it will post it to Google Tag Manager.
 * When GTM gets custom event our custom trigger fires.
 * We setup Google Ads Tag based on the custom trigger.
 * So Google Ads Tag fires when GTM gets custom event.
 * NOTE: without setting up custom triggers in GTM, the event will not be sent from the app to Google Tag Manager.
 * Trigger: https://tagmanager.google.com/?pli=1#/container/accounts/4702562852/containers/12631487/workspaces/83/triggers
 * Tags: https://tagmanager.google.com/#/container/accounts/4702562852/containers/12631487/workspaces/83/tags
 */

export const pushCustomEvent = ({
  prevFields,
  nextFields,
}: {
  prevFields: Fields | undefined;
  nextFields: Fields | undefined;
}) => {
  window.dataLayer = window.dataLayer || [];

  if (!prevFields || !nextFields) return;

  if (detectEmailProvided(prevFields, nextFields) && nextFields['bill-size'] === 'less-than-5k') {
    window.dataLayer.push({
      // Tag name `AW - Signup - Email submit & less than $5k Bill Size`
      event: 'JEJ9CM-Jh84DELntguQC',
    });
  }

  if (detectEmailProvided(prevFields, nextFields) && nextFields['bill-size'] === '5k-15k') {
    window.dataLayer.push({
      // Tag name `AW - Signup - Email submit & 5k-15k Bill Size`
      event: 'h-ZhCJqavM4DELntguQC',
      value: '47.05',
    });
  }

  if (detectEmailProvided(prevFields, nextFields) && nextFields['bill-size'] === '15k-50k') {
    window.dataLayer.push({
      // Tag name `AW - Signup - Email submit & 15k-50k Bill Size`
      event: 'Kz52CPyuvM4DELntguQC',
      value: '128.95',
    });
  }

  if (detectEmailProvided(prevFields, nextFields) && nextFields['bill-size'] === '50k-250k') {
    window.dataLayer.push({
      // Tag name `AW - Signup - Email submit & 50k-250k Bill Size`
      event: '_yz8CPrPts4DELntguQC',
      value: '517.44',
    });
  }

  if (detectEmailProvided(prevFields, nextFields) && nextFields['bill-size'] === 'more-than-250k') {
    window.dataLayer.push({
      // Tag name `AW - Signup - Email submit & more than 250k Bill Size`
      event: 'mgqfCL3Xts4DELntguQC',
      value: '517.44',
    });
  }

  if (detectEmailProvided(prevFields, nextFields)) {
    window.dataLayer.push({
      // Tag name `AW - Signup - Email submit`
      event: '1OCbCMKA-KkBELntguQC',
    });
    window.dataLayer.push({
      // Tag name `Bing - email submit`
      event: 'bing-email-submit',
    });
    window.dataLayer.push({
      // Tag name `FB - email submit`
      event: 'fb-email-submit',
    });
  }

  if (detectBillSizeProvided(prevFields, nextFields)) {
    window.dataLayer.push({
      // Trigger name `SignUp submit-bill-size`
      event: 'submit-bill-size',
    });
  }

  if (detectBillFrequencyProvided(prevFields, nextFields)) {
    window.dataLayer.push({
      // Trigger name `SignUp has-insurance`
      event: 'has-insurance',
    });
  }
};

/**
 * Detect if email is provided
 */
const detectEmailProvided = (prevFields: Fields, nextFields: Fields) => {
  const emailsChanged = !prevFields.email && !!nextFields.email;
  return emailsChanged;
};

/**
 * Detect if bill size is provided in bill size step.
 */
const detectBillSizeProvided = (prevFields: Fields, nextFields: Fields) => {
  return !prevFields['bill-size'] && !!nextFields['bill-size'];
};

/**
 * Detect if bill frequency is provided in anticipate bills step.
 */
const detectBillFrequencyProvided = (prevFields: Fields, nextFields: Fields) => {
  return !prevFields['bill-frequency'] && !!nextFields['bill-frequency'];
};
