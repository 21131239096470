import { Flex, Text, useMantineTheme } from '@mantine/core';
import { People } from '@/components/icons/weCanHelp/People';
import { Savings } from '@/components/icons/weCanHelp/Savings';
import { Updates } from '@/components/icons/weCanHelp/Updates';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';

const ProductBoxes = () => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();

  const iconStyle = {
    paddingTop: isMdBreakpoint ? 0 : 7,
  };

  const list = [
    {
      label: 'Dedicated Success Advocate',
      icon: <People style={iconStyle} />,
    },
    {
      label: (
        <>
          Effortless {isMdBreakpoint && <br />}
          Savings
        </>
      ),
      icon: <Savings style={iconStyle} />,
    },
    {
      label: 'Regular Progress Updates',
      icon: <Updates style={iconStyle} />,
    },
  ];

  return (
    <Flex
      gap={20}
      mt={30}
      mb={10}
      w="100%"
      justify="center"
      align="center"
      direction={{ base: 'column', md: 'row' }}
    >
      {list.map((item, index) => (
        <Flex
          key={index}
          align="center"
          direction={{ base: 'row', md: 'column' }}
          p={{ base: 0, md: 15 }}
          w="100%"
          miw={180}
          maw={{ base: '100%', md: 180 }}
          style={{
            borderRadius: 12,
            backgroundColor: theme.colors.primary[3],
          }}
        >
          {item.icon}
          <Text
            mt={0}
            c="primary.2"
            align={isMdBreakpoint ? 'center' : 'left'}
          >
            {item.label}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export { ProductBoxes };
