import { noDepositPartnershipTC, tcLinkContent } from '@/content/checkoutContent';
import { Amount } from '@/content/stepContent.types';
import { useCaseUpdater } from '@/routes/hooks/useCaseUpdater';
import { useFields } from '@/routes/hooks/useFields';
import { Product, Step } from '@/state/state.types';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { Anchor, Flex, Group, List, Text } from '@mantine/core';
import { PrimaryButton } from './PrimaryButton';
import { StepTitle } from './StepTitle';
import { DocSigned } from './icons/DocSigned';
import { useNoDepositPartnership } from '@/routes/hooks/useNoDepositPartnership';

export const TCCard = ({ step }: { step: Step }) => {
  const isMdBreakpoint = useMdBreakpoint();

  const { mutate, isLoading } = useCaseUpdater({
    avoidUrlNavigation: true,
  });
  const stepId = step.id;
  const fields = useFields();
  const product = Product.parse(fields?.product);
  const isTCAccepted = fields?.agreement;
  const amount = Amount.parse(step.properties?.amount);
  const isNoDeposit = useNoDepositPartnership();

  // FIXME: keep it only for development until sc-335 is done
  // const environment = Environment.parse(validateEnv().PROD ? 'production' : 'development');
  const tcLink = isNoDeposit
    ? noDepositPartnershipTC
    : tcLinkContent['development'][product][amount];

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('stepId', stepId);
    formData.append('agreement', tcLink);
    mutate(formData);
  };

  return (
    <Flex
      gap={0}
      direction={{ base: 'column', md: isTCAccepted ? 'row' : 'column' }}
      justify="space-between"
      align={{ base: 'start', md: isTCAccepted ? 'center' : 'start' }}
    >
      <StepTitle
        size="h5"
        align="left"
        style={{
          lineHeight: 'inherit',
        }}
      >
        Accept terms & conditions
      </StepTitle>

      {isTCAccepted ? (
        <Group
          c="primary.0"
          align="center"
          mt={{ base: 6, md: 0 }}
          spacing={8}
        >
          <DocSigned />
          <Text
            fz="sm"
            fw={600}
          >
            Accepted
          </Text>
        </Group>
      ) : (
        <>
          <List
            center
            mt={8}
            spacing={16}
            styles={{
              itemIcon: {
                alignSelf: 'start',
              },
              root: {
                paddingLeft: 6,
              },
              itemWrapper: {
                // We have to subtract list marker width with its padding to keep the same width as parent
                width: !isMdBreakpoint ? `calc(100% - 22px)` : '100%',
                marginLeft: -8,
              },
            }}
            style={{
              // To override browser default styling
              paddingLeft: isMdBreakpoint ? 8 : undefined,
            }}
          >
            <List.Item>
              <Text
                c="primary.0"
                fz="sm"
              >
                I confirm that I have read, consent to, and accept the terms of{' '}
                <Anchor
                  href={tcLink}
                  sx={{ textDecoration: 'underline' }}
                  target="_blank"
                  fz="sm"
                >
                  {`Resolve's Patient Agreement`}
                </Anchor>
                .
              </Text>
            </List.Item>
            {isNoDeposit ? (
              <>
                <List.Item>
                  <Text
                    c="primary.0"
                    fz="sm"
                  >
                    No deposit required
                  </Text>
                </List.Item>
                <List.Item>
                  <Text
                    c="primary.0"
                    fz="sm"
                  >
                    20% of first $5k of savings or reimbursements in a calendar year
                  </Text>
                </List.Item>
                <List.Item>
                  <Text
                    c="primary.0"
                    fz="sm"
                  >
                    8% of all savings or reimbursements beyond the first $5k for that calendar year
                  </Text>
                </List.Item>
              </>
            ) : (
              <List.Item>
                <Text
                  c="primary.0"
                  fz="sm"
                >
                  I will pay Resolve 20% of the savings generated, with a maximum charge of $20,000.
                </Text>
              </List.Item>
            )}
          </List>

          {/* Square injects hidden input[name="nds-pmd"] field in each <form> on the page
            so we have to avoid using <form> on this page and compose submit button function manually
            https://stackoverflow.com/questions/60388087/how-remove-odd-hidden-web-field-in-my-form
          */}
          <PrimaryButton
            size="sm"
            mt={20}
            isLoading={isLoading}
            align="start"
            onClick={onSubmit}
          >
            I agree
          </PrimaryButton>
        </>
      )}
    </Flex>
  );
};
