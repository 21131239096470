import { ComponentPropsWithoutRef } from 'react';

const FinFit = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    id="svg2"
    width={740.66669}
    height={245.85333}
    viewBox="0 0 740.66669 245.85333"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs6" />
    <g
      id="g8"
      transform="matrix(1.3333333,0,0,-1.3333333,0,245.85333)"
    >
      <g
        id="g10"
        transform="scale(0.1)"
      >
        <path
          d="m 5460.07,86.7813 h 11.4 c 9.99,0 15.65,4.8281 15.65,12.3984 0,7.7693 -5.66,12.4023 -15.65,12.4023 h -11.4 z m 49.42,-39.1094 c 2.49,-3.3125 1.14,-6.0625 -3.03,-6.0625 h -8.88 c -2.62,0 -4.47,0.9023 -6.06,3.0312 l -19.48,25.6602 h -11.97 V 46.6289 c 0,-3.2578 -1.76,-5.0195 -5,-5.0195 h -8.12 c -3.24,0 -4.99,1.7617 -4.99,5.0195 V 123.66 c 0,3.219 1.75,5.012 4.99,5.012 h 13.12 12.23 c 20.76,0 32.93,-13.113 32.93,-28.594 0,-12.2069 -5.52,-21.4882 -15.48,-26.2264 z m 30.36,37.4492 c 0,39.0199 -31.37,70.5199 -69.96,70.5199 -38.59,0 -69.96,-31.5 -69.96,-70.5199 0,-39.0195 31.37,-70.4727 69.96,-70.4727 38.59,0 69.96,31.4532 69.96,70.4727 z m 15.18,0 C 5555.03,38.1094 5516.95,0 5469.89,0 c -47.03,0 -85.14,38.1094 -85.14,85.1211 0,47.0589 38.11,85.1679 85.14,85.1679 47.06,0 85.14,-38.109 85.14,-85.1679"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path12"
        />
        <path
          d="m 1079.94,1781.35 v -222.61 c 0,-34.57 -28.02,-62.6 -62.6,-62.6 H 362.172 v -396.33 h 562.305 c 34.554,0 62.566,-28.01 62.566,-62.56 V 814.68 c 0,-34.539 -27.996,-62.532 -62.531,-62.532 H 362.172 V 62.6016 C 362.172,28.0313 334.145,0 299.57,0 H 62.5664 C 28.0117,0 0,28.0117 0,62.5703 V 1632.62 c 0,116.68 94.5859,211.26 211.262,211.26 h 150.91 655.238 c 34.54,0 62.53,-28 62.53,-62.53"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path14"
        />
        <path
          d="M 2851.6,624.059 2849.62,62.6016 C 2849.62,22.4414 2827.22,0 2787.06,0 h -214.68 c -40.16,0 -62.56,22.4414 -62.56,62.6016 l 1.98,535.7384 c 0,150.859 -89.54,221.961 -208.74,221.961 -133.67,0 -237.72,-77.039 -237.72,-275.27 L 2063.36,62.6016 C 2063.36,22.4414 2040.95,0 2000.79,0 h -214.67 c -40.17,0 -62.57,22.4414 -62.57,62.6016 l 1.98,974.3784 c 0,40.16 22.41,62.53 62.57,62.53 h 214.67 c 40.16,0 62.57,-22.37 62.57,-62.53 v -72.824 c 73.75,111.414 194.48,170.934 358.24,170.934 244.97,0 428.02,-188.371 428.02,-511.031"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path16"
        />
        <path
          d="m 4151.08,1781.35 v -222.61 c 0,-34.57 -28.03,-62.6 -62.6,-62.6 h -655.17 v -396.33 h 562.3 c 34.56,0 62.57,-28.01 62.57,-62.56 V 814.68 c 0,-34.539 -28,-62.532 -62.53,-62.532 H 3433.31 V 62.6016 C 3433.31,28.0313 3405.28,0 3370.71,0 H 3133.7 c -34.55,0 -62.56,28.0117 -62.56,62.5703 V 1632.62 c 0,116.68 94.58,211.26 211.26,211.26 h 150.91 655.24 c 34.54,0 62.53,-28 62.53,-62.53"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path18"
        />
        <path
          d="M 5555.03,1036.98 V 835.449 c 0,-40.16 -22.4,-62.597 -62.56,-62.597 H 5308.5 L 5306.51,62.6016 C 5306.51,22.4414 5284.11,0 5243.95,0 h -214.68 c -40.16,0 -62.56,22.4414 -62.56,62.6016 l 1.98,710.2504 h -184.61 c -40.17,0 -62.57,22.437 -62.57,62.597 v 201.531 c 0,40.16 22.4,62.53 62.57,62.53 h 184.61 v 334.1 c 0,40.16 22.4,62.53 62.57,62.53 h 214.67 c 40.16,0 62.57,-22.37 62.57,-62.53 v -334.1 h 183.97 c 40.16,0 62.56,-22.37 62.56,-62.53"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path20"
        />
        <path
          d="m 1441.45,1089.37 c -4.75,0 -8.78,-2.91 -11.01,-3.85 -29.68,-12.49 -62.16,-19.57 -96.33,-19.57 -34.17,0 -66.65,7.08 -96.33,19.57 -2.24,0.94 -6.26,3.85 -11,3.85 -40.16,0 -62.57,-22.37 -62.57,-62.53 L 1162.23,62.6016 C 1162.23,22.4414 1184.64,0 1224.8,0 h 214.67 c 40.16,0 62.57,22.4414 62.57,62.6016 l 1.98,964.2384 c 0,40.16 -22.41,62.53 -62.57,62.53"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path22"
        />
        <path
          d="m 1164.22,1326.25 v 0 c 0,93.83 76.07,169.89 169.9,169.89 v 0 c 93.83,0 169.89,-76.06 169.89,-169.89 v 0 c 0,-93.83 -76.06,-169.89 -169.89,-169.89 v 0 c -93.83,0 -169.9,76.06 -169.9,169.89"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path24"
        />
        <path
          d="m 4512.59,1089.37 c -4.75,0 -8.78,-2.91 -11.02,-3.85 -29.68,-12.49 -62.16,-19.57 -96.32,-19.57 -34.17,0 -66.65,7.08 -96.33,19.57 -2.24,0.94 -6.27,3.85 -11,3.85 -40.17,0 -62.57,-22.37 -62.57,-62.53 L 4233.37,62.6016 C 4233.37,22.4414 4255.77,0 4295.94,0 h 214.67 c 40.16,0 62.56,22.4414 62.56,62.6016 l 1.99,964.2384 c 0,40.16 -22.41,62.53 -62.57,62.53"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path26"
        />
        <path
          d="m 4235.37,1326.25 v 0 c 0,93.83 76.06,169.89 169.89,169.89 v 0 c 93.83,0 169.9,-76.06 169.9,-169.89 v 0 c 0,-93.83 -76.07,-169.89 -169.9,-169.89 v 0 c -93.83,0 -169.89,76.06 -169.89,169.89"
          style={{
            fill: 'currentcolor',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path28"
        />
      </g>
    </g>
  </svg>
);

export default FinFit;
