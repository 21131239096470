import {
  Branch,
  Step,
  StepName,
  StepType,
  isBranchGuard,
  isStepNameGuard,
} from '@/state/state.types';

export const isMilestoneStep = (id: string): boolean => {
  return id.startsWith('congrats-first-step');
};

export const isCompleteAccountStep = (id: string): boolean => {
  return id.startsWith('complete-account');
};

export const isCanNotHelpStep = (id: string): boolean => {
  return id.startsWith('product-cannot-help');
};

export const isValidationStep = (id: string): boolean => {
  return id.startsWith('validation');
};

export const isEmailStep = (id: string): boolean => {
  return id.startsWith('email');
};

export const isProductStep = (id: string): boolean => {
  return id.startsWith('product') && !isCanNotHelpStep(id);
};

export const isPrepStep = (id: string): boolean => {
  return id.startsWith('prep-');
};

export const isCheckoutStep = (id: string): boolean => {
  return id.startsWith('checkout');
};

export const isJotformStep = (id: string): boolean => {
  return id.startsWith('jotform');
};

export const isOpenStep = (step: Step): boolean => {
  return step.type === StepType.open;
};

export const isLoaderProductStep = (id: string): boolean => {
  return id.startsWith(StepName.loader_product);
};

export const isConsultFocusStep = (id: string): boolean => {
  return id.startsWith(StepName.consult_focus);
};

export const isScheduledCallInfoStep = (id: string): boolean => {
  return id.startsWith(StepName.scheduled_call_info);
};

export const isProductCannotHelpLegalEncumbranceStep = (id: string): boolean => {
  return id.startsWith(StepName.product_cannot_help_legal_encumbrance);
};

export const getStepName = (stepId: string): StepName | undefined => {
  const stepName = stepId.split('.')[0];
  return isStepNameGuard(stepName) ? stepName : undefined;
};

export const getBranchSuffix = (stepId: string): Branch | undefined => {
  const suffix = stepId.slice(stepId.indexOf('.') + 1);
  if (isBranchGuard(suffix)) {
    return suffix;
  }
};

export const getBranches = (stepId: string): Branch[] => {
  const brancheCandidates = stepId.split('.');

  const branches: Branch[] = brancheCandidates.filter((branch): branch is Branch => {
    return isBranchGuard(branch);
  });

  if (stepId.includes('.') && branches.length === 0) {
    throw new Error("stepId doesn't include valid branch");
  }

  return branches;
};
