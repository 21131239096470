import { errorRoute, router } from '@/router/router';
import { StepName } from '@/state/state.types';
import {
  isCanNotHelpStep,
  isCheckoutStep,
  isConsultFocusStep,
  isLoaderProductStep,
  isMilestoneStep,
  isPrepStep,
  isProductStep,
  isScheduledCallInfoStep,
  isValidationStep,
} from './stepUtils';

export const shouldHideBackButton = (stepId: string): boolean =>
  isCanNotHelpStep(stepId) ||
  isProductStep(stepId) ||
  isPrepStep(stepId) ||
  isCheckoutStep(stepId) ||
  isLoaderProductStep(stepId) ||
  [StepName.loader_product, StepName.terms].some((name) => stepId.startsWith(name));

export const shouldHideProgressBar = (stepId: string): boolean => shouldHideBackButton(stepId);

export const shouldShowHelpBanner = (stepId: string): boolean =>
  [StepName.terms].some((id) => stepId.startsWith(id)) || isCheckoutStep(stepId);

export const shouldShowSalesBannerByStepId = (stepId: string): boolean =>
  [
    StepName.in_collections,
    StepName.collections_company_name,
    StepName.legal_encumbrance,
    StepName.referral_method_001,
    StepName.medical_provider_name,
    StepName.household_size,
    StepName.above_fpl,
    StepName.has_insurance,
    StepName.intake_notes,
    StepName.pain_point_select_001,
    StepName.concerns,
  ].some((id) => stepId.startsWith(id));

export const shouldHideRedirectToDashboardButtonInContainer = (stepId: string): boolean =>
  [
    StepName.bill_size,
    StepName.anticipate_bills_001,
    StepName.anticipate_bills_002,
    StepName.feeling,
    StepName.stress_level,
    StepName.validation,
    StepName.email,
    StepName.product_cannot_help_email,
    StepName.loader_product,
    StepName.terms,
    StepName.complete_account,
    StepName.max_bill_size,
    StepName.pain_point_address,
  ].some((name) => stepId.startsWith(name)) ||
  isProductStep(stepId) ||
  isCheckoutStep(stepId) ||
  stepId.startsWith('referral-method-001.cannot-help');

export const isDarkBackground = (stepId: string): boolean =>
  [StepName.terms, StepName.pain_point_address].some((name) => stepId.startsWith(name)) ||
  isMilestoneStep(stepId) ||
  isValidationStep(stepId) ||
  isProductStep(stepId) ||
  isConsultFocusStep(stepId) ||
  isScheduledCallInfoStep(stepId) ||
  isErrorPage();

export const isMilestone = (stepId: string): boolean =>
  isMilestoneStep(stepId) || isValidationStep(stepId);

export const isPostProductStep = (stepId: string): boolean =>
  [StepName.terms, StepName.checkout].some((name) => stepId.startsWith(name)) ||
  isProductStep(stepId);

export const isErrorPage = (): boolean => router.state.currentLocation.pathname === errorRoute.id;
