import { useDisclosure } from '@mantine/hooks';
import { WhyDoWeAskModal } from './WhyDoWeAskModal';
import { SecondaryButton } from './SecondaryButton';
import { Flex, Text } from '@mantine/core';
import { QuestionMark } from './icons/QuestionMark';
import { ReactNode } from 'react';

export const WhyDoWeAsk = ({
  title,
  description,
  stepId,
}: {
  title: ReactNode;
  description: ReactNode;
  stepId: string;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <WhyDoWeAskModal
        opened={opened}
        close={close}
        title={title}
        description={description}
      />
      <SecondaryButton
        onClick={() => {
          open();
          window.heap?.track('why-do-we-ask-click-event', {
            stepId,
          });
        }}
        styles={(theme) => ({
          root: {
            border: `1px solid ${theme.colors.primary[0]}`,
            color: theme.colors.primary[0],
            backgroundColor: 'transparent',
            marginTop: '25px',

            '&:hover': {
              backgroundColor: theme.colors.primary[0],
              color: theme.colors.minor[1],
              border: `1px solid ${theme.colors.tertiary[0]}`,

              'svg > path': {
                stroke: 'white',
              },
            },

            // override Mantine overlay style
            '&[data-loading]::before': {
              backgroundColor: 'transparent',
            },

            '&[data-loading]': {
              backgroundColor: theme.colors.tertiary[0],
              color: theme.colors.minor[1],
              border: `1px solid ${theme.colors.tertiary[0]}`,
            },
          },
          label: {
            color: 'inherit',
          },
        })}
      >
        <Flex
          align="center"
          justify="center"
          gap={10}
        >
          <QuestionMark />{' '}
          <Text
            size={12}
            fw={600}
          >
            Why do we ask?
          </Text>
        </Flex>
      </SecondaryButton>
    </>
  );
};
