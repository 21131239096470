import { ComponentPropsWithoutRef } from 'react';

export const Email = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="38"
    height="32"
    viewBox="0 0 38 32"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.308 0a5.743 5.743 0 1 0 0 11.487 5.743 5.743 0 0 0 0-11.487Zm-1.74 4.003a2.462 2.462 0 1 1 3.48 3.482 2.462 2.462 0 0 1-3.48-3.482Zm-17.749 6.202a1.641 1.641 0 1 0-2.048 2.564l5.136 4.103.002.002c2.289 1.822 5.91 1.822 8.198 0l1.937-1.543a1.641 1.641 0 1 0-2.045-2.567l-1.936 1.542c-1.092.87-3.017.87-4.109 0l-5.135-4.101Zm-7.588.462c0-2.56.723-4.117 1.732-5.054 1.03-.958 2.622-1.51 4.832-1.51h11.487a1.641 1.641 0 0 0 0-3.282H10.795c-2.713 0-5.225.678-7.065 2.387C1.867 4.938.949 7.482.949 10.667v11.487c0 3.184.918 5.73 2.78 7.459C5.57 31.322 8.083 32 10.796 32h16.41c2.713 0 5.225-.678 7.066-2.387 1.862-1.73 2.78-4.275 2.78-7.46V13.95a1.641 1.641 0 1 0-3.282 0v8.205c0 2.559-.723 4.117-1.732 5.054-1.03.957-2.622 1.51-4.832 1.51h-16.41c-2.21 0-3.801-.553-4.832-1.51-1.01-.937-1.732-2.495-1.732-5.054V10.667Z"
      fill="currentColor"
    />
  </svg>
);
