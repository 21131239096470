export interface BFFErrorResponse extends Response {
  error_message: string;
  error_type: string;
  status_code: number;
}

// TODO: use zod to validate this
export const isBFFErrorResponseGuard = (response: unknown): response is BFFErrorResponse => {
  const castedResponse = response as {
    error_message?: unknown;
    error_type?: unknown;
    status_code?: unknown;
  };
  return (
    !!castedResponse?.error_message && !!castedResponse?.error_type && !!castedResponse?.status_code
  );
};
