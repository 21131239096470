import { MantineProvider } from '@mantine/core';
import { StytchProvider } from '@stytch/react';
import { stytch } from './auth/stytch';
import { RouterAndStateCache } from './router/main';
import { resolveTheme } from './theme/ResolveTheme';

const App = () => {
  return (
    <MantineProvider
      theme={resolveTheme}
      withGlobalStyles
      withNormalizeCSS
    >
      <StytchProvider stytch={stytch}>
        <RouterAndStateCache />
      </StytchProvider>
    </MantineProvider>
  );
};

export default App;
