import { ComponentPropsWithoutRef } from 'react';

export const CircleFilled = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    {...props}
  >
    <circle
      cx="5.5"
      cy="5.5"
      r="5.5"
      fill="currentColor"
    />
  </svg>
);
