import { patchCase } from '../state/patchCase';
import { collectTrackingMetrics, mapMetricsToAllowedPatchFields } from './collectTrackingMetrics';

export const submitTrackingMetrics = async () => {
  const metrics = collectTrackingMetrics();

  if (Object.keys(metrics).length) {
    const mappedMetrics = mapMetricsToAllowedPatchFields(metrics);

    const metricsForm = new FormData();
    for (const [key, value] of Object.entries(mappedMetrics)) {
      metricsForm.append(key, value);
    }

    await patchCase(metricsForm);
  }
};
