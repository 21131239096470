import { Flex, Space, Text } from '@mantine/core';
import { NavigateOptions, useNavigate, useParams } from '@tanstack/router';
import { StepTitle } from '../components/StepTitle';
import { bookAppointmentRoute, staticStepRoute } from '../router/router';

import { MilestoneLogo } from '@/components/icons/MilestoneLogo';
import { MilestoneLogoSmall } from '@/components/icons/MilestoneLogoSmall';
import { MilestonePhone } from '@/components/icons/MilestonePhone';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { GreenScreen } from '@/components/GreenScreen';
import { useBillSize } from './hooks/useBillSize';
import { useLgBreakpoint } from '@/theme/hooks/useLgBreakpoint';
import { PrimaryButton } from '@/components/PrimaryButton';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useConsultationLink } from '@/router/hooks/useConsolutationLink';

export const Milestone = () => {
  const { stepId } = useParams({ from: staticStepRoute.id });
  const isMdBreakpoint = useMdBreakpoint();
  const isLgBreakpoint = useLgBreakpoint();
  const { billSizeUnder15k } = useBillSize();
  const navigate = useNavigate();
  const { mutate, isLoading } = useCaseUpdater({ shouldNavigate: false });
  const consultationLink = useConsultationLink();

  if (!stepId) throw new Error('StepId should be define on milestone step');

  return (
    <Flex
      w="100%"
      wrap="wrap"
      justify="center"
      align="center"
      my="auto"
      rowGap={50}
      direction={isLgBreakpoint ? 'row' : 'column'}
    >
      <GreenScreen
        stepId={stepId}
        primaryButtonLabel={billSizeUnder15k ? 'Continue' : 'Skip and Continue Online'}
        primaryButtonProps={{
          style: {
            display: 'flex',
            minWidth: !isLgBreakpoint ? '100%' : 345,
            justifyContent: 'center',
            zIndex: 1,
          },
        }}
        buttonVariant={billSizeUnder15k ? 'primary' : 'third'}
      >
        <Flex
          maw={610}
          direction="column"
          align="center"
          justify="center"
          style={{
            zIndex: 1,
          }}
        >
          <StepTitle
            isInverted
            ta="left"
            w="100%"
            size={34}
          >
            {billSizeUnder15k
              ? 'Thanks - every detail helps! Just a few more.'
              : 'Based on your bill size, we encourage a free consultation.'}
          </StepTitle>

          <Text
            ta="left"
            c="minor.1"
            size={16}
            mt={16}
          >
            {billSizeUnder15k
              ? 'The last few questions help us identify potential negotiation options and financial discounts to reduce what you owe.'
              : 'Larger bills often have more details to consider. Our consultations are a quick way for us to determine how to lower what you owe.'}
          </Text>
        </Flex>
        <Space h={{ base: 10 }} />
        {!billSizeUnder15k && (
          <PrimaryButton
            align="start"
            type="button"
            isOutlineHover
            isBackgroundTransparentOnHover
            isLoading={isLoading}
            onClick={(e) => {
              e.preventDefault();
              const formData = new FormData();
              formData.append('stepId', stepId);
              formData.append('answer', 'true');
              mutate(formData);
              window.heap?.track('redirect-to-calendly-click-event', {
                stepId,
              });
              const search: NavigateOptions['search'] = {
                appointmentUrl: consultationLink,
              };
              navigate({
                search,
                to: bookAppointmentRoute.id,
              });
            }}
            style={{
              display: 'flex',
              minWidth: !isLgBreakpoint ? '100%' : 345,
              maxWidth: !isLgBreakpoint ? '100%' : 345,
              justifyContent: 'center',
              zIndex: 1,
            }}
          >
            Schedule Consultation Now
          </PrimaryButton>
        )}
      </GreenScreen>
      {
        <Flex
          maw={595}
          miw={200}
          mih={isMdBreakpoint ? 350 : 250}
          direction="column"
          align="center"
          justify="center"
          style={{
            flex: 1,
          }}
        >
          {billSizeUnder15k ? (
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
              }}
            >
              {isLgBreakpoint ? (
                <MilestoneLogo
                  style={{
                    minWidth: '80vw',
                    minHeight: '80vh',
                    margin: '0 0 -5px 0',
                  }}
                />
              ) : (
                <MilestoneLogoSmall
                  style={{
                    minWidth: '100vw',
                  }}
                />
              )}
            </div>
          ) : (
            <MilestonePhone
              style={{
                width: isLgBreakpoint ? 'auto' : 200,
                height: isLgBreakpoint ? 'auto' : 200,
              }}
            />
          )}
        </Flex>
      }
    </Flex>
  );
};
