import { getStepName } from '@/utils/stepUtils';
import { fetchJSON } from '../fetchers/backend-fetchers';
import { queryClient } from './queryClient';
import { CaseState, Step, StepType } from './state.types';

export const updateCase = async (formData: FormData) => {
  const caseId = queryClient.getQueryData<CaseState>(['caseState'])?.case?.id;

  if (!caseId) {
    throw new Error('Case is not found');
  }

  const { stepId, answer, ...answerObject } = Object.fromEntries(formData);

  if (typeof stepId === 'string' && !getStepName(stepId)) {
    throw new Error('known step id should be provided for posing to backend');
  }

  let userResponse:
    | string
    | number
    | {
        [k: string]: FormDataEntryValue;
      }
    | string[];

  const step = queryClient.getQueryData<Step>(['step', stepId]);
  if (step?.type === StepType.multi_choice) {
    userResponse = Object.keys(answerObject);
  } else {
    userResponse = answer !== undefined ? (answer as string | number) : answerObject;
  }

  const updatedCase = await fetchJSON<CaseState>(`/intake/${caseId}`, {
    method: 'POST',
    data: {
      response: userResponse,
      stepId,
    },
  });

  queryClient.setQueryData(['caseState'], updatedCase);

  if (updatedCase.step?.id) {
    queryClient.setQueryData(['step', `${updatedCase.step.id}`], {
      ...updatedCase.step,
      counter: (queryClient.getQueryData<Step>(['step', stepId])?.counter ?? 0) + 1,
      remaining: updatedCase.remaining,
    });
  }

  return updatedCase;
};
