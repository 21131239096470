import {
  ArchetypeAndStressLevel,
  CaseState,
  Feeling,
  stressLevelSchema,
} from '@/state/state.types';

export const mapCaseToArchetypeAndStressLevel = (
  caseState: CaseState | undefined,
): ArchetypeAndStressLevel => {
  if (!caseState)
    throw new Error('case state should be defined at mapCaseToArchetypeAndStressLevel');

  const fields = caseState.case.fields;

  const feeling = Feeling.parse(fields.feeling, {
    errorMap: () => ({
      message: 'feeling should have one of predefined values',
    }),
  });
  const stressLevel = stressLevelSchema.parse(fields['stress-level'], {
    errorMap: () => ({
      message: 'Stress level should be a number between 1 and 5',
    }),
  });

  switch (true) {
    case feeling === 'need-an-expert' && stressLevel < 3: {
      return ArchetypeAndStressLevel.Enum.delegator;
    }
    case feeling === 'need-an-expert' && stressLevel >= 3: {
      return ArchetypeAndStressLevel.Enum.delegator_stressed;
    }
    case feeling === 'wrong-and-unfair': {
      return ArchetypeAndStressLevel.Enum.crusader;
    }
    case feeling === 'overwhelmed': {
      return ArchetypeAndStressLevel.Enum.person_in_distress;
    }
    case feeling === 'researching-options' && stressLevel < 3: {
      return ArchetypeAndStressLevel.Enum.optimizer;
    }
    case feeling === 'researching-options' && stressLevel >= 3: {
      return ArchetypeAndStressLevel.Enum.optimizer_stressed;
    }
    case feeling === 'other' && stressLevel < 3: {
      return ArchetypeAndStressLevel.Enum.other;
    }
    case feeling === 'other' && stressLevel >= 3: {
      return ArchetypeAndStressLevel.Enum.other_stressed;
    }
    default: {
      throw new Error(
        `Can not define validation dependency for feeling: ${feeling} and stress level: ${stressLevel}`,
      );
    }
  }
};
