import { shouldHideProgressBar } from '@/utils/showLayoutElements';
import { Box, useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { ProgressBar as StepProgressBar } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { Step } from '../state/state.types';

function ProgressBar({ stepId }: { stepId: string }) {
  const theme = useMantineTheme();

  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });

  if (!stepId || shouldHideProgressBar(stepId)) return null;

  const [filledBackground, unfilledBackground] = [theme.colors.primary[1], theme.colors.primary[2]];

  const total = (step?.remaining ?? 0) + (step?.counter ?? 0);
  const percent =
    total === 0 || step?.remaining === undefined
      ? 0
      : Math.round(((step?.counter ?? 0) / (total - 1)) * 100);

  return (
    <Box
      pos="absolute"
      top={0}
      style={{
        width: '100%',
      }}
    >
      <StepProgressBar
        percent={percent}
        height={16}
        filledBackground={filledBackground}
        unfilledBackground={unfilledBackground}
      />
    </Box>
  );
}

export { ProgressBar };
