import { FAQs } from '@/components/FAQs';
import { HelpBanner } from '@/components/HelpBanner';
import { StepHeader } from '@/components/StepHeader';
import { ArrowRightCircledFilled } from '@/components/icons/ArrowRightCircledFilled';
import { FAQItems } from '@/content/faqsContent';
import { termsStepContent } from '@/content/termsContent';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { shouldShowHelpBanner } from '@/utils/showLayoutElements';
import { Box, Flex, List, Space, Text, useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/router';
import { Fragment } from 'react';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { StepTitle } from '../components/StepTitle';
import { Amount, BulletItemIconName } from '../content/stepContent.types';
import { staticStepRoute } from '../router/router';
import { Product, Step } from '../state/state.types';
import { useCaseUpdater } from './hooks/useCaseUpdater';
import { useFields } from './hooks/useFields';
import { useBillSize } from './hooks/useBillSize';

export const Terms = () => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();
  const { billSizeUnder15k } = useBillSize();

  const fields = useFields();
  const product = Product.parse(fields?.product);

  const nameToIcon: Partial<Record<BulletItemIconName, JSX.Element>> = {
    ArrowRightCircledFilled: (
      <ArrowRightCircledFilled
        width={24}
        color={theme.colors.primary[0]}
        fill={theme.colors.minor[1]}
      />
    ),
  };

  const { stepId } = useParams({ from: staticStepRoute.id });
  const { data: step } = useQuery<Step>({
    queryKey: ['step', stepId],
    enabled: !!stepId,
  });
  const { onFormSubmit, isLoading } = useCaseUpdater({});

  if (!stepId) throw new Error('StepId should be define at terms step');

  if (!step) throw new Error('Step should be defined at terms page');

  const amount = Amount.parse(step.properties?.amount);
  const { header, bulletList, title, description, aboveButtonText, footnoteList } =
    termsStepContent[product][amount];

  const faq = FAQItems[product];

  return (
    <>
      <StepForm
        onSubmit={onFormSubmit}
        style={{
          marginTop: 0,
          alignItems: 'center',
          backgroundColor: theme.colors.primary[0],
          boxShadow: `0 0 0 100vmax ${theme.colors.primary[0]}`,
        }}
      >
        <input
          name="stepId"
          type="hidden"
          value={stepId}
        />
        <input
          name="answer"
          type="hidden"
          value="true"
        />

        {!!header && (
          <StepHeader
            ta={{ base: 'left', md: 'center' }}
            c="minor.1"
            w={{ base: '100%', sm: 350, md: '100%' }}
            px={{ base: 24, sm: 0 }}
          >
            {header}
          </StepHeader>
        )}

        {!!title &&
          (typeof title === 'string' ? (
            <StepTitle
              ta={{ base: 'left', md: 'center' }}
              c="minor.1"
              w={{ base: '100%', sm: 350, md: '100%' }}
              px={{ base: 24, sm: 0 }}
              mt={40}
            >
              {title}
            </StepTitle>
          ) : (
            title()
          ))}

        {!!description && (
          <Text
            ta={{ base: 'left', md: 'center' }}
            c="minor.1"
            w={{ base: '100%', sm: 350, md: '100%' }}
            px={{ base: 24, sm: 0 }}
            mt={12}
          >
            {description}
          </Text>
        )}

        {!!bulletList?.length && (
          <Box
            p={0}
            mt={24}
            px={{ base: 24, md: 0 }}
          >
            <List
              px={16}
              center
              spacing={16}
              ml={{ md: 20 }}
              styles={{
                itemIcon: {
                  alignSelf: 'start',
                },
                root: {
                  paddingLeft: 0,
                },
              }}
              style={{
                // To override browser default styling
                paddingLeft: !isMdBreakpoint ? 0 : undefined,
              }}
            >
              {bulletList
                .sort((a, b) => {
                  if (a.id > b.id) return 1;
                  else return -1;
                })
                .map((item) => (
                  <List.Item
                    icon={item.iconName && nameToIcon[item.iconName]}
                    key={item.id}
                    fz="sm"
                    c={item.highlight ? 'minor.1' : item.dim ? 'primary.1' : 'primary.2'}
                  >
                    <Text
                      fw={700}
                      strikethrough={item.strike}
                    >
                      {item.text}
                    </Text>

                    <List
                      mt={8}
                      ml={{ base: 8, md: -16 }}
                      center
                      spacing={8}
                      withPadding
                      listStyleType="disc"
                      w={{ base: 250, md: 360 }}
                      style={{
                        // To override browser default styling
                        paddingLeft: !isMdBreakpoint ? 0 : undefined,
                      }}
                    >
                      {item.subBulletList &&
                        item.subBulletList
                          .sort((a, b) => {
                            if (a.id > b.id) return 1;
                            else return -1;
                          })
                          .map((item) => (
                            <List.Item
                              key={item.id}
                              c="minor.1"
                            >
                              <Text
                                strikethrough={item.strike}
                                fz="sm"
                              >
                                {item.text}
                              </Text>
                            </List.Item>
                          ))}
                    </List>
                    {item.footnote &&
                      item.footnote.split('\n').map((el, i) => (
                        <Fragment key={el}>
                          <Text
                            size="sm"
                            c={item.highlight ? 'minor.1' : item.dim ? 'primary.1' : 'primary.2'}
                          >
                            {el}
                          </Text>
                          {i > 0 && <Space h={24} />}
                        </Fragment>
                      ))}
                  </List.Item>
                ))}
            </List>
          </Box>
        )}

        {!!footnoteList?.length && (
          <Box
            p={0}
            mt={24}
            ml={{ base: 30, sm: 58, md: 70 }}
          >
            <List
              type="ordered"
              px={16}
              center
              spacing={16}
              styles={{
                itemIcon: {
                  alignSelf: 'start',
                },
                root: {
                  paddingLeft: 0,
                },
              }}
              style={{
                // To override browser default styling
                paddingLeft: !isMdBreakpoint ? 0 : undefined,
              }}
            >
              {footnoteList
                .sort((a, b) => {
                  if (a.id > b.id) return 1;
                  else return -1;
                })
                .map((item, index) => (
                  <List.Item
                    // icon={item.iconName && nameToIcon[item.iconName]}
                    // 1 and 2 are superscripted
                    icon={index === 0 ? '\u{00B9}' : index === 1 ? '\u{00B2}' : ''}
                    key={item.id}
                    c="minor.1"
                  >
                    <Text
                      strikethrough={item.strike}
                      fz={12}
                    >
                      {item.text}
                    </Text>
                    {item.footnote &&
                      item.footnote.split('\n').map((el, i) => (
                        <Fragment key={el}>
                          <Text
                            size="sm"
                            c="minor.1"
                          >
                            {el}
                          </Text>
                          {i > 0 && <Space h={24} />}
                        </Fragment>
                      ))}
                  </List.Item>
                ))}
            </List>
          </Box>
        )}

        {aboveButtonText && (
          <Text
            c="primary.0"
            fz="sm"
            mt={40}
            px={24}
          >
            {aboveButtonText}
          </Text>
        )}

        <Flex
          w="100%"
          px={{ base: 24, md: 0 }}
          justify="center"
          align="center"
          direction={{ base: 'column', md: 'row' }}
          gap={{ base: 20, md: 32 }}
          mt={!aboveButtonText ? 32 : 12}
        >
          <PrimaryButton
            isOutlineHover
            isDisabled={isLoading}
            isLoading={isLoading}
            align="center"
            w={{ base: '100%', sm: 310, md: 160 }}
            mt={0}
          >
            I am ready
          </PrimaryButton>
        </Flex>
      </StepForm>
      {shouldShowHelpBanner(stepId) && !billSizeUnder15k && <HelpBanner stepId={stepId} />}
      {!!faq?.length && (
        <FAQs
          items={faq}
          backgroundColor={theme.colors.minor[1]}
        />
      )}
    </>
  );
};
