import { nanoid } from 'nanoid';
import { ComponentPropsWithoutRef } from 'react';

export const StepForm = ({ style, ...rest }: ComponentPropsWithoutRef<'form'>) => {
  return (
    <form
      // prevents including all input from different forms in one formData
      id={nanoid()}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      noValidate
      {...rest}
    />
  );
};
