import { validateEnv } from '@/utils/validateEnv';

export const getDashboardURL = () => {
  const url = validateEnv().VITE_DASHBOARD_URL;

  return url;
};

export const DASHBOARD_ROUTES = {
  signIn: 'sign-in',
  authenticate: 'authenticate',
  sessionAuthenticate: 'session-authenticate',
};
