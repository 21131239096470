import { Accordion, Stack, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';
import { ChevronDown } from './icons/ChevronDown';

export interface FAQItem {
  id: string;
  title: string;
  content: string | ReactNode | ReactNode[];
}

interface FAQsProps {
  items: FAQItem[];
  backgroundColor: string;
}

export const FAQs = ({ items, backgroundColor }: FAQsProps) => {
  const theme = useMantineTheme();

  return (
    <Stack
      bg={backgroundColor}
      mt={24}
      spacing={0}
      sx={() => ({
        boxShadow: `0 0 0 100vmax ${backgroundColor}`,
        clipPath: 'inset(0 -100vmax -100vmax -100vmax)',
        marginTop: 48,
      })}
      w="100%"
      mx="auto"
    >
      <Accordion
        w="100%"
        chevron={<ChevronDown color={theme.colors.primary[0]} />}
        styles={(theme) => ({
          control: {
            padding: '24 16',
            fontSize: theme.fontSizes.lg,
            fontWeight: 600,
            borderRadius: '4px',

            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          item: {
            border: `1px solid ${theme.colors.primary[1]}`,
            borderRadius: '4px',
            // to override Mantine merged styles
            borderBottom: `1px solid ${theme.colors.primary[1]}`,
            marginTop: 8,
          },
          content: {
            padding: '0 16 24px 16',
            fontSize: theme.fontSizes.sm,
          },
          label: {
            color: theme.colors.primary[0],
            fontWeight: 600,
          },
        })}
      >
        {items.map((item) => {
          return (
            <Accordion.Item
              value={item.id}
              key={item.id}
            >
              <Accordion.Control>{item.title}</Accordion.Control>
              <Accordion.Panel>{item.content}</Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Stack>
  );
};
