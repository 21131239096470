import { ComponentPropsWithoutRef } from 'react';

export const ArrowUpward = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M5.60136 7.03322C5.60136 7.60149 6.0663 8.06644 6.63458 8.06644H14.4767L5.30172 17.2414C5.20606 17.3371 5.13018 17.4507 5.07842 17.5756C5.02665 17.7006 5 17.8346 5 17.9699C5 18.1051 5.02665 18.2391 5.07842 18.3641C5.13018 18.4891 5.20606 18.6026 5.30172 18.6983C5.39738 18.7939 5.51094 18.8698 5.63592 18.9216C5.76091 18.9734 5.89486 19 6.03014 19C6.16542 19 6.29938 18.9734 6.42436 18.9216C6.54934 18.8698 6.6629 18.7939 6.75856 18.6983L15.9336 9.52328V17.3654C15.9336 17.9337 16.3985 18.3986 16.9668 18.3986C17.5351 18.3986 18 17.9337 18 17.3654V7.03322C18 6.46495 17.5351 6 16.9668 6H6.63458C6.0663 6 5.60136 6.46495 5.60136 7.03322Z"
      fill="currentColor"
    />
  </svg>
);
