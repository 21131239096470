import { queryClient } from '@/state/queryClient';

export const clearCaseStateAndLocalStorage = async () => {
  await queryClient.cancelQueries();

  queryClient.removeQueries(['caseState']);
  queryClient.removeQueries(['step']);

  localStorage.clear();
};
