import { emitHeapEvents } from '@/metrics/emitHeapEvents';
import { useFields } from '@/routes/hooks/useFields';
import { useMdBreakpoint } from '@/theme/hooks/useMdBreakpoint';
import { useSmBreakpoint } from '@/theme/hooks/useSmBreakpoint';
import { Container, Flex, Space, Text, useMantineTheme } from '@mantine/core';
import { TokenResult } from '@square/web-payments-sdk-types';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { useCaseUpdater } from '../routes/hooks/useCaseUpdater';
import { Step } from '../state/state.types';
import { Footnotes } from './Footnotes';
import { SquareLogo } from './icons/SquareLogo';

export const PaymentFormWithTC = ({ step, footnotes }: { step: Step; footnotes?: string[] }) => {
  const theme = useMantineTheme();
  const isMdBreakpoint = useMdBreakpoint();
  const isSmBreakpoint = useSmBreakpoint();

  const fields = useFields();
  const isTCAccepted = fields?.agreement;

  const {
    mutate: updateCase,
    isLoading,
    error,
  } = useCaseUpdater({
    avoidUrlNavigation: true,
    onError: (error) => {
      emitHeapEvents({}, { ['payment-error']: error.message });
    },
  });
  const handleSubmit = async (token: TokenResult) => {
    if (!step?.id) {
      throw new Error('StepId should be provided');
    }

    if (token.status !== 'OK' || !token.token) {
      throw new Error('Card token should be provided');
    }

    if (!step.properties?.locationId || typeof step.properties?.locationId !== 'string') {
      throw new Error('Square location id should be provided');
    }

    if (!step.properties?.appId || typeof step.properties?.appId !== 'string') {
      throw new Error('Square app id should be provided');
    }

    const form = new FormData();
    form.append('stepId', step.id);
    form.append('sourceId', token.token);

    updateCase(form);
  };

  return (
    <>
      {!!error && (
        <Flex
          mt={24}
          p={16}
          sx={(theme) => ({
            background: '#FFE5E5',
            borderRadius: '4px',
            border: `2px solid ${theme.colors.error[0]}`,
          })}
        >
          <Text
            size="sm"
            c="error.0"
            weight={600}
          >
            {
              'Card was declined, or had an invalid expiration date/CVV. Please try again, or try another card.'
            }
          </Text>
        </Flex>
      )}

      <Container
        mt={24}
        w="100%"
        p={0}
      >
        <PaymentForm
          // We ensured that appId and locationId are strings in the component above
          // TS can't infer that in JSX for now
          applicationId={step.properties?.appId as string}
          locationId={step.properties?.locationId as string}
          cardTokenizeResponseReceived={handleSubmit}
          formProps={{
            style: { display: 'flex', flexDirection: 'column' },
          }}
        >
          <CreditCard
            buttonProps={{
              isLoading: isLoading || !isTCAccepted,
              css: {
                color: theme.colors.primary[0],
                backgroundColor: theme.colors.secondary[0],
                borderRadius: '50px',
                fontFamily: theme.fontFamily,
                fontSize: theme.fontSizes.sm,
                fontWeight: 700,
                lineHeight: theme.lineHeight,
                padding: `0.375rem 2.5rem`,
                width: isMdBreakpoint || isSmBreakpoint ? 'auto' : '100%',
                alignSelf: isMdBreakpoint || isSmBreakpoint ? 'start' : 'center',
                border: `1px solid ${theme.colors.secondary[0]}`,
                boxShadow: `0px 2px 4px ${theme.colors.primary[0]}1A`,

                '&:disabled': {
                  color: theme.colors.neutral[1],
                  backgroundColor: theme.colors.neutral[4],
                  border: `1px solid ${theme.colors.neutral[4]}`,
                },
              },
            }}
          >
            Confirm payment
          </CreditCard>

          <Flex
            justify="start"
            mt={20}
          >
            <Text
              color="primary.1"
              size="sm"
              mr={6}
            >
              Transaction secured with
            </Text>
            <SquareLogo
              width={79}
              color={theme.colors.primary[1]}
            />
          </Flex>
        </PaymentForm>
        <Space h={isMdBreakpoint || isSmBreakpoint ? 20 : 8}></Space>
      </Container>
      {!!footnotes?.length && <Footnotes footnotes={footnotes} />}
    </>
  );
};
