import { StepTitle } from '@/components/StepTitle';
import { Google } from '@/components/icons/Google';
import { mapCaseToArchetypeAndStressLevel } from '@/utils/mapCaseToArchetypeAndStressLevel';
import { isEmailStep } from '@/utils/stepUtils';
import { validateEnv } from '@/utils/validateEnv';
import { Carousel } from '@mantine/carousel';
import { Anchor, Box, Flex, Image, Text, Title, Transition } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/router';
import Autoplay from 'embla-carousel-autoplay';
import { useMemo, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import { z } from 'zod';
import { ErrorBanner } from '../components/ErrorBanner';
import { PrimaryButton } from '../components/PrimaryButton';
import { StepForm } from '../components/StepForm';
import { StepSubtitle } from '../components/StepSubtitle';
import { testimonialSlidesContent } from '../content/testimonialContent';
import { isBFFErrorResponseGuard } from '../fetchers/fetcher.types';
import { openStepRoute } from '../router/router';
import { CaseState, FieldName } from '../state/state.types';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';
import { useSmBreakpoint } from '../theme/hooks/useSmBreakpoint';
import { useAccountCreation } from './hooks/useAccountCreation';
import { useOpenStepResponse } from './hooks/useOpenStepResponse';
import { ContentContainer } from '@/components/ContentContainer';
import { WhyDoWeAsk } from '@/components/WhyDoWeAsk';
import { whyActivateContent } from '@/content/whyActivateContent';
import { Input } from '@/components/ui/Input';

const emailFields = z.object({
  [FieldName.email]: z
    .string()
    .email({ message: 'Emails only, please! (example: name@email.com)' }),
});

type EmailFields = z.infer<typeof emailFields>;

const emailFieldList = [{ name: emailFields.keyof().options[0] }];

export const Email = () => {
  const isMdBreakpoint = useMdBreakpoint();
  const isSmBreakpoint = useSmBreakpoint();

  const { stepId } = useParams({ from: openStepRoute.id });
  const { data: caseState } = useQuery<CaseState>(['caseState']);

  const { onFormSubmit, isLoading, error, isSuccess } = useAccountCreation();

  // TODO: Improve Module type
  const images = useMemo(
    () =>
      import.meta.glob<{ default: string }>(
        '../assets/img/*.webp',
        // to resolve promise https://vitejs.dev/guide/features.html#glob-import
        {
          eager: true,
        },
      ),
    [],
  );

  const autoplay = useRef(Autoplay({ delay: 5500, jump: true, stopOnInteraction: false }));
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

  const form = useForm<EmailFields>({
    validate: zodResolver(emailFields),
    validateInputOnBlur: true,
    initialValues: {
      email: '',
    },
  });

  useOpenStepResponse(emailFieldList, form.setFieldValue);

  if (!stepId || !isEmailStep(stepId)) throw new Error('Current stepId should be email step id');
  if (!caseState) throw new Error('Case state should be defined at email step');

  const archetypeAndStressLevel = mapCaseToArchetypeAndStressLevel(caseState);
  const testimonialSlides = testimonialSlidesContent[archetypeAndStressLevel];

  const googleSsoUrl = validateEnv().VITE_GOOGLE_SSO_URL;
  const redirectQueryParams = `&login_redirect_url=${window.location.origin}/sso-authenticate&signup_redirect_url=${window.location.origin}/sso-authenticate`;

  const { title: whyActivateTitle, description: whyActivateDescription } =
    whyActivateContent?.[stepId] ?? {};

  return (
    <ContentContainer>
      <StepForm
        onSubmit={onFormSubmit}
        style={{
          maxWidth: 516,
          alignSelf: 'center',
        }}
      >
        <input
          name="stepId"
          type="hidden"
          value={stepId}
        />
        <StepTitle>What&apos;s your email?</StepTitle>
        <StepSubtitle>
          You&apos;ll use this to save your progress and review your savings strategy after.
        </StepSubtitle>
        {isBFFErrorResponseGuard(error) && error.error_type === 'invalid_email' && (
          <ErrorBanner errorText={error.error_message} />
        )}
        <Input
          type="email"
          name="answer"
          inputProps={form.getInputProps('email')}
          placeholder="yourname@email.com"
          isLoading={isLoading}
          error={form.errors['email']}
        />
        <Text
          color="primary.0"
          size="sm"
          mt={8}
          w={{ base: '100%', md: 'auto' }}
        >
          By creating an account, you agree to our{' '}
          <Anchor
            href="https://www.resolvemedicalbills.com/terms-of-use"
            fz="sm"
            target="_blank"
            sx={{
              textDecoration: 'underline',
            }}
          >
            Terms of Use
          </Anchor>
          .
        </Text>
        <PrimaryButton
          isLoading={isLoading || isSuccess}
          isDisabled={!form.isValid('email') || isLoading}
          align={isMdBreakpoint || isSmBreakpoint ? 'start' : 'center'}
          mt={{ base: 12, sm: 24, md: 24 }}
          mx="auto"
          maw={343}
          w="100%"
        >
          Submit
        </PrimaryButton>

        <Flex
          direction="column"
          justify="center"
          align="center"
          gap={10}
          mt={20}
        >
          <Text
            color="primary.0"
            size="sm"
            mb={{ base: 12, sm: 14, md: 14 }}
            w={{ base: '100%', md: 'auto' }}
            align="center"
          >
            Or continue with:
          </Text>
          {!!googleSsoUrl && (
            <Anchor
              href={`${googleSsoUrl}${redirectQueryParams}`}
              rel="noreferrer"
              underline={false}
              className="sso-link"
            >
              <Google />
              <Text
                color="primary.0"
                size="sm"
              >
                Google
              </Text>
            </Anchor>
          )}
          {/* <Anchor
            href="https://test.stytch.com/v1/public/oauth/facebook/start?public_token=public-token-test-561de4f2-bf3b-489a-abef-b6e03724befd"
            rel="noreferrer"
            underline={false}
            className="sso-link"
          >
            <Facebook />
            <Text
              color="primary.0"
              size="sm"
            >
              Facebook
            </Text>
          </Anchor> */}
        </Flex>
      </StepForm>
      <Carousel
        withControls={false}
        slideGap={0}
        withIndicators
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        onSlideChange={setCurrentSlideNumber}
        mt={{ base: 24, sm: 40, md: 40 }}
        align="start"
        styles={(theme) => ({
          indicator: {
            width: 6,
            height: 6,
            borderRadius: '50%',
            backgroundColor: theme.colors.primary[2],
            marginTop: 16,

            '&[data-active]': {
              backgroundColor: theme.colors.tertiary[1],
            },
          },
        })}
      >
        <>
          {testimonialSlides.map((testimonial, index) => (
            <Carousel.Slide
              key={testimonial.name}
              h={testimonial.size === 'large' ? 464 : 344}
              sx={(theme) => ({
                borderRadius: '20px',
                backgroundColor: `${theme.colors.minor[0]}`,
                cursor: 'default',
              })}
            >
              <Transition
                mounted={index === currentSlideNumber}
                transition="fade"
                duration={500}
                timingFunction="ease-in-out"
              >
                {(styles) => {
                  return (
                    <Flex
                      direction="column"
                      align="center"
                      style={{ ...styles }}
                      pt={20}
                    >
                      <Image
                        src={images[`../assets/img/${testimonial.imageFileName}`].default}
                        width={testimonial.size === 'large' ? 295 : 72}
                      />
                      <StepSubtitle
                        size="xl"
                        color="primary.0"
                        mt={20}
                        ta="center"
                        w={300}
                        h={72}
                      >
                        &quot;{testimonial.quote}&quot;
                      </StepSubtitle>
                      <Box
                        mt={24}
                        py={4}
                        px={8}
                        bg="minor.1"
                        sx={(theme) => ({
                          borderRadius: '4px',
                          border: `1px solid ${theme.colors.minor[1]}`,
                        })}
                      >
                        <Text
                          color="primary.0"
                          size={20}
                        >
                          {testimonial.name}, {testimonial.saving}
                        </Text>
                      </Box>
                      <Title
                        order={1}
                        fz={24}
                        color="primary.0"
                        align="center"
                        mt={8}
                        lh="32px"
                        mb={44}
                      >
                        We can help you too.
                      </Title>
                    </Flex>
                  );
                }}
              </Transition>
            </Carousel.Slide>
          ))}
        </>
      </Carousel>
      <Text
        size="sm"
        color="primary.0"
        ta={{ base: 'left', sm: 'center' }}
        mt={{ base: 12, sm: 20, md: 20 }}
      >
        Testimonials provided by real Resolve customers. Likeness has been changed to protect
        patient identities.
      </Text>
      {whyActivateTitle && whyActivateDescription && (
        <Flex
          align="center"
          justify="center"
          mt="50px"
        >
          <WhyDoWeAsk
            title={whyActivateTitle}
            description={whyActivateDescription}
            stepId={stepId}
          />
        </Flex>
      )}
    </ContentContainer>
  );
};
