import { Case } from '@/state/state.types';
import { validateHeapSafely } from './heap.types';

export const emitHeapUserCreatedEvent = (
  prevCase: Case | undefined,
  nextCase: Case | undefined,
) => {
  if (!prevCase || !nextCase) return;
  if (!validateHeapSafely()) return;

  if (detectPatientIdArrived(prevCase, nextCase)) {
    window.heap.track('user-created-event');
  }
};

const detectPatientIdArrived = (prevCase: Case, nextCase: Case) => {
  const isPatientIdArrived = !prevCase.patientId && !!nextCase.patientId;
  return isPatientIdArrived;
};
