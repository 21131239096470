import { z } from 'zod';
import { CheckoutComplete, Product } from '../state/state.types';
import {
  BulletItem,
  StepContent,
  StepRecordContentByAmountByDependency,
  StepRecordContentByDependency,
} from './stepContent.types';

export const checkoutContent: StepRecordContentByDependency<CheckoutComplete> = {
  false: {
    header: 'My To-Do List',
    description: 'Once the below is completed, your Advocate will reach out to start the process.',
  },
  true: {
    header: 'Welcome to Resolve!',
    description:
      "Thanks again for putting your trust in Resolve. We're excited to get started lowering your medical bills. Our team is currently reviewing and you can expect an email from your Advocate shortly.",
  },
};

const protectBulletList: BulletItem[] = [
  {
    id: '1',
    text: 'No savings found? Your deposit is refunded automatically.',
    iconName: 'ArrowRight',
    boldWordNumber: 3,
  },
];

const pointInTime_10 = {
  9900: {
    title: 'Start the Process',
    bulletList: [
      {
        id: '1',
        text: 'Make a $99 no risk deposit to get started',
      },
      {
        id: '2',
        text: "If we don't find you savings, we'll issue you a refund.",
      },
      {
        id: '3',
        text: 'If we do find you savings, your deposit is applied to your 20% success fee.',
      },
    ],
  },
  25000: {
    title: 'Start the Process',
    description:
      "Enter your card information. It is fully refundable if we don't find you any savings.",
  },
};

const pointInTime_25 = {
  9900: {
    title: 'Start the Process',
    bulletList: [
      {
        id: '1',
        text: 'Make a $99 no risk deposit to get started.',
      },
      {
        id: '2',
        text: "If we don't find you savings, we'll issue you a refund.",
      },
      {
        id: '3',
        text: 'If we do find you savings, your deposit is applied to your 20% success fee.',
      },
    ],
  },
  25000: {
    title: 'Start the Process',
    description:
      "Enter your card information. It is fully refundable if we don't find you any savings.",
  },
};

export const paymentCardContent: StepRecordContentByAmountByDependency<Product> = {
  'bill-reduction-25': pointInTime_25,
  'bill-reduction-10': pointInTime_10,
  subscription: {
    4999: {
      title: 'Your $49.99 monthly fee',
      description:
        "Enter your card information. You can cancel at any time. We'll work on bills as long as your monthly subscription is active.",
    },
  },
  ezfile: {
    4900: {
      title: '$49 per provider',
      bulletList: protectBulletList,
    },
  },
};

export const partnershipContent = (
  partnership: string,
  activatedServices: boolean,
): StepContent => ({
  title: 'Activate Resolve Services',
  description: activatedServices
    ? 'Thanks for getting started with Resolve. Your advocate will reach out to you within 1-2 business days to follow up with next steps'
    : `Thanks to our partnership with ${partnership}, you aren't required to make a deposit today. Simply activate Resolves services and our team will get to work reducing your bills.`,
});

const hipaaDefaultStepContent: StepContent = {
  title: 'Let us represent you',
  description:
    'Sign a HIPAA authorization that will let us speak to hospitals and insurance companies on your behalf.',
};

export const hipaaCardContent: StepRecordContentByDependency<Product> = {
  'bill-reduction-25': hipaaDefaultStepContent,
  'bill-reduction-10': hipaaDefaultStepContent,
  subscription: hipaaDefaultStepContent,
  ezfile: {},
};

export const Environment = z.enum(['production', 'development']);

type Environment = z.infer<typeof Environment>;
export const tcLinkContent: Record<Environment, Record<Product, Record<number, string>>> = {
  development: {
    'bill-reduction-25': {
      9900: 'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
      25000:
        'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
    },
    'bill-reduction-10': {
      9900: 'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
      25000:
        'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
    },
    subscription: {
      4999: 'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
    },
    ezfile: '',
  },
  production: {
    'bill-reduction-25': {
      9900: 'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
      25000:
        'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
    },
    'bill-reduction-10': {
      9900: 'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
      25000:
        'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
    },
    subscription: {
      4999: 'https://resolvemedicalbills-questionnaire-production.s3.us-east-1.amazonaws.com/static-assets/tc_24_07.pdf',
    },
    ezfile: '',
  },
};

export const noDepositPartnershipTC =
  'https://resolvemedicalbills-questionnaire-production.s3.amazonaws.com/static-assets/Resolve+Contract+and+Work+Statement+%5BPartnerships%5D.pdf';
