import { ZodError } from 'zod';
import { BFFErrorResponse, isBFFErrorResponseGuard } from '../fetchers/fetcher.types';
import { ErrorSearchParams, errorRoute, router } from '../router/router';

export const navigateToErrorRoute = (
  error: Error | BFFErrorResponse | ZodError,
  stack?: string,
  networkPayload?: object,
) => {
  let search: ErrorSearchParams;

  if (isBFFErrorResponseGuard(error)) {
    search = {
      message: error.error_message,
      type: error.error_type,
      status: error.status_code,
      stack,
      networkPayload,
    };
  } else {
    search = {
      message: error.message,
      type: error.name,
      stack,
      networkPayload,
    };
  }

  router.navigate({
    to: errorRoute.id,
    search,
  });
};
