import { z } from 'zod';
import {
  bookAppointmentRoute,
  choiceStepRoute,
  multiChoiceStepRoute,
  openStepRoute,
  partialStepRoute,
  staticStepRoute,
  stepSubmitRoute,
} from './router';

export type NextPath =
  | typeof choiceStepRoute.id
  | typeof openStepRoute.id
  | typeof multiChoiceStepRoute.id
  | typeof staticStepRoute.id
  | typeof bookAppointmentRoute.id
  | typeof stepSubmitRoute.id
  | typeof partialStepRoute.id;

export const isNextPathGuard = (path: unknown): path is NextPath => {
  return [
    choiceStepRoute.id,
    openStepRoute.id,
    multiChoiceStepRoute.id,
    staticStepRoute.id,
    bookAppointmentRoute.id,
    stepSubmitRoute.id,
    partialStepRoute.id,
  ].includes(path as NextPath);
};

export const urlScheme = z.string().url({ message: 'Invalid URL' });

export type Url = z.infer<typeof urlScheme>;
