import { Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithoutRef } from 'react';
import { useMdBreakpoint } from '../theme/hooks/useMdBreakpoint';

export type PrimaryButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonProps & {
    align?: 'start' | 'center' | 'end';
    isDisabled?: boolean;
    isInverted?: boolean;
    isLoading?: boolean;
    isOutlineHover?: boolean;
    isOutline?: boolean;
    isBackgroundTransparentOnHover?: boolean;
  };

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const isMdBreakpoint = useMdBreakpoint();
  const {
    isLoading,
    isDisabled,
    align,
    isInverted,
    isOutlineHover,
    isOutline,
    isBackgroundTransparentOnHover,
    ...rest
  } = props;

  return (
    <Button
      type={props.type ?? 'submit'}
      bg={isOutline ? 'primary.0' : isInverted ? 'primary.0' : 'secondary.0'}
      mt={24}
      // NOTE: there is a race conditions
      // https://github.com/mantinedev/mantine/issues/3719
      w={{ base: '100%', sm: 'auto', md: 'auto' }}
      size="md"
      radius="3.125rem"
      loaderPosition="left"
      loading={isLoading}
      disabled={isDisabled}
      loaderProps={{
        color: isInverted ? 'secondary.0' : isDisabled ? 'primary.1' : 'primary.0',
      }}
      px={70}
      styles={(theme) => ({
        root: {
          alignSelf: align ? align : isMdBreakpoint ? 'end' : 'center',
          boxShadow: `0px 2px 4px ${theme.colors.primary[0]}1A`,
          border: `1px solid ${theme.colors.secondary[0]}`,
          transition: 'background 250ms linear, border 250ms linear, color 250ms linear',
          color: isOutline
            ? theme.colors.secondary[0]
            : isInverted
            ? theme.colors.secondary[0]
            : isDisabled
            ? theme.colors.primary[1]
            : theme.colors.primary[0],

          '&:hover': {
            backgroundColor: isBackgroundTransparentOnHover
              ? 'transparent'
              : theme.colors.primary[0],
            color: theme.colors.secondary[0],
            border: isOutline
              ? `1px solid ${theme.colors.secondary[0]}`
              : isOutlineHover
              ? `1px solid ${theme.colors.secondary[0]}`
              : `1px solid ${theme.colors.primary[0]}`,
          },

          '&:disabled': {
            color: theme.colors.primary[1],
            backgroundColor: theme.colors.primary[2],
          },

          '&[data-loading]': {
            backgroundColor: theme.colors.primary[2],
            color: theme.colors.primary[0],
            border: `1px solid ${theme.colors.primary[2]}`,
          },

          // override Mantine overlay style
          '&[data-loading]::before': {
            backgroundColor: 'transparent',
          },
        },
        label: {
          color: 'inherit',
          fontWeight: 700,
        },
      })}
      {...rest}
    />
  );
};
