import { ArchetypeAndStressLevel } from '@/state/state.types';
import { StepRecordContentByDependency } from './stepContent.types';

export interface TestimonialSlideContent {
  imageFileName?: string;
  quote: string;
  name: string;
  saving: string;
  size?: 'small' | 'large';
}

type NameSize =
  | 'GarrisonSmall'
  | 'GarrisonLarge'
  | 'MathewSmall'
  | 'KyleSmall'
  | 'JameSmall'
  | 'HeatherSmall'
  | 'RyanSmall'
  | 'JennySmall'
  | 'BrettSmall'
  | 'KarenSmall'
  | 'LouiseSmall'
  | 'AshleySmall'
  | 'TeresaSmall'
  | 'SonjaLarge'
  | 'SamanthaLarge'
  | 'LidiaLarge';

const garrison = {
  quote: '100% legitimate. 100% worth it. Reduced my debt by over 95%.',
  name: 'Garrison',
  saving: 'Saved $35,000',
};

export const testimonialSlides: Record<NameSize, TestimonialSlideContent> = {
  GarrisonSmall: {
    imageFileName: 'garrison-small.webp',
    size: 'small',
    ...garrison,
  },
  GarrisonLarge: {
    imageFileName: 'garrison-large.webp',
    size: 'large',
    ...garrison,
  },
  MathewSmall: {
    imageFileName: 'mathew-small.webp',
    size: 'small',
    quote: 'Professional and very good ... they get results and get them quickly.',
    name: 'Mathew',
    saving: 'Saved $86,245.59',
  },
  KyleSmall: {
    imageFileName: 'kyle-small.webp',
    size: 'small',
    quote: 'After I chose Resolve, I never had to deal with the hospital again.',
    name: 'Kyle',
    saving: 'Saved $17,195.29',
  },
  JameSmall: {
    imageFileName: 'jame-small.webp',
    size: 'small',
    quote: 'Very impressed. Reduced my bill by 67% and totally took the stress off of me.',
    name: 'Jame',
    saving: 'Saved $8,097.01',
  },
  HeatherSmall: {
    imageFileName: 'heather-small.webp',
    size: 'small',
    quote:
      'In a month and a half ... (they) did what I tried for 15 months and eliminated our bill.',
    name: 'Heather',
    saving: 'Saved $82,398.57',
  },
  RyanSmall: {
    imageFileName: 'ryan-small.webp',
    size: 'small',
    quote:
      'They handled everything from analyzing the bill to advocating for financial aid on our behalf.',
    name: 'Ryan',
    saving: 'Saved over $7,000',
  },
  JennySmall: {
    imageFileName: 'jenny-small.webp',
    size: 'small',
    quote:
      'For two years the hospital would not take a dime off... in 2 weeks (Resolve) worked a deal.',
    name: 'Jenny',
    saving: 'Saved $26,495.09',
  },
  BrettSmall: {
    imageFileName: 'brett-small.webp',
    size: 'small',
    quote:
      'Professional, knowledgeable, and encouraging…a huge relief for my family, and our future.',
    name: 'Brett',
    saving: 'Saved $159,323.72',
  },
  KarenSmall: {
    imageFileName: 'karen-small.webp',
    size: 'small',
    quote: `We'd be in debt during all our retirement years if not for the relentless efforts (they) put in.`,
    name: 'Karen',
    saving: 'Saved $54,244.70',
  },
  LouiseSmall: {
    imageFileName: 'louise-small.webp',
    size: 'small',
    quote: `(Resolve) saved us from bankruptcy.`,
    name: 'Louise',
    saving: 'Saved $31,618.04',
  },
  AshleySmall: {
    imageFileName: 'ashley-small.webp',
    size: 'small',
    quote: `I tried fighting (the bill) myself. I felt powerless … I couldn't have done it without Resolve.`,
    name: 'Ashley',
    saving: 'Saved $39,202.80',
  },
  TeresaSmall: {
    imageFileName: 'teresa-small.webp',
    size: 'small',
    quote: `(They) removed a Hospital bill I'd been fighting for 3 years ... to a zero balance.`,
    name: 'Teresa',
    saving: 'Saved $23,092',
  },
  SonjaLarge: {
    imageFileName: 'sonja-large.webp',
    size: 'large',
    quote: `The peace of mind we had […] was life changing. We are still in shock…`,
    name: 'Sonja',
    saving: 'Saved $542,409.49',
  },
  SamanthaLarge: {
    imageFileName: 'samantha-large.webp',
    size: 'large',
    quote: `Resolve took a load of worry off of our minds!`,
    name: 'Samantha',
    saving: 'Saved $25,470.36',
  },
  LidiaLarge: {
    imageFileName: 'lidia-large.webp',
    size: 'large',
    quote: `I (had) been stressed about this for months, I couldn't be more thankful to Resolve.`,
    name: 'Lidia',
    saving: 'Saved $5,344.01',
  },
};

const delegatorSlides = [
  testimonialSlides.GarrisonSmall,
  testimonialSlides.MathewSmall,
  testimonialSlides.KyleSmall,
  testimonialSlides.JameSmall,
];

const delegatorStressedSlides = [
  testimonialSlides.GarrisonSmall,
  testimonialSlides.BrettSmall,
  testimonialSlides.KarenSmall,
  testimonialSlides.LouiseSmall,
];

export const testimonialSlidesContent: StepRecordContentByDependency<
  ArchetypeAndStressLevel,
  TestimonialSlideContent[]
> = {
  delegator: delegatorSlides,
  delegator_stressed: delegatorStressedSlides,
  crusader: delegatorStressedSlides,
  person_in_distress: [
    testimonialSlides.GarrisonLarge,
    testimonialSlides.SonjaLarge,
    testimonialSlides.SamanthaLarge,
    testimonialSlides.LidiaLarge,
  ],
  optimizer: [
    testimonialSlides.GarrisonSmall,
    testimonialSlides.HeatherSmall,
    testimonialSlides.RyanSmall,
    testimonialSlides.JennySmall,
  ],
  optimizer_stressed: [
    testimonialSlides.GarrisonSmall,
    testimonialSlides.HeatherSmall,
    testimonialSlides.AshleySmall,
    testimonialSlides.TeresaSmall,
  ],
  other: delegatorSlides,
  other_stressed: delegatorStressedSlides,
};
