import { Anchor, Flex, List, Text } from '@mantine/core';
import { FAQItem } from '../components/FAQs';
import { Product } from '../state/state.types';

const protectItems: FAQItem[] = [
  {
    id: 'save',
    title: 'How much can you save me?',
    content:
      "On average we save our patients 60% off of their bill. Your personal savings will be dependent upon the details of your situation. We'll create a plan specific to your situation to maximize your savings found.",
  },
  {
    id: 'fix',
    title: 'How will Resolve fix my problem?',
    content: (
      <Flex direction="column">
        <Text mb="24px">
          We leave no stone unturned as we explore possible savings. We&apos;ll listen as you share
          your story with us to learn about your situation. From there we&apos;ll look at every
          angle and potential solution that provides the best savings possible and formulate a path
          forward specific to you.
        </Text>
        <Text mb="8px">
          Often we&apos;ll use one or a combination of the following three methods:
        </Text>
        <List
          type="ordered"
          size="sm"
        >
          <List.Item>
            Insurance Appeals-We will review your policy and EOB&apos;s when necessary to make sure
            nothing was missed. When applicable, we&apos;ll contact your insurance company and
            handle the appeals process for claims wrongfully denied.
          </List.Item>
          <List.Item pt="8px">
            Financial Aid- Most hospitals offer help for patients with enormous bills. We&apos;ll
            look to see if there are any options available to you here.
          </List.Item>
          <List.Item pt="8px">
            Negotiations- We&apos;ll review your bills for errors, duplicate charges and inflated
            pricing. Then we&apos;ll fight to negotiate and ensure you&apos;re not paying more than
            you should. We&apos;ll even go as high as the CFO or CEO of the hospital if we have to!
          </List.Item>
        </List>
      </Flex>
    ),
  },
  {
    id: 'secure',
    title: 'Is my information secure?',
    content:
      'Your security is important to us. Your information is stored behind bank level encryption.',
  },
  {
    id: 'cost',
    title: 'How much does this cost?',
    content:
      "Pricing is determined based on the savings we find for you. We charge a percent of the savings we find. To learn more about our pricing please tell us a little more about your situation by filling out our questionnaire here. If you'd rather speak with one of our Patient Advisors you can do so by providing your contact information here, or calling our main line at 877-245-4244",
  },
  {
    id: 'documents',
    title: 'What documents does Resolve need to start?',
    content:
      "To get started, we'll need your bills. It's helpful if you can provide any other important statements, EOBs, documents or letters that you've received concerning your medical bills.",
  },
  {
    id: 'how',
    title: 'How does it work?',
    content: (
      <Flex direction="column">
        <Text mb="24px">
          Medical bills are complicated, but handing them over to your Advocate team is simple.
        </Text>
        <Text mb="8px">Here&apos;s how our process works:</Text>
        <List
          type="ordered"
          size="sm"
        >
          <List.Item pt="8px">Hand over all of your bills and let our experts take over.</List.Item>
          <List.Item pt="8px">
            We&apos;ll dive into the details of your situation to find maximum savings and organize
            your bills. We&apos;ll take over communications with the hospital and insurance
            companies so you can rest easy. We&apos;ll keep you updated along the way and ensure you
            know what&apos;s going on.
          </List.Item>
          <List.Item pt="8px">
            You receive a discounted bill. Once your balance has been reduced, you&apos;ll pay your
            discounted bill directly with the hospital.
          </List.Item>
        </List>
      </Flex>
    ),
  },
];

const subscriptionItems: FAQItem[] = [
  {
    id: 'time',
    title: 'How long will it take to find resolution for my charges?',
    content:
      'The bill resolution process can vary based on the responsiveness we receive from insurance companies and hospitals. On average it takes about 4 months to resolve a bill.',
  },
  {
    id: 'included',
    title: 'What is included in “bill management”?',
    content:
      "We'll review your bills to identify duplicate charges, errors and inflated pricing. If there are issues such as wrongfully denied claims, we'll get to the bottom of it. We'll spend time on the phone communicating with the hospital and insurance companies so you don't have to. We'll bring clarity and organization to your bills, and you'll receive a clear monthly statement with a breakdown of who you owe, how much you owe, and any savings found.",
  },
  {
    id: 'fix',
    title: 'How will Resolve fix my problem?',
    content: (
      <Flex direction="column">
        <Text mb="24px">
          We leave no stone unturned as we explore possible savings. We&apos;ll listen as you share
          your story with us to learn about your situation. From there we&apos;ll look at every
          angle and potential solution that provides the best savings possible and formulate a path
          forward specific to you.
        </Text>
        <Text mb="8px">
          Often we&apos;ll use one or a combination of the following three methods:
        </Text>
        <List
          type="ordered"
          size="sm"
        >
          <List.Item>
            Insurance Appeals - We will review your policy and EOB&apos;s when necessary to make
            sure nothing was missed. When applicable, we&apos;ll contact your insurance company and
            handle the appeals process for claims wrongfully denied.
          </List.Item>
          <List.Item pt="8px">
            Financial Aid - Most hospitals offer help for patients with enormous bills. We&apos;ll
            look to see if there are any options available to you here.
          </List.Item>
          <List.Item pt="8px">
            Negotiations - We&apos;ll review your bills for errors, duplicate charges and inflated
            pricing. Then we&apos;ll fight to negotiate and ensure you&apos;re not paying more than
            you should. We&apos;ll even go as high as the CFO or CEO of the hospital if we have to!
          </List.Item>
        </List>
      </Flex>
    ),
  },
  {
    id: 'secure',
    title: 'Is my information secure?',
    content:
      'Your security is important to us. Your information is stored behind bank level encryption.',
  },
  {
    id: 'locked',
    title: 'Am I locked into the monthly fee?',
    content:
      'We recommend planning to keep an active subscription for at least 6 months to allow ample time for bill resolution and best results. However, you can cancel your subscription at any time without penalty.',
  },
  {
    id: 'multiple-persons',
    title: 'What if I have more than one person in my household with medical bills?',
    content: (
      <Text>
        Our Family Shield program will cover up to 4 individuals in your household.{' '}
        <Anchor
          href="mailto:advocate@resolvemedicalbills.com?subject=More than person with medical bills"
          target="_blank"
        >
          Contact us
        </Anchor>{' '}
        to know more.
      </Text>
    ),
  },
];

const ezfileItems: FAQItem[] = [
  {
    id: 'aid',
    title: 'What is Financial Aid?',
    content:
      "Financial Aid is a program providing aid for patients who fall below a specific financial threshold. The financial threshold can vary from hospital to hospital. If you qualify for Financial Aid, the hospital will often write off all or most of your bill. Financial Aid is not a loan so you don't have to pay it back. ",
  },
  {
    id: 'save',
    title: 'How much can Resolve save me?',
    content:
      'Financial Aid discounts can range from 50%-100%. Each hospital has its own Financial Aid policy and the savings found will vary based on the policies of the hospital you visited.',
  },
  {
    id: 'secure',
    title: 'Is my information secure?',
    content:
      'Your security is important to us. Your information is stored behind bank level encryption.',
  },
  {
    id: 'cost',
    title: 'How much does this cost?',
    content:
      'We charge $49 per provider. This means that if you have 5 bills at one hospital, you will only pay $49 to use our EZ-file program.',
  },
  {
    id: 'documents',
    title: 'What documents does Resolve need to start?',
    content:
      "To get started, we'll need your bills, taxes from the prior two years, and current paystubs. You can snap a photo of these documents and easily upload them through our EZ-file program.",
  },
];

export const FAQItems: Partial<Record<Product, FAQItem[]>> = {
  'bill-reduction-25': protectItems,
  'bill-reduction-10': protectItems,
  subscription: subscriptionItems,
  ezfile: ezfileItems,
};
