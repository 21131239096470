import { useStytch } from '@stytch/react';
import { useMutation } from '@tanstack/react-query';
import { SyntheticEvent } from 'react';
import { createAccount } from '../../fetchers/bff-fetchers';
import { BFFErrorResponse, isBFFErrorResponseGuard } from '../../fetchers/fetcher.types';
import { ResponseCreateAccount } from '../../state/state.types';
import { updateStytchSession } from '../../utils/updateStytchSession';
import { useCaseUpdater } from './useCaseUpdater';
import { useMagicLinkSending } from './useMagicLinkSending';
import { DASHBOARD_ROUTES, getDashboardURL } from '@/utils/dashboardUrl';

export const useAccountCreation = () => {
  const { mutate: updateCase } = useCaseUpdater({});
  const { mutate: sendMagicLink } = useMagicLinkSending();
  const stytch = useStytch();

  const { mutate, isLoading, error, isSuccess } = useMutation({
    mutationFn: createAccount,
    onError: (error: BFFErrorResponse | Error, formData) => {
      // Expected error types
      if (isBFFErrorResponseGuard(error)) {
        if (error.error_type === 'duplicate_email') {
          sendMagicLink(formData);
        }

        if (error.error_type === 'legacy_user') {
          window.location.assign(`${getDashboardURL()}/${DASHBOARD_ROUTES.signIn}`);
        }
      }
    },
  });

  const onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    if (stytch.session.getTokens()?.session_token) {
      updateCase(formData);
    } else {
      mutate(formData, {
        onSuccess: async (result: ResponseCreateAccount) => {
          await updateStytchSession(result.session);

          formData.append('authSession', result.session);

          updateCase(formData);
        },
      });
    }
  };

  return { onFormSubmit, isLoading, error, isSuccess };
};
