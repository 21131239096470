import { Text, TextProps } from '@mantine/core';

export const StepSubtitle = (props: TextProps) => {
  return (
    <Text
      c="primary.0"
      align="center"
      mt={8}
      size={props.size ?? '14px'}
      maw={640}
      mx="auto"
      {...props}
    />
  );
};
