import { Fields } from '@/state/state.types';
import { useEffect, useRef } from 'react';

export const usePreviousFields = (fields: Fields | undefined) => {
  const ref = useRef<Fields>();

  useEffect(() => {
    if (!fields) return;

    ref.current = fields;
  }, [fields]);

  return ref.current;
};
